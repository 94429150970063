import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import { InputElement, SelectElement } from "components/atoms/FormElements";
import { useAuth } from "hooks/redux-hooks";
import { useEffect, useLayoutEffect, useState } from "react";
import Dropzone from "components/atoms/Dropzone";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { mainPaths } from "router/path";
import { Hooks } from "services";
import SpecialSelect from "./components/SpecialSelect";

interface Props {
  location: {
    state: {
      data: any;
    };
  };
  onUpdate: () => void;
}

const InventoryEditForm = (props: Props) => {
  const { handleSubmit, control, watch, setValue, register, formState } =
    useForm({
      defaultValues: {
        campusID: props.location.state.data.campusID as any,
        buildID: props.location.state.data.buildID as any,
        floorID: props.location.state.data.floorID as any,
        roomID: props.location.state.data.roomID as any,
        groupID: props.location.state.data.groupID as any,
        brandID: props.location.state.data.brandID as any,
        modelID: props.location.state.data.modelID as any,
        userTypeID: props.location.state.data.userTypeID as any,
        name: props.location.state.data.name as any,
        assetNo: props.location.state.data.assetNo as any,
        barcode: props.location.state.data.barcode as any,
        explain: props.location.state.data.explain as any,
        unit: props.location.state.data.unit as any,
        count: props.location.state.data.count as any,
        serialNumber: props.location.state.data.serialNumber as any,
        productionYear: props.location.state.data.productionYear as any,
        inventoryPhoto: undefined as any,
        inventoryCapacityID: props.location.state.data
          .inventoryaCapacityID as any,
      },
    });

  const toast = useToast();

  const { project, user } = useAuth();
  const [updateInventory, statusUI, dataUI] = Hooks.UpdateInvetory();
  const [getCampus, statCampus, dataCampus] = Hooks.CampusList();
  const [getBuild, statBuild, dataBuild, resetBuild] = Hooks.BuildList();
  const [getFloor, statFloor, dataFloor, resetFloor] = Hooks.FloorList();
  const [getRoom, statRoom, dataRoom, resetRoom] = Hooks.RoomList();
  const [getUserType, statUT, dataUT] = Hooks.UserTypeList();
  const [getGroup, statGroup, dataGroup] = Hooks.GroupList();
  const [getBrand, statBrand, dataBrand, resetBrand] = Hooks.BrandList();
  const [getModel, statModel, dataModel, resetModel] = Hooks.ModelList();
  const [getCapacities, statCapacities, dataCapacities] = Hooks.CapacityList();

  const [isMounted, setMounted] = useState(false);

  async function onValid(values: any) {
    function checkField(value: string) {
      return !!value ? value : undefined;
    }
    await updateInventory(
      props.location.state.data.id,
      props.location.state.data.userTypeID,
      project.id,
      values.campusID,
      values.buildID,
      values.floorID,
      values.roomID,
      values.groupID,
      values.brandID,
      !!values.modelID ? values.modelID : 0,
      !!values.inventoryCapacityID ? values.inventoryCapacityID : 0,
      values.barcode,
      checkField(values.assetNo),
      values.name,
      checkField(values.serialNumber),
      checkField(values.productionYear),
      checkField(values.inventoryPhoto),
      checkField(values.explain),
      !!values.unit ? values.unit : "",
      values.count ? parseInt(values.count) : undefined,
      user.id,
      props.location.state.data.isActive
    );
  }

  useLayoutEffect(() => {
    register("campusID", { required: true });
    register("buildID", { required: true });
    register("floorID", { required: true });
    register("roomID", { required: true });
    register("groupID", { required: true });
    register("brandID", { required: true });
    getCampus(project.id);
    getUserType();
    getGroup(project.id);
    getCapacities();
  }, []);

  useEffect(() => {
    if (!!watch("campusID")) {
      if (!!dataBuild) {
        setValue("buildID", null);
      }
      getBuild(watch("campusID"));
    } else if (!watch("campusID") && !!dataBuild) {
      resetBuild();
      setValue("buildID", null);
    }
  }, [watch("campusID")]);

  useEffect(() => {
    if (!!watch("buildID")) {
      if (!!dataFloor) {
        setValue("floorID", null);
      }
      getFloor(watch("buildID"));
    } else if (!watch("buildID") && !!dataFloor) {
      resetFloor();
      setValue("floorID", null);
    }
  }, [watch("buildID")]);

  useEffect(() => {
    if (!!watch("floorID")) {
      if (!!dataRoom) {
        setValue("roomID", null);
      }
      getRoom(watch("floorID"));
    } else if (!watch("floorID") && !!dataRoom) {
      resetRoom();
      setValue("roomID", null);
    }
  }, [watch("floorID")]);

  useEffect(() => {
    if (!!watch("groupID")) {
      if (!!dataBrand) {
        setValue("brandID", null);
      }
      getBrand(watch("groupID"));
    } else if (!watch("groupID") && !!dataBrand) {
      resetBrand();
      setValue("brandID", null);
    }
  }, [watch("groupID")]);

  useEffect(() => {
    if (!!watch("brandID")) {
      if (!!dataModel) {
        setValue("modelID", null);
      }
      getModel(watch("brandID"));
    } else if (!watch("brandID") && !!dataModel) {
      resetModel();
      setValue("modelID", null);
    }
  }, [watch("brandID")]);

  useEffect(() => {
    if (statusUI === "fulfilled") {
      if (dataUI) {
        if (dataUI?.data?.success === true) {
          toast({ status: "success", title: "Ekipman Başarıyla Güncellendi" });
          if (props.onUpdate) {
            props.onUpdate();
          }
        } else if (dataUI?.data?.success === false) {
          toast({ status: "error", title: "Ekipman Güncellenemedi!" });
        }
      }
    }
  }, [statusUI]);
  return (
    <form onSubmit={handleSubmit(onValid)}>
      <Card>
        <Tabs isFitted>
          <TabList>
            <Tab>Lokasyon Bilgileri</Tab>
            <Tab>Teknik Bilgiler Ve Kullanıcı Tipi</Tab>
            <Tab>Genel Bilgiler</Tab>
          </TabList>
          <TabPanels>
            <TabPanel pt="10">
              <SimpleGrid columns={[1, 2]} gap="5">
                <SpecialSelect
                  isRequired
                  onChange={(value: any) => {
                    setValue("campusID", value?.value);
                  }}
                  value={watch("campusID")}
                  data={dataCampus?.data?.data}
                  status={statCampus}
                  label="Tesis"
                  isInvalid={formState.errors["campusID"]}
                />
                <SpecialSelect
                  isRequired
                  onChange={(value: any) => {
                    setValue("buildID", value?.value);
                  }}
                  value={watch("buildID")}
                  data={dataBuild?.data?.data}
                  status={statBuild}
                  label="Bina"
                  isInvalid={formState.errors["buildID"]}
                />
                <SpecialSelect
                  isRequired
                  onChange={(value: any) => {
                    setValue("floorID", value?.value);
                  }}
                  value={watch("floorID")}
                  data={dataFloor?.data?.data}
                  status={statFloor}
                  label="Kat"
                  isInvalid={formState.errors["floorID"]}
                />
                <SpecialSelect
                  isRequired
                  onChange={(value: any) => {
                    setValue("roomID", value?.value);
                  }}
                  value={watch("roomID")}
                  data={dataRoom?.data?.data}
                  status={statRoom}
                  label="Oda"
                  isInvalid={formState.errors["roomID"]}
                />
              </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <SimpleGrid columns={[1, 2]} gap="5">
                <SpecialSelect
                  isRequired
                  onChange={(value: any) => {
                    setValue("groupID", value?.value);
                  }}
                  value={watch("groupID")}
                  data={dataGroup?.data?.data}
                  status={statGroup}
                  label="Grup"
                  isInvalid={formState.errors["groupID"]}
                />
                <SpecialSelect
                  isRequired
                  onChange={(value: any) => {
                    setValue("brandID", value?.value);
                  }}
                  value={watch("brandID")}
                  data={dataBrand?.data?.data}
                  status={statBrand}
                  label="Marka"
                  isInvalid={formState.errors["brandID"]}
                />
                <SpecialSelect
                  onChange={(value: any) => {
                    setValue("modelID", value?.value);
                  }}
                  value={watch("modelID")}
                  data={dataModel?.data?.data}
                  status={statModel}
                  label="Model"
                />
              </SimpleGrid>
              <Divider my="5" />
              <SimpleGrid columns={2} gap="5">
                <SpecialSelect
                  isRequired
                  onChange={(value: any) => {
                    setValue("userTypeID", value?.value);
                  }}
                  value={watch("userTypeID")}
                  data={dataUT?.data?.data}
                  status={statUT}
                  label="Kullanıcı Tipi"
                />
              </SimpleGrid>
            </TabPanel>
            <TabPanel pt="10">
              <SimpleGrid columns={[1, 2]} gap="5">
                <InputElement
                  name="name"
                  label="Ekipman Adı"
                  isRequired
                  control={control}
                  defaultValue={watch("name")}
                />
                <InputElement
                  name="serialNumber"
                  label="Seri No"
                  control={control}
                  defaultValue={watch("serialNumber")}
                />
                <InputElement
                  name="barcode"
                  label="Barkod"
                  isRequired
                  control={control}
                  defaultValue={watch("barcode")}
                />
                <InputElement
                  name="explain"
                  label="Açıklama"
                  control={control}
                  defaultValue={watch("explain")}
                />
                <SelectElement
                  name="inventoryCapacityID"
                  label="Kapasite"
                  control={control}
                  data={(dataCapacities?.data.data as any[])?.map(
                    (capacity) => ({
                      label: capacity.name,
                      value: capacity.id,
                    })
                  )}
                />
                <InputElement
                  name="unit"
                  label="Kapasite Değeri"
                  control={control}
                  defaultValue={watch("unit")}
                />
                <InputElement
                  name="assetNo"
                  label="Asset No"
                  control={control}
                  defaultValue={watch("assetNo")}
                />
                <InputElement
                  name="productionYear"
                  label="Üretim Yılı"
                  control={control}
                  defaultValue={watch("productionYear")}
                />
                <InputElement
                  name="count"
                  label="Adet"
                  control={control}
                  defaultValue={watch("count")}
                />
                <FormControl>
                  <FormLabel>Ekipman Fotoğrafı</FormLabel>
                  <Dropzone
                    onDrop={(files) => setValue("inventoryPhoto", files[0])}
                  />
                </FormControl>
              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
      <Card mt="3">
        <Flex justifyContent="flex-end">
          <Button colorScheme="purple" type="submit">
            Düzenle
          </Button>
        </Flex>
      </Card>
    </form>
  );
};

export default InventoryEditForm;
