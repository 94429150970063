import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Spinner,
  Text,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card, DataTable } from "components/atoms";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Hooks } from "services";
import Moment from "moment";
import { AiFillFire } from "react-icons/ai";
import { useAuth } from "hooks/redux-hooks";

interface CounterMaintenance {
  id: number;
  counterID: number;
  counterBarcode: string;
  counterLocation: string;
  counterPeriodID: number;
  counterPeriodName: string;
  startDate: string;
  beforeDay: number;
  isTRequired: boolean;
  isT1Required: boolean;
  isT2Required: boolean;
  isT3Required: boolean;
  isKapasitifRequired: boolean;
  isReaktifRequired: boolean;
  isDemantRequired: boolean;
  isActive: boolean;
  addedUserID: number;
  addedUserIName: string;
  createDate: string;
  multiplierValue: number;
  consumptionDifferencePercentage: number;
}

type CounterMaintenanceProps = {};

const CounterMaintenancePage: FC<CounterMaintenanceProps> = (props) => {
  const history = useHistory();
  const routeMatch = useRouteMatch<{ counterId: string }>();
  const { project } = useAuth();
  const match = useRouteMatch<{ counterId: string }>();
  const [getList, statList, dataList] = Hooks.CounterListMaintenance();

  const [counter, setCounter] = useState<any>(null);

  const [getCL, statCL, dataCL] = Hooks.CounterListByProject();

  useLayoutEffect(() => {
    getList(Number(match.params.counterId));

    if (!dataCL) {
      getCL(project.id);
    }
  }, []);

  useEffect(() => {
    if (dataCL) {
      const _counter = dataCL.data.data.find(
        (item: any) => item.id === Number(routeMatch.params.counterId)
      );
      if (_counter) setCounter(_counter);
    }
  }, [dataCL]);

  const renderBadge = (cond: boolean) => {
    return (
      <Badge colorScheme={cond ? "orange" : "gray"}>
        {cond ? "Gerekli" : "Gerekli Değil"}
      </Badge>
    );
  };

  return (
    <Main headerProps={{ title: "Sayaç Bakımları" }}>
      <Card>
        <Flex justifyContent="flex-end">
          <Button
            colorScheme="blue"
            size="sm"
            onClick={() =>
              history.push(
                `/counter/${match.params.counterId}/maintenance-create`
              )
            }
          >
            Kontrol Ekle
          </Button>
        </Flex>
      </Card>
      <Card mt="5">
        {statList === "fulfilled" && !!counter ? (
          <DataTable
            columns={[
              {
                name: "ID",
                selector: "id",
              },
              {
                name: "Periyot",
                selector: "counterPeriodName",
              },
              {
                name: "Başlangıç Tarihi",
                cell: (row: CounterMaintenance) =>
                  Moment(row.startDate).format("DD.MM.YYYY"),
              },
              {
                name: "Kaç gün önce başlayacak",
                selector: "beforeDay",
              },
              ...(counter.counterTypeID === 1
                ? [
                    {
                      name: "T Gerekli Mi?",
                      cell: (row: CounterMaintenance) =>
                        renderBadge(row.isTRequired),
                    },
                    {
                      name: "T1 Gerekli Mi?",
                      cell: (row: CounterMaintenance) =>
                        renderBadge(row.isT1Required),
                    },
                    {
                      name: "T2 Gerekli Mi?",
                      cell: (row: CounterMaintenance) =>
                        renderBadge(row.isT2Required),
                    },
                    {
                      name: "T3 Gerekli Mi?",
                      cell: (row: CounterMaintenance) =>
                        renderBadge(row.isT3Required),
                    },
                    {
                      name: "Demant Değeri Gerekli Mi?",
                      cell: (row: CounterMaintenance) =>
                        renderBadge(row.isDemantRequired),
                    },
                    {
                      name: "Kapasitif Değer Gerekli Mi?",
                      cell: (row: CounterMaintenance) =>
                        renderBadge(row.isKapasitifRequired),
                    },
                    {
                      name: "Reaktif Değer Gerekli Mi?",
                      cell: (row: CounterMaintenance) =>
                        renderBadge(row.isReaktifRequired),
                    },
                    {
                      name: "Çarpan Değeri",
                      selector: "multiplierValue"
                    },
                    {
                      name: `Ortalama Tüketim Farkı Yüzdesi (%)`,
                      cell: (row: CounterMaintenance) => `%${row.consumptionDifferencePercentage}`
                    },
                  ]
                : []),
              {
                name: "Oluşturan Kullanıcı",
                selector: "addedUserIName",
              },
            ]}
            data={dataList?.data.data}
          />
        ) : (
          <Center minHeight="500px">
            <Spinner />
          </Center>
        )}
      </Card>
    </Main>
  );
};

export default CounterMaintenancePage;
