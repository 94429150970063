import React, {
  FC,
  Fragment,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  Box,
  Text,
  Center,
  Spinner,
  Flex,
  Button,
  Icon,
  SimpleGrid,
  Heading,
  Divider,
  Input,
  FormControl,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { useForm } from "react-hook-form";
import { Hooks } from "services";
import { RouteComponentProps } from "react-router-dom";
import { TimelineItem, TimelineItemDetail, TimelineLegal } from "models";
import { FaCheck } from "react-icons/fa";
import { Card } from "components/atoms";
import Dropzone from "components/atoms/Dropzone";
import { useAuth } from "hooks/redux-hooks";
import { useToast } from "hooks/toast";
import Moment from "moment";
import { DocumentField } from "./DoMaintenance";

interface DoMaintenancePageProps {}

const DoMaintenancePage: FC<
  DoMaintenancePageProps &
    RouteComponentProps<{ id: string }, {}, { timelineItem: TimelineLegal }>
> = (props) => {
  const { control, handleSubmit } = useForm();
  const { user, project } = useAuth();
  const [getQustions, statQuestions, dataQuestions] = Hooks.ListQuestion();
  const [doMaintenance, statDoMain, dataDoMain] = Hooks.DoDocumentMaintenance();
  const [loading, setLoading] = useState<boolean>(false);
  const [datas, setDatas] = useState({
    isg: false,
    contract: false,
    instruction: false,
    guide: false,
    photo: null as File[] | null,
    request: null as File[] | null,
    firm: "",
    user: "",
    explain: "",
    quest: null as unknown as any,
    lastDate: "",
    extra: "",
  });

  const [questions, setQuestions] = useState<any[]>([]);
  const toast = useToast();

  function _onOk(_key: keyof typeof datas) {
    setDatas((_datas) => ({ ...datas, [_key]: true }));
  }

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function getBase64Photos(arr: any[]) {
    return new Promise(async (resolve, reject) => {
      let arrData: any[] = [];
      for (var index = 0; index < arr.length; index++) {
        let base64str = await toBase64(arr[index]);
        let ext = arr[index].name.split(".").pop();
        arrData.push({ base64: base64str, extension: ext });
      }

      return resolve(arrData);
    });
  }

  useEffect(() => {
    if (statDoMain) {
      if (dataDoMain?.data.success) {
        toast({ title: "İşlem başarıyla gerçekleşti!" });
        props.history.push("/");
      }
    }
  }, [statDoMain]);

  async function handleClickComplete() {
    setLoading(true);
    let base64List = await getBase64Photos(datas.photo || []);
    let base64ListForms = await getBase64Photos(datas.request || []);
    await doMaintenance({
      firstControlUserID: user.id,
      firstControlUserName: user.username,
      documentMaintenanceID: parseInt(props.match.params.id),
      documents: [
        ...(base64List as any[]).map((base64Str) => ({
          document: base64Str.base64,
          extension: base64Str.extension,
          type: "inventoryPhoto",
        })),
        ...(base64ListForms as any[]).map((base64Str) => ({
          document: base64Str.base64,
          extension: base64Str.extension,
          type: "formPhoto",
        })),
      ],
      explain: datas.explain,
      secondDate: !!datas.lastDate
        ? datas.lastDate
        : Moment().format("YYYY-MM-DD"),
      questionAnswer: datas.quest ? "uygun" : "uygun değil",
      status: datas.quest,
    });
    setLoading(false);
  }

  return (
    <Main headerProps={{ title: "Belge Takip Kontrol İşlemi" }}>
      <Card mb="10">
        {true && (
          <Box>
            {[
              props.location.state.timelineItem.userGudiePathIsRequired,
              props.location.state.timelineItem.userInstructionsIsRequired,
              props.location.state.timelineItem.riskAnalysisIsRequired,
              props.location.state.timelineItem.maintenanceContractIsRequired,
            ].includes(true) && (
              <Fragment>
                <Heading fontSize="large">
                  Zorunlu Dokümanlar{" "}
                  <Text d="inline" color="red.500">
                    *
                  </Text>
                </Heading>
                <Divider my="2" />
                <SimpleGrid columns={[1, 2]} spacing="5">
                  <DocumentField
                    required={
                      props.location.state.timelineItem
                        .maintenanceContractIsRequired
                    }
                    title="Bakım Sözleşmesi"
                    value={datas.contract}
                    onOk={() => {
                      _onOk("contract");
                    }}
                    url={
                      props.location.state.timelineItem.maintenanceContractPath
                    }
                  />
                  <DocumentField
                    required={
                      props.location.state.timelineItem.riskAnalysisIsRequired
                    }
                    title="ISG Risk Analizi"
                    value={datas.isg}
                    onOk={() => {
                      _onOk("isg");
                    }}
                    url={props.location.state.timelineItem.riskAnalysisPath}
                  />
                  <DocumentField
                    required={
                      props.location.state.timelineItem
                        .userInstructionsIsRequired
                    }
                    title="Kullanma Talimatı"
                    value={datas.instruction}
                    onOk={() => {
                      _onOk("instruction");
                    }}
                    url={props.location.state.timelineItem.userInstructionsPath}
                  />
                  <DocumentField
                    required={
                      props.location.state.timelineItem.userGudiePathIsRequired
                    }
                    title="Kullanım Klavuzu"
                    value={datas.guide}
                    onOk={() => {
                      _onOk("guide");
                    }}
                    url={props.location.state.timelineItem.userGudiePath}
                  />
                </SimpleGrid>
              </Fragment>
            )}

            <Heading fontSize="large" mt="8">
              Belge{" "}
              <Text d="inline" color="red.500">
                *
              </Text>
            </Heading>
            <Divider my="2" />
            <Box>
              <Dropzone
                multiple
                hasAllType
                onDrop={(files) => {
                  setDatas((_data) => ({ ..._data, request: files }));
                }}
              />
            </Box>

            <Heading fontSize="large" mt="8">
              Belge Takip Kontrol Sorusu{" "}
              <Text d="inline" color="red.500">
                *
              </Text>
            </Heading>
            <Divider my="2" />
            <Box>
              <FormControl mb="2">
                <FormLabel>
                  {props.location.state.timelineItem.question}
                </FormLabel>
                <Flex>
                  <Button
                    onClick={() => {
                      setDatas((_datas) => ({ ..._datas, quest: true }));
                    }}
                    variant={datas.quest ? "solid" : "outline"}
                    colorScheme="green"
                  >
                    Uygun
                  </Button>
                  <Button
                    onClick={() => {
                      setDatas((_datas) => ({ ..._datas, quest: false }));
                    }}
                    variant={datas.quest === false ? "solid" : "outline"}
                    colorScheme="red"
                    ml="1"
                  >
                    Uygun Değil
                  </Button>
                </Flex>
              </FormControl>
              {datas.quest === false && (
                <Fragment>
                  <FormControl mb="2" mt="4">
                    <FormLabel>
                      İkinci Doküman Yüklenebilir Son Tarih
                      <Text d="inline" color="red.500">
                        *
                      </Text>
                    </FormLabel>
                    <Input
                      type="date"
                      value={datas.lastDate}
                      min={Moment().add(1, "d").format("YYYY-MM-DD")}
                      onChange={(e) => {
                        setDatas((_datas) => ({
                          ..._datas,
                          lastDate: e.currentTarget.value,
                        }));
                      }}
                    />
                  </FormControl>
                  <FormControl mb="2">
                    <FormLabel>Not</FormLabel>
                    <Textarea
                      value={datas.extra}
                      onChange={(e) => {
                        setDatas((_datas) => ({
                          ..._datas,
                          extra: e.currentTarget?.value,
                        }));
                      }}
                    ></Textarea>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Fotoğraf / Belge</FormLabel>
                    <Dropzone
                      multiple
                      onDrop={(files) => {
                        setDatas((_data) => ({ ..._data, photo: files }));
                      }}
                    />
                  </FormControl>
                </Fragment>
              )}
            </Box>

            <Box>
              {datas.quest === true && (
                <FormControl>
                  <FormLabel>Notlar</FormLabel>
                  <Textarea
                    type="text"
                    value={datas.explain}
                    onChange={(e) =>
                      setDatas((_datas) => ({
                        ..._datas,
                        explain: e.currentTarget.value,
                      }))
                    }
                  />
                </FormControl>
              )}
            </Box>

            <Flex justifyContent="flex-end" mt="10">
              <Button
                colorScheme="teal"
                onClick={handleClickComplete}
                isLoading={loading}
                isDisabled={datas.request?.length === 0 || datas.quest === null}
              >
                Tamamla
              </Button>
            </Flex>
          </Box>
        )}
      </Card>
    </Main>
  );
};

DoMaintenancePage.defaultProps = {};

export default DoMaintenancePage;
