import React, { FC } from "react";
import { Box, Button, Center, Flex, Icon, Text } from "@chakra-ui/react";
import Main from "components/templates/Main";
import { IoRemove, IoRemoveCircle, IoSad, IoSadOutline } from "react-icons/io5";
import { FaSadCry, FaSadTear } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = (props) => {
  const history = useHistory();
  return (
    <Main headerProps={{ title: "" }}>
      <Center h="100%" minH="300px">
        <Flex flexDir="column" alignItems="center">
          <Icon as={IoRemoveCircle} color="blackAlpha.600" fontSize="8xl" />
          <Text fontSize="3xl" color="blackAlpha.600">Sayfa Bulunamadı</Text>
          <Button
            colorScheme="blackAlpha"
            variant="outline"
            mt="10"
            onClick={() => {
              history.push("/");
            }}
          >
            Kontrol Merkezine Git
          </Button>
        </Flex>
      </Center>
    </Main>
  );
};

NotFound.defaultProps = {};

export default NotFound;
