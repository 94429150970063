import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FC, useLayoutEffect } from "react";
import { Hooks } from "services";
import InventoryEditForm from "../forms/inventory-edit-form/InventoryEditForm";

type InventoryEditModalProps = {
  disc: any;
  id: string;
  onUpdate: () => void;
};

const InventoryEditModal: FC<InventoryEditModalProps> = (props) => {
  const [getInventoryDetail, status, data] = Hooks.InventoryDetail();
  useLayoutEffect(() => {
    if (props.id) {
      getInventoryDetail(props.id);
    }
  }, [props.id]);

  return (
    <Modal isOpen={props.disc.isOpen} onClose={props.disc.onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ekipman Düzenle</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {status === "fulfilled" && data && (
            <InventoryEditForm
              location={{
                state: {
                  data: data?.data?.data,
                },
              }}
              onUpdate={() => {
                props.onUpdate();
                props.disc.onClose();
              }}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InventoryEditModal;
