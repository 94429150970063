import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { useAuth, useRedux } from "hooks/redux-hooks";
import Card from "components/atoms/Card";
import {
  CheckBoxElement,
  InputElement,
  SelectElement,
} from "components/atoms/FormElements";
import ReactSelect from "react-select";
import { useForm } from "react-hook-form";
import { Hooks } from "services";
import { UserRoles } from "models/user";

interface AuthInfoProps {}

const AuthInfo: FC<AuthInfoProps> = (props) => {
  const { control, handleSubmit } = useForm();
  const [getUser, statUser, dataUser] = Hooks.GetUser();
  const [getRoles, statRoles, dataRoles] = Hooks.UserRoleList();
  const [getTypes, statTypes, dataTypes] = Hooks.UserTypeList();
  const { user, role } = useAuth();
  const [userTypes, setUserTypes] = useState<any[]>([]);
  const [updateUser, , dataUpdateUser] = Hooks.UpdateUser();
  const toast = useToast();

  function onValid(values: any) {
    updateUser({
      ...dataUser?.data.data!,
      userTypes: [...dataUser?.data.data.userTypes.map((item: any) => item.id)],
      ...values,
    });
  }

  useLayoutEffect(() => {
    getUser(user.id);
  }, []);

  useEffect(() => {
    if (dataUpdateUser) {
      if (dataUpdateUser.data.success) {
        toast({ status: "success", title: "Kullanıcı başarıyla güncellendi" });
        getUser(user.id);
      } else {
        toast({
          status: "error",
          description: dataUpdateUser.data.message,
          title: "Kullanıcı güncellenemedi",
        });
      }
    }
  }, [dataUpdateUser]);

  if (statUser !== "fulfilled" || !dataUser?.data.data)
    return (
      <Center>
        <Spinner size="xl" />
      </Center>
    );

  return (
    <Main headerProps={{ title: "Bilgilerimi Güncelle" }}>
      <Card>
        <form onSubmit={handleSubmit(onValid)}>
          <SimpleGrid columns={2} gap="5">
            <InputElement
              name="name"
              label="Ad"
              control={control}
              isRequired
              defaultValue={dataUser?.data.data.name}
            />
            <InputElement
              name="surname"
              label="Soyad"
              control={control}
              isRequired
              defaultValue={dataUser?.data.data.surname}
            />
            <InputElement
              name="username"
              label="Kullanıcı Adı"
              control={control}
              isRequired
              defaultValue={dataUser?.data.data.username}
              disabled={role !== UserRoles.admin}
            />
            <InputElement
              name="email"
              defaultValue={dataUser?.data.data.email}
              label="E Posta"
              control={control}
            />
            <InputElement
              name="phone"
              defaultValue={dataUser?.data.data.phone}
              label="Telefon"
              control={control}
            />
            <Box></Box>

            <CheckBoxElement
              name="isNotificationEnabled"
              label="Bildirimlere İzin Ver"
              control={control}
              defaultValue={dataUser?.data.data.isNotificationEnabled}
            />
            <CheckBoxElement
              name="isEmailEnabled"
              label="E-Posta Almaya İzin Ver"
              control={control}
              defaultValue={dataUser?.data.data.isEmailEnabled}
            />
          </SimpleGrid>
          <Flex justifyContent="flex-end" mt="5">
            <Button type="submit" colorScheme="teal">
              Düzenle
            </Button>
          </Flex>
        </form>
      </Card>
    </Main>
  );
};

AuthInfo.defaultProps = {};

export default AuthInfo;
