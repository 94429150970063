import React, { FC } from "react";
import { IconButton, useColorMode } from "@chakra-ui/react";
import { IoMoon, IoSunny } from "react-icons/io5";

const ColorModeButton: FC = () => {
  const colorMode = useColorMode();
  return (
    <IconButton
      onClick={colorMode.toggleColorMode}
      aria-label="Color"
      rounded="full"
      mr="2"
      icon={colorMode.colorMode === "dark" ? <IoSunny /> : <IoMoon />}
    />
  );
};

export default ColorModeButton;
