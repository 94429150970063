import {
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
  Box,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  ModalOverlay,
  UseDisclosureReturn,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { useAuth } from "hooks/redux-hooks";
import React, { FC, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { Hooks } from "services";
import Result from "./Result";

type GlobalSearchProps = {
  searchDisc: UseDisclosureReturn;
};

const GlobalSearch: FC<GlobalSearchProps> = (props) => {
  const [state, setState] = useState({
    query: "",
  });
  const { project } = useAuth();
  const { isOpen, onToggle, onClose } = props.searchDisc;
  const [request, status, data] = Hooks.GlobalSearch();

  const onSearch = () => {
    request(project.id, state.query);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Projede Ara</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex>
            <Input
              mr="2"
              autoFocus
              placeholder="Aranacak kelime..."
              value={state.query}
              onKeyPress={(e) => {
                if (e.code === "Enter") {
                  onSearch();
                }
              }}
              onChange={(e) =>
                setState((prev) => ({ ...prev, query: e.target.value }))
              }
            />
            <IconButton
              colorScheme="blue"
              onClick={onSearch}
              aria-label="Search"
              mr="2"
              icon={<IoSearch />}
            />
          </Flex>

          <Box minH="50vh" maxH="50vh" overflowY="scroll" pr="4" mt="5">
            {status === "pending" && (
              <Center>
                <Spinner />
              </Center>
            )}
            {status === "fulfilled" &&
              data?.data.data.map((item: any, index: number) => {
                return (
                  <Result key={index} title={item.title} itemType={item.type} id={item.id} />
                );
              })}
            {status === "fulfilled" && data?.data.data.length === 0 && (
              <Box textAlign="left">
                <Text>Sonuç bulunamadı!</Text>
              </Box>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GlobalSearch;
