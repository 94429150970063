import React, { FC } from "react";
import { Box, Flex, Text, useColorModeValue, Center } from "@chakra-ui/react";

type TimelineHeaderProps = {};

const TimelineHeader: FC<TimelineHeaderProps> = (props) => {
  return (
    <Flex
      my="1"
      p="0.5"
      bgColor={useColorModeValue("gray.200", "gray.700")}
      fontSize="small"
    >
      <Center flex="1">
        <Text>Tarih</Text>
      </Center>
      <Box flex="1">
        <Text>Barkod</Text>
      </Box>
      <Box flex="1">
        <Text>Tüketim Bölgesi</Text>
      </Box>
      <Box flex="1">
        <Text>Sayaç Türü</Text>
      </Box>
      <Box flex="1">
        <Text>Periyot</Text>
      </Box>
      <Box flex="1">
        <Text>Planlı Bakım Tarihi</Text>
      </Box>
    
      <Flex alignItems="center" w="100px" mx="3"></Flex>
    </Flex>
  );
};

export default TimelineHeader;
