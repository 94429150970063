import React, { FC, useLayoutEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import Modal, { ModalProps } from "components/atoms/Modal";
import { Form, InputElement, FormHandles } from "components/atoms/HookForm";
import { Hooks } from "services";
import DataTable from "components/atoms/DataTable";
import { UserType } from "services/swagger";
import Confirm from "components/atoms/Confirm";
import Badge from "components/atoms/Badge";
import { IoOptions } from "react-icons/io5";

interface UserTypePageProps {}

const UserTypePage: FC<UserTypePageProps> = (props) => {
  const [onList, statList, dataList] = Hooks.UserTypeList();
  const [onAdd, statAdd, dataAdd] = Hooks.AddUserType();
  const [onEdit, statEdit, dataEdit] = Hooks.EditUserType();
  const modalRef = useRef<ModalProps>(null);
  const editModalRef = useRef<ModalProps>(null);
  const formRef = useRef<FormHandles>(null);
  const editFormRef = useRef<FormHandles>(null);
  const toast = useToast();
  const [userType, setUserType] = useState<UserType | null>(null);

  async function handleValid(values: { name: string }) {
    const resp = await onAdd({ name: values.name});
    if (!resp.data.success) {
      return toast({
        status: "error",
        title: "Hata oluştu!",
        description: resp.data.message,
      });
    }

    onList();
    modalRef.current?.close();
    toast({ status: "success", title: "Yeni Kullanıcı Tipi Oluşturuldu!" });
  }

  async function handleValidEdit(values: { name: string }) {
    const resp = await onEdit({ ...userType, name: values.name });
    if (!resp.data.success) {
      return toast({
        status: "error",
        title: "Hata oluştu!",
        description: resp.data.message,
      });
    }

    onList();
    editModalRef.current?.close();
    setUserType(null);
    toast({ status: "success", title: "Kullanıcı Tipi Düzenlendi!" });
  }

  async function handleClickToggleActive(row: UserType) {
    const resp = await onEdit({ ...row, isActive: !row.isActive });
    if (!resp.data.success) {
      return toast({
        status: "error",
        title: "Hata oluştu!",
        description: resp.data.message,
      });
    }

    onList();
    toast({ status: "success", title: "Kullanıcı Tipi Düzenlendi!", duration: 500 });
  }

  useLayoutEffect(() => {
    onList();
  }, []);

  return (
    <Main headerProps={{ title: "Kullanıcı Tipleri" }}>
      <Card p="3" mb="3">
        <Flex justifyContent="flex-end">
          <Button
            size="sm"
            colorScheme="blue"
            onClick={() => modalRef.current?.open()}
          >
            Yeni Kullanıcı Tipi Ekle
          </Button>
        </Flex>
      </Card>
      {statList === "fulfilled" && (
        <Card mb="5">
          <DataTable
            data={dataList?.data.data}
            columns={[
              { name: "ID", selector: "id" },
              { name: "İsim", selector: "name" },
              { name: "Durum", cell: (row: UserType) => <Badge isActive={!!row.isActive} /> },
              {
                name: "Aktif / Pasif",
                cell: (row: UserType) => (
                  <Box>
                    <Confirm onConfirm={() => { handleClickToggleActive(row) }} text="Bu kayıt pasif yapılacak. İşleme devam edilsin mi?">
                      {({ onOpen }) => <IconButton size="sm" onClick={onOpen} aria-label="active" icon={<IoOptions />} />}
                    </Confirm>
                  </Box>
                ),
              },
              {
                name: "Düzenle",
                cell: (row: UserType) => (
                  <Box>
                    <Button
                      size="sm"
                      colorScheme="teal"
                      onClick={() => {
                        setUserType(row);
                        editModalRef.current?.open();
                      }}
                    >
                      Düzenle
                    </Button>
                  </Box>
                ),
              },
            ]}
          />
        </Card>
      )}
      {statList !== "fulfilled" && (
        <Center>
          <Spinner size="xl" />
        </Center>
      )}

      <Modal
        ref={modalRef}
        title="Kullanıcı Tipi Ekle"
        onConfirm={() => {
          formRef.current?.submit();
        }}
      >
        <Form onValid={handleValid} ref={formRef}>
          <InputElement name="name" label="Kullanıcı Tipi İsmi" />
        </Form>
      </Modal>
      <Modal
        ref={editModalRef}
        title="Kullanıcı Tipi Düzenle"
        onConfirm={() => {
          editFormRef.current?.submit();
        }}
      >
        <Form onValid={handleValidEdit} ref={editFormRef}>
          <InputElement
            defaultValue={userType?.name || ""}
            name="name"
            label="Kullanıcı Tipi İsmi"
          />
        </Form>
      </Modal>
    </Main>
  );
};

export default UserTypePage;
