import React, { FC, useEffect, useLayoutEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  useToast,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { Form, InputElement, SelectElement } from "components/atoms/HookForm";
import { Hooks } from "services";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useAuth } from "hooks/redux-hooks";
import RSelect from "react-select";
import { IFollowDocumentControl } from "models";
import Moment from 'moment'

interface ControlCreateProps {}

const ControlCreate: FC<
  ControlCreateProps &
    RouteComponentProps<{}, {}, { control: IFollowDocumentControl }>
> = ({
  location: {
    state: { control },
  },
  ...props
}) => {
  const { project } = useAuth();
  const [onAdd, statusAdd, dataAdd] = Hooks.EditDocumentMaintenance();
  const [getUsers, statUsers, dataUsers] = Hooks.UserList();
  const [getPeriod, statusPeriod, dataPeriod] =
    Hooks.DocumentMaintenancePeriodList();
  const toast = useToast();
  const history = useHistory();

  function onValid(values: any) {
    onAdd({
      id: control.id,
      documentPeriodID: control.documentPeriodID,
      beforeDay: control.beforeDay,
      documentID: control.documentID,
      startDate: control.startDate,
      question: values.question,
      isActive: control.isActive,
    });
  }

  useEffect(() => {
    if (!!dataAdd) {
      if (dataAdd.data.success) {
        toast({ status: "success", title: "Kontrol başarıyla düzenlendi!" });
        history.push(`/document/${control.documentID}/control`);
      } else {
        toast({
          status: "error",
          title: "Hata Oluştu!",
          description: dataAdd.data.message,
        });
      }
    }
  }, [dataAdd]);

  useLayoutEffect(() => {
    getPeriod();
    getUsers(project.id);
  }, []);

  return (
    <Main headerProps={{ title: "Belge İçin Kontrol Oluştur" }}>
      <Card>
        <Form onValid={onValid}>
          
          <InputElement
            label="Soru"
            name="question"
            isRequired
            containerProps={{ mt: "3" }}
            defaultValue={control.question}

          />
          {/* <SelectElement
            data={dataUsers?.data.data.map((user: any) => ({
              label: user.name,
              value: user.id,
            }))}
            containerProps={{ mt: "3" }}
            label="Atanan Kullanıcı"
            name="assignUserID"
          /> */}
          <Button colorScheme="blue" type="submit" mt="5">
            Düzenle
          </Button>
        </Form>
      </Card>
    </Main>
  );
};

ControlCreate.defaultProps = {};

export default ControlCreate;
