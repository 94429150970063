import React, { FC } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

const TimelinePage: FC = () => {
  return (
    <Box>
      <Text>TimelinePage</Text>
    </Box>
  );
};

export default TimelinePage;
