import React, { useCallback, useLayoutEffect, useState } from "react";
import {
  Flex,
  Button,
  Spinner,
  Center,
  IconButton,
  Badge as CBadge,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { useAuth } from "hooks/redux-hooks";
import { Hooks } from "services";
import { Campus as ICampus } from "services/swagger";
import DTable from "components/atoms/DataTable";
import Badge from "components/atoms/Badge";
import Moment from "moment";
import { useHistory } from "react-router";
import { IoRefresh } from "react-icons/io5";
import { UserRoles } from "models/user";
import { useToast } from "hooks/toast";
import Confirm from "components/atoms/Confirm";

function User() {
  const { project, user, role } = useAuth();
  const history = useHistory();
  const toast = useToast();

  const [getList, , data] = Hooks.UserList();
  const [updateUser, statusUpdateUser] = Hooks.UpdateUser();
  const [updatingUser, setUpdateUser] = useState<null | number>(null);

  const _getList = useCallback(
    () => getList(project.id),
    [project.id, getList]
  );

  useLayoutEffect(() => {
    _getList();
  }, []);

  function onFilter(item: any) {
    switch (user.userRoleID) {
      case UserRoles.admin:
        return true;
      case UserRoles.manager:
        return [UserRoles.basic, UserRoles.view].includes(item.userRoleID);
      case UserRoles.basic:
        return item.id === user.id;
      default:
        return false;
    }
  }

  return (
    <Main headerProps={{ title: "Kullanıcılar" }}>
      <Card p="3" mb="3">
        <Flex justifyContent="flex-end">
          {role !== UserRoles.view && role !== UserRoles.basic && (
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => history.push("/user/create")}
            >
              Yeni Kullanıcı Ekle
            </Button>
          )}
        </Flex>
      </Card>

      {!!data?.data.data ? (
        <Card>
          <DTable
            data={data?.data.data.filter(onFilter)}
            columns={[
              {
                name: "ID",
                selector: "id",
                sortable: true,
                width: "100px",
              },
              {
                name: "Kullanıcı Adı",
                selector: "username",
                sortable: true,
                width: "150px",
              },
              {
                name: "Adı",
                selector: "name",
                sortable: true,
              },
              {
                name: "Soyad",
                selector: "surname",
                sortable: true,
              },
              {
                name: "E-Posta",
                selector: "email",
                sortable: true,
                width: "190px",
              },
              {
                name: "Telefon",
                selector: "phone",
                sortable: true,
                width: "190px",
              },
              {
                name: "Oluşturulma Tarihi",
                cell: (row: ICampus) => (
                  <span>{Moment(row.createDate).format("DD.MM.YYYY")}</span>
                ),
                width: "200px",
                columnType: "date",
                selector: "createDate",
              },
              {
                name: "Tanımlayan Kullanıcı",
                cell: (row: any) => <span></span>,
                width: "200px",
              },
              {
                name: "E-Posta Bildirim",
                cell: (row: any) => (
                  <CBadge colorScheme={row.isEmailEnabled ? "green" : "gray"}>
                    {row.isEmailEnabled ? "Açık" : "Kapalı"}
                  </CBadge>
                ),
                width: "100px",
                selector: "isEmailEnabled",
                columnType: "isActiveBoolean",
              },
              {
                name: "Mobil Bildirim",
                cell: (row: any) => (
                  <CBadge
                    colorScheme={row.isNotificationEnabled ? "green" : "gray"}
                  >
                    {row.isNotificationEnabled ? "Açık" : "Kapalı"}
                  </CBadge>
                ),
                width: "100px",
                selector: "isNotificationEnabled",
                columnType: "isActiveBoolean",
              },
              {
                name: "Rol",
                cell: (row: any) => (
                  <CBadge
                    colorScheme={
                      row.userRoleID === 1
                        ? "blue"
                        : row.userRoleID === 2
                        ? "orange"
                        : undefined
                    }
                  >
                    {row.userRoleName}
                  </CBadge>
                ),
                width: "100px",
                selector: "userRoleID",
                specialData: (cell) =>
                  cell === 1 ? "Admin" : cell === 2 ? "Yönetici" : "Standart",
              },
              {
                name: "Durum",
                cell: (row: ICampus) => <Badge isActive={!!row.isActive} />,
                width: "100px",
                columnType: "isActiveBoolean",
                selector: "isActive",
              },
              {
                name: "Aktif / Pasif",
                cell: (row: IUser) => {
                  if (row.userRoleID === UserRoles.admin) {
                    return null;
                  }

                  if (![UserRoles.admin, UserRoles.manager].includes(role))
                    return null;

                  return (
                    <Confirm
                      confirmText="Uygula"
                      onConfirm={async () => {
                        setUpdateUser(row.id);
                        const _data = (await updateUser({
                          ...row,
                          userTypes: row.userTypes.map(
                            (_type: any) => _type.id
                          ),
                          isActive: !row.isActive,
                        })) as unknown as any;
                        if (_data.data?.success) {
                          _getList();
                          setUpdateUser(null);
                          toast({
                            status: "success",
                            title: `${row.username} ${
                              row.isActive ? "PASİF" : "AKTİF"
                            } yapıldı!`,
                          });
                        } else if (!_data.data?.success) {
                          setUpdateUser(null);
                          toast({
                            status: "error",
                            title: _data.data?.message,
                          });
                        }
                      }}
                      colorScheme="red"
                      text={`${row.username} ${
                        row.isActive ? "PASİF" : "AKTİF"
                      } yapılacak!`}
                      title="Kullanıcı Aktif/Pasif değişikliği"
                    >
                      {({ onOpen }) => (
                        <IconButton
                          icon={<IoRefresh />}
                          aria-label="passiuve"
                          onClick={onOpen}
                        />
                      )}
                    </Confirm>
                  );
                },
                width: "100px",
              },
              {
                name: "Düzenle",
                cell: (row: any) => {
                  return (
                    <Button
                      size="xs"
                      colorScheme="orange"
                      disabled={
                        role === UserRoles.admin
                          ? false
                          : row.userRoleID === UserRoles.admin
                      }
                      onClick={() =>
                        history.push(`/user-edit/${row.id}`, { data: row })
                      }
                    >
                      Düzenle
                    </Button>
                  );
                },
                width: "100px",
              },
            ]}
          />
        </Card>
      ) : (
        <Card>
          <Center>
            <Spinner />
          </Center>
        </Card>
      )}
    </Main>
  );
}

export default User;
