import React, { FC } from "react";
import { Box, Text, SimpleGrid, Button, Center } from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { isSieOrNo } from "helper/project-mode";

interface ReportsPageProps {}

const ReportsPage: FC<ReportsPageProps> = (props) => {
  return (
    <Main headerProps={{ title: `Periyodik ${isSieOrNo("Kontrol", "Muayene")} Raporları`}}>
      <Card>
        <SimpleGrid columns={[1, 3]} gap="5">
          <Link to="/report/completed-period">
            <Center
              bgColor="gray.50"
              h="250px"
              rounded="md"
              _hover={{ bgColor: "blue.50" }}
            >
              <Box textAlign="center">
                <Text>Gerçekleşen Periyodik {isSieOrNo("Kontroller", "Muayeneler")}</Text>
              </Box>
            </Center>
          </Link>
          <Link to="/report/incompleted-period">
            <Center
              bgColor="gray.50"
              h="250px"
              rounded="md"
              _hover={{ bgColor: "blue.50" }}
            >
              <Box textAlign="center">
                <Text>
                  Planlanan Zamanda Gerçekleşmeyen
                  <br />
                  Periyodik {isSieOrNo("Kontroller", "Muayeneler")}
                </Text>
              </Box>
            </Center>
          </Link>
          <Link to="/report/wait-period">
            <Center
              bgColor="gray.50"
              h="250px"
              rounded="md"
              _hover={{ bgColor: "blue.50" }}
            >
              <Box textAlign="center">
                <Text>
                  Form Beklenen
                  <br />
                  Periyodik {isSieOrNo("Kontroller", "Muayeneler")}
                </Text>
              </Box>
            </Center>
          </Link>
        </SimpleGrid>
      </Card>
    </Main>
  );
};

ReportsPage.defaultProps = {};

export default ReportsPage;
