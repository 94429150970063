import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  Spinner,
  Center,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Collapse,
  Fade,
  useDisclosure,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { useAuth } from "hooks/redux-hooks";
import { Hooks } from "services";
import DTable from "components/atoms/DataTable";
import { Campus as ICampus, ControlTask } from "services/swagger";
import Badge from "components/atoms/Badge";
import Moment from "moment";
import { useHistory } from "react-router";
import Drawer, { DrawerProps } from "components/atoms/Drawer";
import { Inventory } from "models";
import {
  IoCamera,
  IoOptions,
  IoRefresh,
  IoRefreshCircle,
} from "react-icons/io5";
import ReactDatePicker from "react-datepicker";

import Confirm from "components/atoms/Confirm";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-lightbox/style.css";
import InventoryFilter from "components/organisms/InventoryFilter";
import { mainPaths } from "router/path";
import { UserRoles } from "models/user";

function InventoryPage() {
  const { project, role } = useAuth();
  const history = useHistory();
  const [selectedInventory, setSelectedInventory] = useState<Inventory>();
  const drawerRef = useRef<DrawerProps>(null);
  const [lightBoxImage, setLightBoxImage] = useState("");
  const toast = useToast();
  const filterDisc = useDisclosure();
  const [filterData, setFilterData] = useState<any>(null);

  const [getList, statusList, data] = Hooks.ListControlTask();
  const [updateControlTask, statusControlTask] = Hooks.UpdateControl();

  async function _updateControlTask(controlTask: ControlTask) {
    await updateControlTask({
      ...controlTask,
      isActive: !controlTask.isActive,
    });
  }
  useLayoutEffect(() => {
    getList(project.id);
  }, []);

  useEffect(() => {
    if (statusControlTask === "fulfilled") {
      getList(project.id);
    }
  }, [statusControlTask]);

  function _onFilterData(row: Inventory) {
    function _condition(a: any, b: any) {
      return !a || a === b;
    }

    const _data = [
      _condition(filterData?.campus?.value, row.campusID),
      _condition(filterData?.build?.value, row.buildID),
      _condition(filterData?.floor?.value, row.floorID),
      _condition(filterData?.room?.value, row.roomID),
      _condition(filterData?.userType?.value, row.userTypeID),
      _condition(filterData?.group?.value, row.groupID),
      _condition(filterData?.brand?.value, row.brandID),
      _condition(filterData?.model?.value, row.modelID),
    ];
    return !_data.includes(false);
  }

  useEffect(() => {
    if (!filterDisc.isOpen) {
      setFilterData({});
    }
  }, [filterDisc.isOpen]);

  return (
    <Main headerProps={{ title: "Denetim Listesi" }}>
      <Card p="3" mb="3">
        <Flex justifyContent="flex-end">
          <Button
            size="sm"
            colorScheme="orange"
            mr="3"
            onClick={() => filterDisc.onToggle()}
          >
            {filterDisc.isOpen ? "Filtre Kapat" : "Filtre"}
          </Button>
          {role !== UserRoles.view && role !== UserRoles.basic && (
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => history.push("/control-create")}
            >
              Yeni Denetim Ekle
            </Button>
          )}
        </Flex>
      </Card>

      {filterDisc.isOpen && (
        <InventoryFilter
          onFilter={(data: any) => {
            setFilterData(data);
          }}
        />
      )}

      {!!filterData && Object.values(filterData).length > 0 && (
        <Flex flexWrap="wrap" mb="3">
          {Object.values(filterData).map((data: any) =>
            !!data?.label ? (
              <Center p="2" px="4" bgColor="white" mr="3">
                <Text fontSize="xs" textAlign="center">
                  {data?.label}
                </Text>
              </Center>
            ) : null
          )}
        </Flex>
      )}

      {!!data?.data.data ? (
        <Card>
          <DTable
            columns={[
              {
                name: "ID",
                selector: "id",
                sortable: true,
                width: "100px",
              },
              {
                name: "Denetim Adı",
                selector: "name",
                sortable: true,
                width: "150px",
              },
              {
                name: "Kullanıcı Tipi",
                selector: "userTypeName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Açıklama",
                selector: "explain",
                sortable: true,
                width: "200px",
              },
              {
                name: "Tesis",
                selector: "campusName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Bina",
                selector: "buildName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Kat",
                selector: "floorName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Oda",
                selector: "roomName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Oluşturulma Tarihi",
                cell: (row: ICampus) => (
                  <span>{Moment(row.createDate).format("DD.MM.YYYY")}</span>
                ),
                width: "200px",
                selector: "createDate",
                columnType: "date",
              },
              {
                name: "Durum",
                cell: (row: ICampus) => <Badge isActive={!!row.isActive} />,
                width: "100px",
                selector: "isActive",
                columnType: "isActiveBoolean",
              },
              {
                name: "Aktif / Pasif",
                cell: (row: ICampus) => (
                  <Center w="90%">
                    <Confirm
                      onConfirm={() => {
                        _updateControlTask(row);
                      }}
                      text={`Denetim ${
                        !row.isActive ? "Aktif" : "Pasif"
                      } yapılsın mı?`}
                    >
                      {({ onOpen }) => (
                        <IconButton
                          size="sm"
                          onClick={onOpen}
                          aria-label="activepassive"
                          icon={<IoOptions />}
                        />
                      )}
                    </Confirm>
                  </Center>
                ),
                width: "100px",
              },
              {
                name: "Düzenle",
                cell: (row: any) => (
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() => {
                      history.push("/control-edit", { controlTask: row });
                    }}
                  >
                    Düzenle
                  </Button>
                ),
              },
              {
                name: "Görevler",
                width: "200px",
                cell: (row: any) => (
                  <Button
                    colorScheme="teal"
                    size="sm"
                    onClick={() => {
                      history.push("/control-tasks", { controlTask: row });
                    }}
                  >
                    Görevler
                  </Button>
                ),
              },
            ]}
            data={data?.data.data
              .filter(_onFilterData)
              .map((item: any) => ({ ...item, highlight: item.hasControl }))}
          />
        </Card>
      ) : (
        <Card>
          <Center>
            <Spinner />
          </Center>
        </Card>
      )}
    </Main>
  );
}

export default InventoryPage;
