import React, { FC, useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Flex,
  Button,
  Input,
  Box,
  Text,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import Dropzone from "components/atoms/Dropzone";
import { IoFolder, IoTrash } from "react-icons/io5";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { useToast } from "hooks/toast";
import { getApiUrl } from "services/urls";
import Confirm from "components/atoms/Confirm";

export enum FileUploadType {
  RiskAnalysisExpriDate = 1,
  RiskAnalysisPath = 2,
  UserInstructions = 3,
  UserGuidePath = 4,
  MaintenanceContract = 5,
}

interface FileUploaderFormProps {
  defaultValue: string;
  inventoryId: number;
  uploadType: FileUploadType;
  title: string;
  date?: string;
  onNeedReload?: () => void;
}

const FileUploaderForm: FC<FileUploaderFormProps> = (props) => {
  const { project } = useAuth();
  const [expireDate, setExpireDate] = useState("");
  const [uploadFile, statusUploadFile, dataUploadFile] = Hooks.UploadFile();
  const [deleteFile, statusDeleteFile] = Hooks.DeleteInventoryFile();
  const [uploadDate, statDate, dataDate] =
    Hooks.UpdateInventoryRiskAnalyzeDate();

  const toast = useToast();

  async function handleDrop(files: File[]) {
    const _file = files[0];
    try {
      await uploadFile(project.id, props.inventoryId, props.uploadType, _file);
    } catch (error) {}
  }
  async function handleChange(dateStr: string) {
    try {
      await uploadDate(props.inventoryId, dateStr);
    } catch (error) {}
  }

  useEffect(() => {
    if (statusUploadFile === "fulfilled") {
      toast({ title: "Dosya başarıyla yüklendi", status: "success" });
    }
  }, [statusUploadFile]);

  useEffect(() => {
    if (statusDeleteFile === "fulfilled") {
      toast({ title: "Dosya başarıyla kaldırıldı", status: "success" });
    }
  }, [statusDeleteFile]);

  const apiUrl = getApiUrl();

  return (
    <FormControl mt="2" mb="5">
      <FormLabel fontSize="sm" m="0">
        {props.title}
      </FormLabel>
      <Flex flexDir="column" alignItems="flex-start" mt="1">
        {!!props.defaultValue && statusDeleteFile !=="fulfilled"  && props.defaultValue.split(apiUrl)[1] !== "/" && (
          <HStack mb="2">
            <Button
              colorScheme="teal"
              size="sm"
              leftIcon={<IoFolder />}
              onClick={() => window.open(props.defaultValue, "_blank")}
            >
              Varolan dosyayı gör
            </Button>
            {/* TODO: Integrate delete service */}
            <Confirm
              colorScheme="orange"
              text="Ekipman dosyası silinecek. Bu işlemi onaylıyor musunuz?"
              onConfirm={() => {
                deleteFile(props.inventoryId, props.uploadType);
              }}
              confirmText="Dosyayı Sil"
            >
              {({ isOpen, onOpen }) => (
                <IconButton
                  rounded="sm"
                  size="sm"
                  colorScheme="orange"
                  icon={<IoTrash />}
                  aria-label="Delete"
                  onClick={onOpen}
                />
              )}
            </Confirm>
          </HStack>
        )}
        {props.uploadType === FileUploadType.RiskAnalysisPath && (
          <Input
            type="date"
            defaultValue={props.date}
            onChange={(e) => handleChange(e.currentTarget.value)}
          />
        )}
        {expireDate}
        <Dropzone
          hasAllType
          onDrop={handleDrop}
          loading={statusUploadFile === "pending"}
        />
        {statusUploadFile === "fulfilled" && (
          <Box>
            <Text>Dosya başarıyla yüklendi</Text>
          </Box>
        )}
      </Flex>
    </FormControl>
  );
};

FileUploaderForm.defaultProps = {};

export default FileUploaderForm;
