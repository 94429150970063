type p = number | string;

export const mainPaths = {
  Home: ["/", "/home"],
  Campus: "/campus",
  Build: "/campus/:campusId/build",
  Floor: "/campus/:campusId/build/:buildId/floor",
  Room: "/campus/:campusId/build/:buildId/floor/:floorId/room",
  Inventory: "/inventory",
  InventoryCreate: "/inventory/create",
  InventoryEdit: "/inventory/edit",
  ChangePassword: "/change-passwod",
  MaintenanceCreate: "/maintenance-create/:id",
  MaintenanceList: "/maintenance-list/:id",
  DoMaintenance: "/do-maintenance/:id",
  UserType: "/user-type/",
  ProcessDetail: "/process-detail"
};

export const authPaths = {
  Login: ["/", "/login", "/signin"],
};
