import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Form, InputElement } from "components/atoms/HookForm";
import { FC } from "react";

type LocationUpdaterProps = {
  title: string;
  defaultData?: {
    title: string;
  };
  onSubmit: (title: string) => void;
};

const LocationUpdater: FC<LocationUpdaterProps> = (props) => {
  const disc = useDisclosure();
  return (
    <Box>
      <Button size="sm" colorScheme="orange" onClick={disc.onToggle}>
        Düzenle
      </Button>
      <Modal isOpen={disc.isOpen} onClose={disc.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form
              onValid={(values: any) => {
                props.onSubmit(values.entityTitle);
              }}
            >
              <InputElement
                name="entityTitle"
                defaultValue={props.defaultData?.title}
              />

              <Box textAlign="right" mt="2">
                <Button size="sm" type="submit" colorScheme="blue">
                  Düzenle
                </Button>
              </Box>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default LocationUpdater;
