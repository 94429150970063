import React, {
  FC,
  Fragment,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  Box,
  Divider,
  Text,
  Center,
  Spinner,
  Flex,
  Badge,
  useDisclosure,
  Button,
  Collapse,
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import PerfectScrollbar from "components/atoms/PerfectScrollbar";
import { FaChevronDown } from "react-icons/fa";
import Moment from "moment";
import { TimelineStatus } from "./TimelineControl";
import { questionStatus } from "pages/Reports/ControlTaskReport/DocReport";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

interface NegativeQuestionsProps {}

enum NegativeType {
  maintenance,
  control,
}

const NegativeQuestions: FC<NegativeQuestionsProps> = (props) => {
  const { project } = useAuth();
  const [negativeType, setNegativeType] = useState<NegativeType>(
    NegativeType.maintenance
  );
  const [getC, statC, dataC] = Hooks.CompletedReport();
  const [getCT, statCT, dataCT] = Hooks.ControlTaskReport();
  useLayoutEffect(() => {
    getC(project.id);
    getCT(project.id);
  }, []);

  function filterControlTask(item: any) {
    return [TimelineStatus.done, TimelineStatus.lateDone].includes(
      item.statusCode
    );
  }

  function hasNegativeQuestion(item: any) {
    const questions = JSON.parse(item.answer);
    questions.forEach((question: any) => {
      if (question.QuestionType === 2) {
        if (
          parseFloat(question.SelectedItem) >
            parseFloat(question.InitialValue) ||
          parseFloat(question.SelectedItem) < parseFloat(question.EndValue)
        ) {
          return true;
        }
      } else {
        if (!questionStatus(question)) {
          return true;
        }
      }
    });
  }

  return (
    <Card w="100%" border="1px" borderColor="gray.200" minH="580px">
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="sm" textTransform="uppercase" color="gray.500">
          Olumsuz Yanıtlanan
          <br />
          <b>
            {negativeType === NegativeType.maintenance ? "Bakım" : "Denetİm"}
          </b>{" "}
          Soruları
        </Text>
        <Flex>
          <Button
            size="sm"
            mr="1"
            onClick={(e) => setNegativeType(NegativeType.maintenance)}
            variant={
              negativeType === NegativeType.maintenance ? "solid" : "outline"
            }
            colorScheme={
              negativeType === NegativeType.maintenance ? "teal" : undefined
            }
          >
            İç Bakım / Kontrol
          </Button>
          <Button
            size="sm"
            onClick={(e) => setNegativeType(NegativeType.control)}
            variant={
              negativeType === NegativeType.control ? "solid" : "outline"
            }
            colorScheme={
              negativeType === NegativeType.control ? "teal" : undefined
            }
          >
            Denetim
          </Button>
        </Flex>
      </Flex>
      <Divider my="3" />

      {negativeType === NegativeType.maintenance && (
        <Fragment>
          {statC !== "fulfilled" && (
            <Center h="200px">
              <Spinner />
            </Center>
          )}
          {dataC?.data.data.length > 0 && (
            <PerfectScrollbar maxHeight={500}>
              {dataC?.data.data
                .filter((item: any) => !item.isPositiveAnswer)
                .map((item: any, index: number) => (
                  <Maintenance key={index} item={item} />
                ))}
            </PerfectScrollbar>
          )}
          {dataC?.data.data.length === 0 && (
            <Center h="200px">
              <Text>Olumsuz yanıt bulunamadı!</Text>
            </Center>
          )}
        </Fragment>
      )}

      {negativeType === NegativeType.control && (
        <Fragment>
          {statCT !== "fulfilled" && (
            <Center h="200px">
              <Spinner />
            </Center>
          )}
          {dataCT?.data.data
            .filter(filterControlTask)
            .filter(hasNegativeQuestion).length > 0 && (
            <PerfectScrollbar maxHeight={500}>
              {dataCT?.data.data
                .filter(filterControlTask)
                .filter(hasNegativeQuestion)
                .map((item: any, index: number) => (
                  <ControlTask key={index} item={item} />
                ))}
            </PerfectScrollbar>
          )}
          {dataCT?.data.data
            .filter(filterControlTask)
            .filter(hasNegativeQuestion).length === 0 && (
            <Center h="200px">
              <Text>Olumsuz yanıt bulunamadı!</Text>
            </Center>
          )}
        </Fragment>
      )}
    </Card>
  );
};

const Maintenance = ({ item }: any) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box borderWidth="1px" p="3">
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Text>{item.name}</Text>
          <Text fontWeight="bold" fontSize="xs">
            {item.maintenancePeriodName}
          </Text>
          <Text fontWeight="bold" fontSize="xs">
            {Moment(item.completedDate).format("DD.MM.YYYY")}
          </Text>
        </Box>

        <Button size="sm" onClick={onToggle} rightIcon={<FaChevronDown />}>
          Sorular
        </Button>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Questions data={item.maintenanceQuestion} />
      </Collapse>
    </Box>
  );
};

const Questions = (props: any) => {
  const [negatives, setNegatives] = useState<any[]>([]);

  useEffect(() => {
    const questions = JSON.parse(props.data);
    if (questions) {
      setNegatives([]);
      questions.forEach((question: any) => {
        if (question.QuestionType === 2) {
          if (question.trueFalseAnswer === true) {
            if (question.SelectedItem === "No") {
              setNegatives((_d) => [..._d, question]);
            }
          } else {
            if (question.SelectedItem === "Yes") {
              setNegatives((_d) => [..._d, question]);
            }
          }
        } else if (question.QuestionType === 1) {
          if (
            parseFloat(question.SelectedItem) <
              parseFloat(question.InitialValue) ||
            parseFloat(question.SelectedItem) > parseFloat(question.EndValue)
          ) {
            setNegatives((_d) => [..._d, question]);
          }
        }
      });
    }
  }, []);

  return (
    <Box p="3" mt="3">
      {negatives.map((negative) => (
        <Box p="3" borderWidth="1px">
          <Flex justifyContent="space-between">
            <Text>{negative.Question}</Text>
            <Text>
              Yanıt:{" "}
              {negative.SelectedItem === "Yes"
                ? "Evet"
                : negative.SelectedItem === "No"
                ? "Hayır"
                : negative.SelectedItem}
            </Text>
          </Flex>
          <Text></Text>
          <Flex justifyContent="space-between">
            <Badge>
              {negative.QuestionType === 1 ? "Değer Girişli" : "Evet / Hayır"}
            </Badge>
            <Text fontSize="xs">
              {negative.QuestionType === 1
                ? `${negative.InitialValue} - ${negative.EndValue}`
                : negative.trueFalseAnswer
                ? "Evet"
                : "Hayır"}
            </Text>
          </Flex>
        </Box>
      ))}
    </Box>
  );
};

const ControlTask = ({ item }: any) => {
  const { isOpen, onToggle } = useDisclosure();

  function getQuestions(): Array<any> {
    const questionsSource: any[] = JSON.parse(item.answer);
    return questionsSource || [];
  }

  const questList = getQuestions().filter(
    (item) => questionStatus(item) === false
  );
  if (questList.length === 0) {
    return null;
  }
  return (
    <Box borderWidth="1px" p="3">
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Text>{item.controlName}</Text>
          <Text fontWeight="bold" fontSize="xs">
            {item.controlPeriodName}
          </Text>
          <Text fontWeight="bold" fontSize="xs">
            {Moment(item.complatedDate).format("DD.MM.YYYY")}
          </Text>
        </Box>

        <Button size="sm" onClick={onToggle} rightIcon={<FaChevronDown />}>
          Sorular
        </Button>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Box p="5">
          {questList.map((quest, key) => (
            <ControlTaskQuestion quest={quest} key={key} />
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

const ControlTaskQuestion = ({ quest }: any) => {
  return (
    <Flex borderWidth="1px" flexDir="column" fontSize="sm" p="2" mb="1">
      <Flex justifyContent="space-between">
        <Text>{quest.question}</Text>
        <Text>{quest.answer || "Yanıt Yok"}</Text>
      </Flex>
      <Flex flexDir="column" mt="3">
        <Text fontSize="xs">Not</Text>
        <Text>{quest.note || "Not Yok"}</Text>
      </Flex>
    </Flex>
  );
};

NegativeQuestions.defaultProps = {};

export default NegativeQuestions;
