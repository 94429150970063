import React, {
  FC,
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  SimpleGrid,
  FormControl,
  FormLabel,
  Divider,
  Flex,
  Button,
  IconButton,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Inventory } from "models";
import { useForm } from "react-hook-form";
import {
  FileElement,
  InputElement,
  SelectElement,
} from "components/atoms/FormElements";
import { Card, Modal, ModalProps } from "components/atoms";
import RSelect from "react-select";
import { useAuth } from "hooks/redux-hooks";
import { Hooks } from "services";
import { useToast } from "hooks/toast";
import { mainPaths } from "router/path";
import { Demand } from "services/swagger";
import { IoAdd } from "react-icons/io5";
import { Form, InputElement as HookInput } from "components/atoms/HookForm";

interface InventoryEditPageProps {}

const SpecialSelect = memo((props: any) => {
  return (
    <FormControl>
      <FormLabel>{props.label}</FormLabel>
      <RSelect
        isClearable
        isSearchable
        value={
          !!props?.value
            ? {
                label: props.data?.find(
                  (item: any) => item.id === parseInt(props.value)
                )?.name,
                value: props.data?.find(
                  (item: any) => item.id === parseInt(props.value)
                )?.id,
              }
            : null
        }
        onChange={(value) => {
          props.onChange(value);
        }}
        isLoading={props.status === "pending"}
        placeholder="Seç"
        isDisabled={!props.data}
        options={props.data?.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))}
      />
    </FormControl>
  );
});

const InventoryEditPage: FC<
  InventoryEditPageProps & RouteComponentProps<{}, {}, { data: Demand }>
> = (props) => {
  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      campusID: props.location.state.data.campusID as any,
      buildID: props.location.state.data.buildID as any,
      floorID: props.location.state.data.floorID as any,
      roomID: props.location.state.data.roomID as any,
      userTypeID: props.location.state.data.userTypeID as any,
      demandGroupID: props.location.state.data.demandGroupID as any,
      name: props.location.state.data.name as any,
      explain: props.location.state.data.explain as any,
    },
  });

  const toast = useToast();
  const history = useHistory();

  useLayoutEffect(() => {
   
  }, []);

  const { project } = useAuth();
  const [updateInventory, statusUI, dataUI] = Hooks.UpdateDemand();
  const [getCampus, statCampus, dataCampus] = Hooks.CampusList();
  const [getBuild, statBuild, dataBuild, resetBuild] = Hooks.BuildList();
  const [getFloor, statFloor, dataFloor, resetFloor] = Hooks.FloorList();
  const [getRoom, statRoom, dataRoom, resetRoom] = Hooks.RoomList();
  const [getUserType, statUT, dataUT] = Hooks.UserTypeList();
  const [getGroup, statGroup, dataGroup] = Hooks.GroupList();
  const [getBrand, statBrand, dataBrand, resetBrand] = Hooks.BrandList();
  const [getModel, statModel, dataModel, resetModel] = Hooks.ModelList();
  const [isMounted, setMounted] = useState(false);
  const [getDemandGroup, statDemandGroup, dataDemandGroup] =
    Hooks.DemandGroupList();
  const [addDemandGroup, statAddDemandGroup, dataAddDemandGroup] =
    Hooks.AddDemandGroup();
  const groupModal = useRef<ModalProps>(null);

  async function onValid(values: any) {
    await updateInventory({
      ...props.location.state.data,
      ...values,
      demandGroupID: parseInt(values.demandGroupID),
    });
  }

  useLayoutEffect(() => {
    getCampus(project.id);
    getUserType();
    getGroup(project.id);
    getDemandGroup(project.id);
  }, []);

  useEffect(() => {
    if (!!watch("campusID")) {
      if (!!dataBuild) {
        setValue("buildID", null);
      }
      getBuild(watch("campusID"));
    } else if (!watch("campusID") && !!dataBuild) {
      resetBuild();
      setValue("buildID", null);
    }
  }, [watch("campusID")]);

  useEffect(() => {
    if (!!watch("buildID")) {
      if (!!dataFloor) {
        setValue("floorID", null);
      }
      getFloor(watch("buildID"));
    } else if (!watch("buildID") && !!dataFloor) {
      resetFloor();
      setValue("floorID", null);
    }
  }, [watch("buildID")]);

  useEffect(() => {
    if (!!watch("floorID")) {
      if (!!dataRoom) {
        setValue("roomID", null);
      }
      getRoom(watch("floorID"));
    } else if (!watch("floorID") && !!dataRoom) {
      resetRoom();
      setValue("roomID", null);
    }
  }, [watch("floorID")]);

  useEffect(() => {
    if (statusUI === "fulfilled") {
      if (dataUI) {
        if (dataUI?.data?.success === true) {
          toast({ status: "success", title: "Talep Başarıyla Güncellendi" });
          history.push("/demand");
        } else if (dataUI?.data?.success === false) {
          toast({ status: "error", title: "Talep Güncellenemedi!" });
        }
      }
    }
  }, [statusUI]);

  return (
    <Main headerProps={{ title: "Talep Düzenle" }}>
      <form onSubmit={handleSubmit(onValid)}>
        <Card>
          <Tabs isFitted>
            <TabList>
              <Tab>Lokasyon Bilgileri</Tab>
              <Tab>Teknik Bilgiler Ve Kullanıcı Tipi</Tab>
              <Tab>Genel Bilgiler</Tab>
            </TabList>
            <TabPanels>
              <TabPanel pt="10">
                <SimpleGrid columns={[1, 2]} gap="5">
                  <SpecialSelect
                    onChange={(value: any) => {
                      setValue("campusID", value?.value);
                    }}
                    value={watch("campusID")}
                    data={dataCampus?.data?.data}
                    status={statCampus}
                    label="Tesis"
                  />
                  <SpecialSelect
                    onChange={(value: any) => {
                      setValue("buildID", value?.value);
                    }}
                    value={watch("buildID")}
                    data={dataBuild?.data?.data}
                    status={statBuild}
                    label="Bina"
                  />
                  <SpecialSelect
                    onChange={(value: any) => {
                      setValue("floorID", value?.value);
                    }}
                    value={watch("floorID")}
                    data={dataFloor?.data?.data}
                    status={statFloor}
                    label="Kat"
                  />
                  <SpecialSelect
                    onChange={(value: any) => {
                      setValue("roomID", value?.value);
                    }}
                    value={watch("roomID")}
                    data={dataRoom?.data?.data}
                    status={statRoom}
                    label="Oda"
                  />
                </SimpleGrid>
              </TabPanel>
              <TabPanel>
                <SimpleGrid columns={2} gap="5">
                  <Flex alignItems="flex-end">
                    <SelectElement
                      name="demandGroupID"
                      label="Grup"
                      isRequired
                      control={control}
                      data={dataDemandGroup?.data.data.map((item: any) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                    <IconButton
                      icon={<IoAdd />}
                      ml="2"
                      variant="outline"
                      aria-label="add"
                      onClick={() => {
                        groupModal.current?.open();
                      }}
                    />
                  </Flex>
                  <SpecialSelect
                    onChange={(value: any) => {
                      setValue("userTypeID", value?.value);
                    }}
                    value={watch("userTypeID")}
                    data={dataUT?.data?.data}
                    status={statUT}
                    label="Kullanıcı Tipi"
                  />
                </SimpleGrid>
              </TabPanel>
              <TabPanel pt="10">
                <SimpleGrid columns={[1, 2]} gap="5">
                  <InputElement
                    name="Name"
                    label="Ekipman Adı"
                    isRequired
                    control={control}
                    defaultValue={watch("name")}
                  />
                  <InputElement
                    name="Explain"
                    label="Açıklama"
                    control={control}
                    defaultValue={watch("explain")}
                  />
                </SimpleGrid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Card>

        <Card mt="3">
          <Flex justifyContent="flex-end">
            <Button colorScheme="purple" type="submit">
              Düzenle
            </Button>
          </Flex>
        </Card>
      </form>
      <Modal title="Grup Ekle" ref={groupModal}>
        <Form
          onValid={async ({ name }: any) => {
            await addDemandGroup({ name: name, projectID: project.id });
            toast({ status: "success", title: "Grup başarıyla eklendi!" });
            getDemandGroup(project.id);
            groupModal.current?.close();
          }}
        >
          <HookInput name="name" label="İsim" />
          <Button mt="2" type="submit">
            Grup Ekle
          </Button>
        </Form>
      </Modal>
    </Main>
  );
};

InventoryEditPage.defaultProps = {};

export default InventoryEditPage;
