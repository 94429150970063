import React, { FC, useLayoutEffect, useRef, useState } from "react";
import {
  Box,
  Center,
  Text,
  Spinner,
  Button,
  Flex,
  FormControl,
  FormLabel,
  useDisclosure,
} from "@chakra-ui/react";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import Main from "components/templates/Main";
import { Card, ModalProps } from "components/atoms";
import DataTable from "components/atoms/DataTable";
import { TimelineLegal } from "models";
import { TimelineStatus } from "components/organisms/TimelineControl";
import Moment from "moment";
import { FaFile } from "react-icons/fa";
import ModalAtom from "components/atoms/Modal";
import RSelect from "react-select";
import InventoryFilter from "components/organisms/InventoryFilter";
import { isSieOrNo } from "helper/project-mode";

interface CompletedLegalProps {}

const CompletedLegal: FC<CompletedLegalProps> = (props) => {
  const { project } = useAuth();
  const [getData, statusData, dataData] = Hooks.ControlTaskReport();
  const [getPeriods, statPeriods, dataPeriods] = Hooks.ListPeriod();
  const [getUT, statUT, dataUT] = Hooks.UserTypeList();
  const [filterData, setFilterData] = useState<any[]>([]);
  const [filterData2, setFilterData2] = useState<any>();
  const filterModalRef = useRef<ModalProps>(null);
  const filterDisc = useDisclosure();
  const [statusFilter, setStatusFilter] = useState([1, 2]);

  useLayoutEffect(() => {
    getData(project.id);
    getPeriods(project.id);
    getUT();
  }, []);

  function onFilter(item: any) {
    const filters: boolean[] = [];
    filterData.forEach((_filter) => {
      filters.push(item[_filter.name] === _filter.value);
    });

    return !filters.includes(false);
  }

  function _onFilterData(row: any) {
    function _condition(a: any, b: any) {
      return !a || a === b;
    }

    const _data = [
      _condition(filterData2?.campus?.value, row.campusID),
      _condition(filterData2?.build?.value, row.buildID),
      _condition(filterData2?.floor?.value, row.floorID),
      _condition(filterData2?.room?.value, row.roomID),
      _condition(filterData2?.userType?.value, row.userTypeID),
      _condition(filterData2?.group?.value, row.groupID),
      _condition(filterData2?.brand?.value, row.brandID),
      _condition(filterData2?.model?.value, row.modelID),
    ];
    return !_data.includes(false);
  }

  function isCompleted(row: any) {
    return [TimelineStatus.done, TimelineStatus.lateDone].includes(
      row.statusCode
    );
  }

  return (
    <Main headerProps={{ title: "Gerçekleşen Denetimler" }}>
      <Card p="3" mb="3">
        <Flex justifyContent="space-between">
          <Flex>
            <Button
              size="sm"
              mr="1"
              onClick={() =>
                setStatusFilter((prev) =>
                  prev.includes(1) ? prev.filter((i) => i !== 1) : [...prev, 1]
                )
              }
              variant={statusFilter.includes(1) ? "solid" : "outline"}
              colorScheme="purple"
            >
              Tamamlanan
            </Button>
            <Button
              size="sm"
              mr="1"
              onClick={() =>
                setStatusFilter((prev) =>
                  prev.includes(2) ? prev.filter((i) => i !== 2) : [...prev, 2]
                )
              }
              variant={statusFilter.includes(2) ? "solid" : "outline"}
              colorScheme="purple"
            >
              Planlı Zamanda Gerçekleşmeyen
            </Button>
          </Flex>
          <Button
            size="sm"
            colorScheme="orange"
            onClick={() => filterDisc.onToggle()}
          >
            {filterDisc.isOpen ? "Filtre Kapat" : "Filtre"}
          </Button>
        </Flex>
      </Card>

      {filterDisc.isOpen && (
        <InventoryFilter
          noGroups
          onFilter={(data: any) => {
            setFilterData2(data);
          }}
        />
      )}
      <Card>
        {statusData !== "fulfilled" || !dataData ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <DataTable
            onClickFilter={() => {
              filterModalRef.current?.open();
            }}
            data={dataData.data.data
              .filter((item: any) => {
                let _statuses: any[] = [];
                if (statusFilter.includes(1)) {
                  _statuses = [
                    ..._statuses,
                    ...[TimelineStatus.done, TimelineStatus.lateDone],
                  ];
                }
                if (statusFilter.includes(2)) {
                  _statuses = [..._statuses, TimelineStatus.late];
                }
                return _statuses.includes(item.statusCode);
              })
              .filter(onFilter)
              .filter(_onFilterData)}
            columns={[
              {
                name: "Kullanıcı Tipi",
                selector: "userTypeName",
                width: "240px",
              },
              { name: "Denetim Adı", selector: "controlName", width: "250px" },
              {
                name: `Periyodik ${isSieOrNo("Kontrol", "Muayene")} Raporu`,
                cell: (row: any) => {
                  if(!isCompleted(row)) {
                    return "Gerçekleşmedi"
                  }
                  return (
                    <Button
                      size="xs"
                      colorScheme="blue"
                      onClick={() => {
                        localStorage.removeItem("doc-report");
                        localStorage.setItem("doc-report", JSON.stringify(row));
                        window.open("/doc-report-control-task", "_blank");
                      }}
                      leftIcon={<FaFile />}
                    >
                      Raporu Görüntüle
                    </Button>
                  );
                },
                width: "200px",
              },
              {
                name: "Soru Grubu",
                selector: "controlQuestionGroupName",
                width: "200px",
              },
              { name: "Kullanıcı", selector: "userName", width: "200px" },
              {
                name: "Başlangıç Tarihi",
                cell: (row: TimelineLegal) =>
                  Moment(row.startDate).format("DD.MM.YYYY"),
                width: "200px",
              },
              {
                name: "Planlı Denetim Tarihi",
                cell: (row: TimelineLegal) =>
                  Moment(row.endDate).format("DD.MM.YYYY"),
                width: "200px",
              },
              {
                name: "Gerçekleşen Tarih",
                cell: (row: any) => {
                  const _isCompleted = isCompleted(row);
                  if (!_isCompleted) return "Gerçekleşmedi";
                  return Moment(row.complatedDate).format("DD.MM.YYYY HH:mm");
                },
                width: "200px",
              },
              { name: "Tesis", selector: "campusName", width: "200px" },
              { name: "Bina", selector: "buildName", width: "200px" },
              { name: "Kat", selector: "floorName", width: "200px" },
              { name: "Oda", selector: "roomName", width: "200px" },
            ]}
          />
        )}
      </Card>
      <ModalAtom ref={filterModalRef} title="Filtre" noForm>
        <FormControl mt="5" mb="20">
          <FormLabel>Kullanıcı Tipi</FormLabel>
          <RSelect
            isClearable
            placeholder="Seçim Yap"
            onChange={(value) => {
              const keyName = "userTypeId";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                  },
                ]);
              }
            }}
            options={dataUT?.data.data.map((period: any) => ({
              value: period.id,
              label: period.name,
            }))}
          />
        </FormControl>
      </ModalAtom>
    </Main>
  );
};

CompletedLegal.defaultProps = {};

export default CompletedLegal;
