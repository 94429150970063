import React, { useEffect, useLayoutEffect, useRef } from "react";
import {
  Flex,
  Box,
  Text,
  SimpleGrid,
  FormLabel,
  Input,
  FormControl,
  Select,
  Divider,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  FormHelperText,
  IconButton,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { useForm, Controller } from "react-hook-form";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import {
  FileElement,
  InputElement,
  SelectElement,
} from "components/atoms/FormElements";
import ReactSelect from "react-select";
import { useHistory } from "react-router";
import { UserRoles } from "models/user";
import Modal, { ModalProps } from "components/atoms/Modal";
import { Form, InputElement as HookInput } from "components/atoms/HookForm";
import { useToast } from "hooks/toast";
import { IoAdd } from "react-icons/io5";

function DocumentCreate() {
  const { project, user, role } = useAuth();
  const { control, handleSubmit, watch, setValue } = useForm();
  const [addInventory, statusInventory, dataInventory] = Hooks.AddInventory();
  const [addDocument, statDoc, dataDoc] = Hooks.AddDocument();
  const [getTypes, , userTypes] = Hooks.UserTypeList();
  const toast = useToast();
  const history = useHistory();

  const [getCampuses, statCampuses, dataCampuses] = Hooks.CampusList();
  const [getBuilds, statBuilds, dataBuilds] = Hooks.BuildList();
  const [getFloors, statFloors, dataFloors] = Hooks.FloorList();
  const [getRooms, statRooms, dataRooms] = Hooks.RoomList();
  const [getGroups, statGroups, dataGroups] = Hooks.GroupList();
  const [getBrands, statBrands, dataBrands] = Hooks.BrandList();
  const [getModels, statModels, dataModels] = Hooks.ModelList();
  const [getCapacities, statCapacities, dataCapacities] = Hooks.CapacityList();
  const [getUsers, statUsers, dataUsers] = Hooks.UserList();

  const [onAddGroup, statusAddGroup] = Hooks.AddGroup();
  const [onAddBrand, statusAddBrand] = Hooks.AddBrand();
  const [onAddModel, statusAddModel] = Hooks.AddModel();

  const groupModal = useRef<ModalProps>(null);
  const brandModal = useRef<ModalProps>(null);
  const modelModal = useRef<ModalProps>(null);

  useLayoutEffect(() => {
    if (!!!dataCampuses) {
      getCampuses(project.id);
    }
    getUsers(project.id);
    getGroups(project.id);
    getTypes();
    getCapacities();
  }, []);

  useEffect(() => {
    if (!isNaN(parseInt(watch("campusID")))) {
      getBuilds(parseInt(watch("campusID")));
      setValue("buildID", undefined);
    }
  }, [watch("campusID")]);

  useEffect(() => {
    if (!isNaN(parseInt(watch("buildID")))) {
      getFloors(parseInt(watch("buildID")));
      setValue("floorID", undefined);
    }
  }, [watch("buildID")]);

  useEffect(() => {
    if (!isNaN(parseInt(watch("floorID")))) {
      getRooms(parseInt(watch("floorID")));
      setValue("roomID", undefined);
    }
  }, [watch("floorID")]);

  useEffect(() => {
    if (!isNaN(parseInt(watch("groupID")))) {
      getBrands(parseInt(watch("groupID")));
      setValue("modelID", undefined);
    }
  }, [watch("groupID")]);

  useEffect(() => {
    if (!isNaN(parseInt(watch("brandID")))) {
      getModels(parseInt(watch("brandID")));
      setValue("modelID", undefined);
    }
  }, [watch("brandID")]);

  function onValid(values: any) {
   
    addDocument({
      projectID: project.id,
      userTypeID: parseInt(values.userTypeID),
      campusID: parseInt(values.campusID),
      buildID: parseInt(values.buildID),
      floorID: parseInt(values.floorID),
      roomID: parseInt(values.roomID),
      groupID: parseInt(values.groupID),
      brandID: parseInt(values.brandID),
      modelID: parseInt(values.modelID),
      inventoryCapacityID: parseInt(values.inventoryCapacityID),
      documentName: values.documentName,
      explain: values.explain,
      productionYear: values.productionYear,
      unit: values.unit,
      assignUserID: parseInt(values.assignUserID)
    });
  }

  useEffect(() => {
    if (!!dataDoc) {
      if (dataDoc?.data.success) {
        toast({ status: "success", title: "Belge başarıyla eklendi" });
        history.push("/document");
      } else {
        toast({
          status: "error",
          title: "Belge eklenirken hata!",
          description: dataDoc?.data.message,
        });
      }
    }
  }, [dataDoc]);

  return (
    <Main headerProps={{ title: "Belge Oluştur" }}>
      <Card>
        <form onSubmit={handleSubmit(onValid)}>
          <Tabs isFitted>
            <TabList>
              <Tab>Lokasyon Bilgileri</Tab>
              <Tab>Teknik Bilgiler Ve Kullanıcı Tipi</Tab>
              <Tab>Genel Bilgiler</Tab>
            </TabList>
            <TabPanels>
              <TabPanel pt="10">
                <SimpleGrid columns={[1, 2]} gap="5">
                  <SelectElement
                    name="campusID"
                    label="Tesis"
                    control={control}
                    isRequired
                    data={dataCampuses?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                  <SelectElement
                    name="buildID"
                    label="Bina"
                    control={control}
                    isRequired
                    data={dataBuilds?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                  <SelectElement
                    name="floorID"
                    label="Kat"
                    isRequired
                    control={control}
                    data={dataFloors?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                  <SelectElement
                    name="roomID"
                    label="Oda"
                    isRequired
                    control={control}
                    data={dataRooms?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                </SimpleGrid>
              </TabPanel>
              <TabPanel pt="10">
                <SimpleGrid columns={[1, 2]} gap="5">
                  <Flex alignItems="flex-end">
                    <SelectElement
                      name="groupID"
                      label="Grup"
                      isRequired
                      control={control}
                      data={dataGroups?.data.data.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                    <IconButton
                      icon={<IoAdd />}
                      ml="2"
                      variant="outline"
                      aria-label="add"
                      onClick={() => {
                        groupModal.current?.open();
                      }}
                    />
                  </Flex>
                  <Flex alignItems="flex-end">
                    <SelectElement
                      name="brandID"
                      label="Marka"
                      control={control}
                      data={dataBrands?.data.data.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                    <IconButton
                      icon={<IoAdd />}
                      ml="2"
                      variant="outline"
                      aria-label="add"
                      onClick={() => {
                        if (watch("groupID") != 0) {
                          brandModal.current?.open();
                        } else {
                          toast({ status: "error", title: "Grup seçiniz!" });
                        }
                      }}
                    />
                  </Flex>
                  <Flex alignItems="flex-end">
                    <SelectElement
                      name="modelID"
                      label="Model"
                      control={control}
                      data={dataModels?.data.data.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                    <IconButton
                      icon={<IoAdd />}
                      ml="2"
                      variant="outline"
                      aria-label="add"
                      onClick={() => {
                        if (watch("brandID") != 0) {
                          modelModal.current?.open();
                        } else {
                          toast({ status: "error", title: "Marka seçiniz!" });
                        }
                      }}
                    />
                  </Flex>
                </SimpleGrid>
                <Divider my="5" />
                <SimpleGrid columns={2} gap="5">
                  <SelectElement
                    name="userTypeID"
                    label="Kullanıcı Tipi"
                    isRequired
                    control={control}
                    data={userTypes?.data.data
                      .filter((item) =>
                        role === UserRoles.admin
                          ? true
                          : user.userTypes
                              .map((_type: any) => _type.id)
                              .includes(item.id)
                      )
                      .map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                  />
                  <SelectElement
                    name="assignUserID"
                    label="Atanan Kullanıcı"
                    isRequired
                    control={control}
                    data={dataUsers?.data.data.map((item) => ({
                      value: item.id,
                      label: (item as any).username,
                    }))}
                  />
                </SimpleGrid>
              </TabPanel>
              <TabPanel pt="10">
                <SimpleGrid columns={[1, 2]} gap="5">
                  <InputElement
                    name="documentName"
                    label="Belge Adı"
                    isRequired
                    control={control}
                  />
                  <InputElement
                    name="explain"
                    label="Açıklama"
                    control={control}
                  />
                  {/* <SelectElement
                    name="inventoryCapacityID"
                    label="Kapasite "
                    control={control}
                    data={(dataCapacities?.data.data as any[])?.map(
                      (capacity) => ({
                        label: capacity.name,
                        value: capacity.id,
                      })
                    )}
                  />
                  <InputElement
                    name="Unit"
                    label="Kapasite Değeri"
                    control={control}
                  />

                  <InputElement
                    name="productionYear"
                    label="Üretim Yılı"
                    control={control}
                  /> */}
                </SimpleGrid>
                <Flex justifyContent="flex-end" mt="5">
                  <Button colorScheme="teal" type="submit">
                    Oluştur
                  </Button>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </form>
      </Card>
      <Modal title="Grup Ekle" ref={groupModal}>
        <Form
          onValid={async ({ name }: any) => {
            await onAddGroup({ name: name, projectID: project.id });
            toast({ status: "success", title: "Grup başarıyla eklendi!" });
            getGroups(project.id);
            groupModal.current?.close();
          }}
        >
          <HookInput name="name" label="İsim" />
          <Button mt="2" type="submit">
            Grup Ekle
          </Button>
        </Form>
      </Modal>
      <Modal title="Marka Ekle" ref={brandModal}>
        <Form
          onValid={async ({ name }: any) => {
            await onAddBrand({
              name: name,
              groupID: parseInt(watch("groupID")),
            });
            toast({ status: "success", title: "Marka başarıyla eklendi!" });
            getBrands(parseInt(watch("groupID")));
            brandModal.current?.close();
          }}
        >
          <HookInput name="name" label="İsim" />
          <Button mt="2" type="submit">
            Marka Ekle
          </Button>
        </Form>
      </Modal>
      <Modal title="Model Ekle" ref={modelModal}>
        <Form
          onValid={async ({ name }: any) => {
            await onAddModel({
              name: name,
              brandID: parseInt(watch("brandID")),
            });
            toast({ status: "success", title: "Model başarıyla eklendi!" });
            getModels(parseInt(watch("brandID")));
            modelModal.current?.close();
          }}
        >
          <HookInput name="name" label="İsim" />
          <Button mt="2" type="submit">
            Model Ekle
          </Button>
        </Form>
      </Modal>
    </Main>
  );
}

export default DocumentCreate;
