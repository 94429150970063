import React, { FC, useEffect, useLayoutEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  useToast,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { Form, InputElement, SelectElement } from "components/atoms/HookForm";
import { Hooks } from "services";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useAuth } from "hooks/redux-hooks";
import RSelect from "react-select";

interface ControlCreateProps {}

const ControlCreate: FC<
  ControlCreateProps & RouteComponentProps<{ id: string }>
> = (props) => {
  const { project } = useAuth();
  const [onAdd, statusAdd, dataAdd] = Hooks.AddDocumentMaintenance();
  const [getUsers, statUsers, dataUsers] = Hooks.UserList();
  const [getPeriod, statusPeriod, dataPeriod] =
    Hooks.DocumentMaintenancePeriodList();
  const toast = useToast();
  const history = useHistory();

  function onValid(values: any) {
    onAdd({
      documentPeriodID: parseInt(values.period),
      beforeDay: parseInt(values.beforeDay),
      documentID: parseInt(props.match.params.id),
      startDate: values.startDate,
      question: values.question,
    });
  }

  useEffect(() => {
    if (!!dataAdd) {
      if (dataAdd.data.success) {
        toast({ status: "success", title: "Kontrol başarıyla eklendi!" });
        history.push(`/document/${props.match.params.id}/control`);
      } else {
        toast({
          status: "error",
          title: "Kontrol eklenemedi!",
          description: dataAdd.data.message,
        });
      }
    }
  }, [dataAdd]);

  useLayoutEffect(() => {
    getPeriod();
    getUsers(project.id);
  }, []);

  return (
    <Main headerProps={{ title: "Belge İçin Kontrol Oluştur" }}>
      <Card>
        <Form onValid={onValid}>
          <SelectElement
            data={dataPeriod?.data.data.map((period: any) => ({
              label: period.name,
              value: period.id,
            }))}
            label="Periyot"
            name="period"
          />
          <InputElement
            label="Lütfen Seçmiş Olduğunuz Periyodun Bir Önceki Periyot Tarihini Giriniz!"
            type="date"
            name="startDate"
            containerProps={{ mt: "3" }}
            isRequired
          />
          <InputElement
            label="Başlangıç Tarihinden Kaç Gün Önce Doküman Yüklenebilir?"
            name="beforeDay"
            containerProps={{ mt: "3" }}
            isRequired
          />
          <InputElement
            label="Soru"
            name="question"
            isRequired
            containerProps={{ mt: "3" }}
          />
          {/* <SelectElement
            data={dataUsers?.data.data.map((user: any) => ({
              label: user.name,
              value: user.id,
            }))}
            containerProps={{ mt: "3" }}
            label="Atanan Kullanıcı"
            name="assignUserID"
          /> */}
          <Button colorScheme="blue" type="submit" mt="5">
            Oluştur
          </Button>
        </Form>
      </Card>
    </Main>
  );
};

ControlCreate.defaultProps = {};

export default ControlCreate;
