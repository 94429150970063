import { api, __mode__ } from "consts";

const BASE_URL = "https://siemensapi.tesisyonetim.pro";
const TEST_URL = "https://siemensapitest.tesisyonetim.pro";
const APL_URL = "https://tysapi.tesisyonetim.pro";
const OTK_URL = "https://otokocapi.tesisyonetim.net";

export function getApiUrl() {
  switch (__mode__) {
    case api.siemens:
      return BASE_URL;
    case api.otk:
      return OTK_URL;
    case api.apl:
      return APL_URL;
    case api.test:
      return TEST_URL;
    default:
      throw new Error("Have not declare app mode");
  }
}