import { SimpleGrid, Skeleton } from "@chakra-ui/react";
import AdvancedNumberWidget from "components/molecules/AdvancedNumberWidget";
import MaintenanceNotes from "components/organisms/MaintenanceNotes";
import NegativeQuestions from "components/organisms/NegativeQuestion";
import Main from "components/templates/Main";
import { isSieOrNo } from "helper/project-mode";
import { useAuth } from "hooks/redux-hooks";
import { useResponsive } from "hooks/responsive";
import { HomeStats } from "models";
import { useLayoutEffect } from "react";
import { IoClipboardOutline, IoConstructOutline } from "react-icons/io5";
import { Hooks } from "services";

interface Props {}

const ProcessDetal = (props: Props) => {
  const { project } = useAuth();
  const [getStats, statStats, dataStats] = Hooks.HomeStatistic();
  const { isSm } = useResponsive();

  useLayoutEffect(() => {
    getStats(project.id);
  }, []);

  return (
    <Main headerProps={{ title: "İşlem Detayları" }}>
      <SimpleGrid columns={2} spacing="5">
        <Skeleton isLoaded={statStats === "fulfilled" && !!dataStats}>
          <AdvancedNumberWidget
            icon={IoClipboardOutline}
            title="Kayıtlı EKİPMAN"
            value={(dataStats?.data.data as HomeStats)?.totalInventoryCount}
            valueType="Adet"
            color="blue"
            link="/inventory"
            extraData={[
              {
                title: "İç Bakım",
                value: dataStats?.data.data.unDoneInternalMaintenance,
              },
              {
                title: "Dış Bakım",
                value: dataStats?.data.data.unDoneExternalMaintenance,
              },
              {
                title: `Periyodik ${isSieOrNo("Kontrol", "Muayene")}`,
                value: dataStats?.data.data.unDoneLegalInspectionMaintenance,
              },
            ]}
          />
        </Skeleton>
        <Skeleton isLoaded={statStats === "fulfilled" && !!dataStats}>
          <AdvancedNumberWidget
            icon={IoClipboardOutline}
            title="Kayıtlı Belge"
            value={dataStats?.data.data.documentTotalCount}
            valueType="Adet"
            color="red"
            link="/document"
            extraData={[
              {
                title: "Belge Takip",
                value: dataStats?.data.data.unDoneDocumentMaintenance,
              },
            ]}
          />
        </Skeleton>
        <Skeleton isLoaded={statStats === "fulfilled" && !!dataStats}>
          <AdvancedNumberWidget
            icon={IoClipboardOutline}
            title="Kayıtlı Talep"
            value={dataStats?.data.data.demandTotalCount}
            valueType="Adet"
            color="teal"
            link="/demand"
            extraData={[
              {
                title: "Talep İşlemi",
                value: dataStats?.data.data.unDoneDocumentMaintenance,
              },
            ]}
          />
        </Skeleton>
        <Skeleton isLoaded={statStats === "fulfilled" && !!dataStats}>
          <AdvancedNumberWidget
            icon={IoConstructOutline}
            title="Kayıtlı DENETİM"
            title2="Kayıtlı GÖREV"
            value={dataStats?.data.data.controlTotalCount}
            value2={dataStats?.data.data.controlMaintenanceCount}
            valueType="Adet"
            valueType2="Adet"
            color="orange"
            link="/control"
            extraData={[
              {
                title: "Kayıtlı Görev",
                value: dataStats?.data.data.controlMaintenanceCount,
                not: true,
              },
              {
                title: "Denetim İşlemi",
                value: dataStats?.data.data.unDoneControlMaintenanceCount,
              },
            ]}
          />
        </Skeleton>
      </SimpleGrid>
      <SimpleGrid columns={isSm ? 1 : 2} gap="5" mt="5" mb="5">
        <Skeleton isLoaded={statStats === "fulfilled" && !!dataStats}>
          <MaintenanceNotes />
        </Skeleton>
        <Skeleton isLoaded={statStats === "fulfilled" && !!dataStats}>
          <NegativeQuestions />
        </Skeleton>
      </SimpleGrid>
    </Main>
  );
};

export default ProcessDetal;
