import React, { FC, useLayoutEffect, useRef, useState } from "react";
import { Box, Center, Text, Spinner, Flex, Button, FormControl, FormLabel } from "@chakra-ui/react";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import Main from "components/templates/Main";
import { Card, ModalProps } from "components/atoms";
import DataTable from "components/atoms/DataTable";
import { TimelineLegal } from "models";
import { TimelineStatus } from "components/organisms/TimelineLegal";
import Moment from "moment";
import ModalAtom from "components/atoms/Modal";
import RSelect from "react-select";

interface CompletedLegalProps {}

const CompletedDemand: FC<CompletedLegalProps> = (props) => {
  const { project } = useAuth();
  const [getData, statusData, dataData] = Hooks.DemandReport();
  const [getUT, statUT, dataUT] = Hooks.UserTypeList();
  const [filterData, setFilterData] = useState<any[]>([]);
  const filterModalRef = useRef<ModalProps>(null);

  useLayoutEffect(() => {
    getData(project.id);
    getUT();
  }, []);

  function onFilterData(item: any) {
    const isRequiredDoc = item.documents
      .map((_item: any) => _item.isCompleted)
      .includes(false);

    const _filters = [isRequiredDoc];
    return !_filters.includes(false);
  }

  function onFilter(item: any) {
    const filters: boolean[] = [];
    filterData.forEach((_filter) => {
      filters.push(item[_filter.name] === _filter.value);
    });

    return !filters.includes(false);
  }

  return (
    <Main headerProps={{ title: "Doküman Gereken Talepler" }}>
      <Card>
        {statusData !== "fulfilled" || !dataData ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <DataTable
            onClickFilter={() => {
              filterModalRef.current?.open();
            }}
            data={dataData.data.data.filter(onFilterData).filter(onFilter)}
            columns={[
              {
                name: "Dokümanlar Ve Yüklenme Tarihleri",
                cell: (row: any) => {
                  return (
                    <Flex pr="10">
                      {row.documents.map((document: any, index: number) => (
                        <Button
                          colorScheme={document.isCompleted ? "teal" : "red"}
                          size="xs"
                          py="10"
                          mr="1"
                          onClick={() => {
                            window.open(document.path, "_blank");
                          }}
                          disabled={!document.isCompleted}
                        >
                          {document.documentName}
                          <br />
                          {!document.isCompleted && "Son Yüklenme Tarihi: "}
                          {Moment(
                            document.isCompleted
                              ? document.completedDate
                              : document.lastDate
                          ).format(
                            document.isCompleted
                              ? "DD.MM.YYYY HH:mm"
                              : "DD.MM.YYYY"
                          )}
                          <br />
                          {document.completedUserName}
                        </Button>
                      ))}
                    </Flex>
                  );
                },
              },
              {
                name: "Kullanıcı Tipi",
                selector: "userTypeName",
                width: "200px",
              },
              { name: "Talep Adı", selector: "name", width: "200px" },

              { name: "Notlar", selector: "explain", width: "400px" },
              { name: "Tesis", selector: "campusName", width: "200px" },
              { name: "Bina", selector: "buildName", width: "200px" },
              { name: "Kat", selector: "floorName", width: "200px" },
              { name: "Oda", selector: "roomName", width: "200px" },
            ]}
          />
        )}
      </Card>
      <ModalAtom ref={filterModalRef} title="Filtre" noForm>
        <FormControl mt="5" mb="20">
          <FormLabel>Kullanıcı Tipi</FormLabel>
          <RSelect
            isClearable
            placeholder="Seçim Yap"
            onChange={(value) => {
              const keyName = "userTypeID";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                  },
                ]);
              }
            }}
            options={dataUT?.data.data.map((period: any) => ({
              value: period.id,
              label: period.name,
            }))}
          />
        </FormControl>
      </ModalAtom>
    </Main>
  );
};

CompletedDemand.defaultProps = {};

export default CompletedDemand;
