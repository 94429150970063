import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { useForm, Controller } from "react-hook-form";
import { Card } from "components/atoms";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { useToast } from "hooks/toast";
import { useHistory } from "react-router-dom";

type CounterCreatePageProps = {};

const CounterCreatePage: FC<CounterCreatePageProps> = (props) => {
  const history = useHistory();
  const { control, ...methods } = useForm();
  const { project } = useAuth();
  const [getPL, statPL, dataPL] = Hooks.CounterPeriodList();
  const [getTL, statTL, dataTL] = Hooks.CounterTypeList();
  const [postAdd, statAdd, dataAdd] = Hooks.CounterAdd();
  const toast = useToast();

  useLayoutEffect(() => {
    getPL();
    getTL();
  }, []);

  async function addCounter(values: any) {
    postAdd({
      ...values,
      barcode: values.barcode,
      counterTypeID: Number(values.mode),
      location: values.area,
      projectID: project.id,
      createDate: values.date,
    });
  }

  useEffect(() => {
    if (statAdd === "fulfilled") {
      if (dataAdd?.data.success) {
        toast({
          title: "Başarılı",
          description: "Sayaç Başarıyla Eklendi!",
          status: "success",
        });
        history.push("/counter");
      }
    }
  }, [statAdd]);

  return (
    <Main headerProps={{ title: "Sayaç Tanımla" }}>
      {statPL === "fulfilled" && statTL === "fulfilled" ? (
        <Card>
          <form onSubmit={methods.handleSubmit(addCounter)}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing="5">
              <Controller
                control={control}
                defaultValue="1"
                name="mode"
                render={({ field }) => (
                  <FormControl id="mode">
                    <FormLabel>
                      Sayaç Türü{" "}
                      <Text display="inline" color="red.500">
                        *
                      </Text>
                    </FormLabel>
                    <Select {...field}>
                      {dataTL?.data.data.map((opt: any, index: number) => (
                        <option key={index} value={opt.id}>
                          {opt.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                defaultValue=""
                name="barcode"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl id="barcode">
                    <FormLabel>
                      Kod
                      <Text display="inline" color="red.500">
                        *
                      </Text>
                    </FormLabel>
                    <Input {...field} placeholder="Kod" />
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                defaultValue=""
                name="area"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl id="mode">
                    <FormLabel>
                      Tüketim Bölgesi
                      <Text display="inline" color="red.500">
                        *
                      </Text>
                    </FormLabel>
                    <Input {...field} placeholder="Tüketim Bölgesi" />
                  </FormControl>
                )}
              />
            </SimpleGrid>
            <Flex justifyContent="flex-end" my="5">
              <Button type="submit" colorScheme="green">
                Oluştur
              </Button>
            </Flex>
          </form>
        </Card>
      ) : (
        <Center minH="500px">
          <Spinner />
        </Center>
      )}
    </Main>
  );
};

export default CounterCreatePage;
