import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label: string;
  containerProps?: FormControlProps;
};

export const InputField = ({
  label,
  containerProps,
  required,
  ...inputProps
}: InputFieldProps) => {
  const [field, { error }] = useField(inputProps);
  return (
    <FormControl {...containerProps} isInvalid={!!error}>
      <FormLabel htmlFor={field.name}>
        {label}{" "}
        {required && (
          <Text color="red.400" d="inline">
            *
          </Text>
        )}
      </FormLabel>
      <Input
        {...field}
        id={field.name}
        placeholder={inputProps.placeholder}
        type={inputProps.type}
        _placeholder={{ color: "gray.500" }}
        borderColor="gray.300"
        borderRadius="sm"
        required={required}
        isRequired={required}
      />
      {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

type SelectFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label: string;
  containerProps?: FormControlProps;
  data: Array<{ value: string; label: string }>;
};

export const SelectField = ({
  label,
  disabled,
  containerProps,
  data,
  ...inputProps
}: SelectFieldProps) => {
  const [field, { error }] = useField(inputProps);
  return (
    <FormControl {...containerProps} isInvalid={!!error}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Select
        {...field}
        id={field.name}
        borderColor="gray.300"
        borderRadius="sm"
        disabled={disabled}
      >
        {!!data &&
          Array.isArray(data) &&
          data.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
      </Select>
      {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

type TextareaFieldProps = InputHTMLAttributes<HTMLTextAreaElement> & {
  name: string;
  label: string;
  containerProps?: FormControlProps;
};

export const TextareaField = ({
  label,
  containerProps,
  ...inputProps
}: TextareaFieldProps) => {
  const [field, { error }] = useField(inputProps);
  return (
    <FormControl {...containerProps} isInvalid={!!error}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Textarea
        {...field}
        id={field.name}
        placeholder={inputProps.placeholder}
        type={inputProps.type}
        _placeholder={{ color: "gray.500" }}
        borderColor="gray.300"
        borderRadius="sm"
      />
      {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};
