import React, { FC } from "react";
import { Box, Text, SimpleGrid, Button, Center } from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

interface ControlTaskReportProps {}

const ControlTaskReport: FC<ControlTaskReportProps> = (props) => {
  return (
    <Main headerProps={{ title: "Denetim Raporları" }}>
      <Card>
        <SimpleGrid columns={[1, 2]} gap="5">
          <Link to="/report-control-task-completed">
            <Center
              bgColor="gray.50"
              h="250px"
              rounded="md"
              _hover={{ bgColor: "blue.50" }}
            >
              <Box textAlign="center">
                <Text>Tamamlanan Denetim İşlemleri</Text>
              </Box>
            </Center>
          </Link>
          <Link to="/report-control-task-incompleted">
            <Center
              bgColor="gray.50"
              h="250px"
              rounded="md"
              _hover={{ bgColor: "blue.50" }}
            >
              <Box textAlign="center">
                <Text>Planlanan Zamanda Gerçekleşmeyen<br />Denetim İşlemleri</Text>
              </Box>
            </Center>
          </Link>
        </SimpleGrid>
      </Card>
    </Main>
  );
};

ControlTaskReport.defaultProps = {};

export default ControlTaskReport;
