import React, { useLayoutEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  SimpleGrid,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { useForm } from "react-hook-form";
import {
  CheckBoxElement,
  InputElement,
  SelectElement,
} from "components/atoms/FormElements";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { RouteComponentProps } from "react-router";
import ReactSelect from "react-select";
import { UserRoles } from "models/user";
import { useToast } from "hooks/toast";

interface UserCreateProps {}

function UserCreate(props: RouteComponentProps) {
  const { project, user, role } = useAuth();
  const { control, handleSubmit } = useForm();
  const [getRoles, statRoles, dataRoles] = Hooks.UserRoleList();
  const [getTypes, statTypes, dataTypes] = Hooks.UserTypeList();
  const [addUser, statAddUser, dataAddUser] = Hooks.AddUser();
  const [userTypes, setUserTypes] = useState<any[]>([]);
  const toast = useToast();

  useLayoutEffect(() => {
    getRoles();
    getTypes();
  }, []);

  async function onValid(values: any) {
    const _data = (await addUser({
      ...values,
      companyID: user.companyID,
      projectID: project.id,
      userRoleID: parseInt(values.userRoleID),
      userTypes: userTypes.map((_item) => parseInt(_item)),
      isNotificationEnabled: !!values.isNotificationEnabled,
      isEmailEnabled: !!values.isEmailEnabled,
    })) as unknown as any;
    if (_data.data.success) {
      toast({ status: "success", title: "Kullanıcı Düzenlendi!" });
      props.history.push("/user");
    } else {
      toast({
        status: "error",
        title: "Hata Oluştu!",
        description: _data.data.message,
      });
    }
  }

  return (
    <Main headerProps={{ title: "Kullanıcı Tanımla" }}>
      <Card>
        <form onSubmit={handleSubmit(onValid)}>
          <SimpleGrid columns={2} gap="5">
            <InputElement name="name" label="Ad" control={control} isRequired />
            <InputElement
              name="surname"
              label="Soyad"
              control={control}
              isRequired
            />
            <InputElement
              name="username"
              label="Kullanıcı Adı"
              control={control}
              isRequired
            />
            <InputElement name="email" label="E Posta" control={control} />
            <InputElement name="phone" label="Telefon" control={control} />
            <InputElement
              name="passwordHash"
              label="Parola"
              control={control}
              isRequired
            />
            {/* <SelectElement
              name="userTypeID"
              label="Kullanıcı Tipi"
              control={control}
              data={dataTypes?.data.data.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              isRequired
            /> */}
            <FormControl isRequired>
              <HStack alignItems="flex-start">
                <FormLabel>Kullanıcı Tipi</FormLabel>
                <Button
                  size="xs"
                  onClick={() => {
                    setUserTypes(
                      dataTypes!.data.data
                        .filter((item) => !!item.isActive)
                        .filter((item) =>
                          role === UserRoles.admin
                            ? true
                            : user.userTypes
                                .map((_type: any) => _type.id)
                                .includes(item.id)
                        )
                        .map((x) => x.id)
                    );
                  }}
                >
                  Hepsini seç
                </Button>
              </HStack>
              <ReactSelect
                placeholder="Kullanıcı Tipi seçiniz"
                isMulti
                value={userTypes.map((userType) => {
                  const item = dataTypes?.data!.data.find(
                    (x) => x.id === userType
                  );
                  if (!item) {
                    return userType;
                  }
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
                onChange={(values) => {
                  setUserTypes(values.map((item) => item.value));
                }}
                options={dataTypes?.data.data
                  .filter((item) => !!item.isActive)
                  .filter((item) =>
                    role === UserRoles.admin
                      ? true
                      : user.userTypes
                          .map((_type: any) => _type.id)
                          .includes(item.id)
                  )
                  .map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
              />
              <FormHelperText>Birden fazla seçim yapılabilir</FormHelperText>
            </FormControl>
            <SelectElement
              name="userRoleID"
              label="Kullanıcı Rolü"
              control={control}
              data={dataRoles?.data.data
                .filter((item) => (role === 1 ? true : item.id > role))
                .map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              isRequired
            />
            <CheckBoxElement
              name="isNotificationEnabled"
              label="Bildirimlere İzin Ver"
              control={control}
            />
            <CheckBoxElement
              name="isEmailEnabled"
              label="E-Posta Almaya İzin Ver"
              control={control}
            />
          </SimpleGrid>
          <Flex justifyContent="flex-end" mt="5">
            <Button type="submit" colorScheme="teal">
              Oluştur
            </Button>
          </Flex>
        </form>
      </Card>
    </Main>
  );
}

export default UserCreate;
