import React, { FC, useLayoutEffect } from "react";
import { Button, Center, Flex, Spinner } from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card, DataTable } from "components/atoms";
import { Link, useHistory } from "react-router-dom";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import Moment from "moment";
import Badge from "components/atoms/Badge";

type CounterPageProps = {};

const CounterPage: FC<CounterPageProps> = (props) => {
  const { project } = useAuth();
  const [getList, statList, dataList] = Hooks.CounterListByProject();
  const history = useHistory();

  useLayoutEffect(() => {
    getList(project.id);
  }, []);

  return (
    <Main headerProps={{ title: "Sayaç Listesi" }}>
      <Card>
        <Flex justifyContent="flex-end">
          <Link to="/counter/create">
            <Button colorScheme="blue" size="sm">
              Sayaç Tanımla
            </Button>
          </Link>
        </Flex>
      </Card>

      <Card mt="5">
        {statList === "fulfilled" ? (
          <DataTable
            columns={[
              // {
              //   name: "ID",
              //   selector: "id",
              // },
              { name: "Barkod", selector: "barcode" },
              {
                name: "Sayaç Türü",
                selector: "counterTypeName",
              },
              { name: "Tüketim Bölgesi", selector: "location" },
              {
                name: "Oluşturulma Tarihi",
                cell: (row: any) => Moment(row.createDate).format("DD/MM/YYYY"),
              },
              {
                name: "Durum",
                cell: (row: any) => <Badge isActive={row.isActive} />,
              },
              {
                name: "Kontroller",
                cell: (row: any) => (
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() => {
                      history.push(`/counter/${row.id}/maintenance`);
                    }}
                  >
                    Kontroller
                  </Button>
                ),
              },
            ]}
            data={dataList?.data.data.map((item: any) => ({
              ...item,
              highlight: item.hasMaintenance,
            }))}
          />
        ) : (
          <Center minHeight="500px">
            <Spinner />
          </Center>
        )}
      </Card>
    </Main>
  );
};

export default CounterPage;
