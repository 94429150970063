import React, { FC, useLayoutEffect, useRef, useState } from "react";
import {
  Box,
  Center,
  Text,
  Spinner,
  Flex,
  Button,
  FormControl,
  FormLabel,
  useDisclosure,
} from "@chakra-ui/react";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import Main from "components/templates/Main";
import { Card, ModalProps } from "components/atoms";
import DataTable from "components/atoms/DataTable";
import { TimelineLegal } from "models";
import { TimelineStatus } from "components/organisms/TimelineLegal";
import Moment from "moment";
import RSelect from "react-select";
import ModalAtom from "components/atoms/Modal";
import { IoFolder } from "react-icons/io5";
import DemandFilter from "components/organisms/DemandFilter";

interface CompletedLegalProps {}

const CompletedDemand: FC<CompletedLegalProps> = (props) => {
  const { project } = useAuth();
  const [getData, statusData, dataData] = Hooks.DemandReport();
  const [getPeriods, statPeriods, dataPeriods] = Hooks.ListPeriod();
  const [getUT, statUT, dataUT] = Hooks.UserTypeList();
  const [filterData, setFilterData] = useState<any[]>([]);
  const [filterData2, setFilterData2] = useState<any>(null);
  const filterDisc = useDisclosure();
  const [statusFilter, setStatusFilter] = useState([1, 2, 3]);

  const filterModalRef = useRef<ModalProps>(null);

  useLayoutEffect(() => {
    getData(project.id);
  }, []);

  function _onFilterData(row: any) {
    function _condition(a: any, b: any) {
      return !a || a === b;
    }

    const _data = [
      _condition(filterData2?.campus?.value, row.campusID),
      _condition(filterData2?.build?.value, row.buildID),
      _condition(filterData2?.floor?.value, row.floorID),
      _condition(filterData2?.room?.value, row.roomID),
      _condition(filterData2?.userType?.value, row.userTypeID),
      _condition(filterData2?.group?.value, row.demandGroupID),
    ];

    return !_data.includes(false);
  }

  function onFilterData(item: any) {
    const isRequiredDoc = item.documents
      .map((_item: any) => _item.isCompleted)
      .includes(false);

    const _filters: any[] = [];
    if(statusFilter.includes(1)) {
      _filters.push(!isRequiredDoc)
    }
    if(statusFilter.includes(2)) {
      _filters.push(isRequiredDoc);
    }
    return _filters.includes(true);
  }

  function onFilter(item: any) {
    const filters: boolean[] = [];
    filterData.forEach((_filter) => {
      filters.push(item[_filter.name] === _filter.value);
    });

    return !filters.includes(false);
  }

  return (
    <Main headerProps={{ title: "Tamamlanan Talepler" }}>
      <Card mb="3">
        <Flex justifyContent="space-between">
        <Flex>
            <Button
              size="sm"
              mr="1"
              onClick={() =>
                setStatusFilter((prev) =>
                  prev.includes(1) ? prev.filter((i) => i !== 1) : [...prev, 1]
                )
              }
              variant={statusFilter.includes(1) ? "solid" : "outline"}
              colorScheme="purple"
            >
              Tamamlanan
            </Button>
            <Button
              size="sm"
              mr="1"
              onClick={() =>
                setStatusFilter((prev) =>
                  prev.includes(2) ? prev.filter((i) => i !== 2) : [...prev, 2]
                )
              }
              variant={statusFilter.includes(2) ? "solid" : "outline"}
              colorScheme="purple"
            >
              Form Beklenen
            </Button>
          </Flex>
          <Button
            size="sm"
            colorScheme="orange"
            onClick={() => filterDisc.onToggle()}
          >
            {filterDisc.isOpen ? "Filtre Kapat" : "Filtre"}
          </Button>
        </Flex>
        {filterDisc.isOpen && (
          <DemandFilter
            onFilter={(data: any) => {
              setFilterData2(data);
            }}
          />
        )}
      </Card>
      <Card>
        {statusData !== "fulfilled" || !dataData ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <DataTable
            onClickFilter={() => {
              filterModalRef.current?.open();
            }}
            data={dataData.data.data
              .filter(onFilterData)
              .filter(onFilter)
              .filter(_onFilterData)}
            columns={[
              { name: "Talep Adı", selector: "name", width: "200px" },
              { name: "Grup Adı", selector: "demandGroupName", width: "200px" },
              {
                name: "Dokümanlar Ve Yüklenme Tarihleri",
                cell: (row: any) => {
                  return (
                    <Flex pr="10">
                      {row.documents.map((document: any, index: number) => (
                        <Button
                          colorScheme="teal"
                          size="xs"
                          py="10"
                          mr="1"
                          leftIcon={<IoFolder />}
                          onClick={() => {
                            window.open(document.path, "_blank");
                          }}
                        >
                          {document.documentName}
                          <br />
                          {Moment(document.completedDate).format(
                            "DD.MM.YYYY HH:mm"
                          )}
                          <br />
                          {document.completedUserName}
                        </Button>
                      ))}
                    </Flex>
                  );
                },
              },
              {
                name: "Kullanıcı Tipi",
                selector: "userTypeName",
                width: "200px",
              },
              { name: "Notlar", selector: "explain", width: "400px" },
              { name: "Tesis", selector: "campusName", width: "200px" },
              { name: "Bina", selector: "buildName", width: "200px" },
              { name: "Kat", selector: "floorName", width: "200px" },
              { name: "Oda", selector: "roomName", width: "200px" },
            ]}
          />
        )}
      </Card>
      <ModalAtom ref={filterModalRef} title="Filtre" noForm>
        <FormControl>
          <FormLabel>Periyot</FormLabel>
          <RSelect
            placeholder="Seçim Yap"
            isClearable
            onChange={(value) => {
              const keyName = "maintenancePeriodID";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                  },
                ]);
              }
            }}
            options={dataPeriods?.data.data.map((period: any) => ({
              value: period.id,
              label: period.name,
            }))}
          />
        </FormControl>
        <FormControl mt="5">
          <FormLabel>Bakım Tipi</FormLabel>
          <RSelect
            placeholder="Seçim Yap"
            isClearable
            onChange={(value) => {
              const keyName = "maintenanceTypeID";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                  },
                ]);
              }
            }}
            options={[
              {
                label: "İç Bakım / Konrol",
                value: 1,
              },
              {
                label: "Dış Bakım",
                value: 2,
              },
            ]}
          />
        </FormControl>
        <FormControl mt="5" mb="20">
          <FormLabel>Kullanıcı Tipi</FormLabel>
          <RSelect
            isClearable
            placeholder="Seçim Yap"
            onChange={(value) => {
              const keyName = "userTypeID";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                  },
                ]);
              }
            }}
            options={dataUT?.data.data.map((period: any) => ({
              value: period.id,
              label: period.name,
            }))}
          />
        </FormControl>
      </ModalAtom>
    </Main>
  );
};

CompletedDemand.defaultProps = {};

export default CompletedDemand;
