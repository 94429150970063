import React, { FC, useLayoutEffect } from "react";
import { Box, Text, Center, Spinner } from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import DataTable from "components/atoms/DataTable";
import { Card } from "components/atoms";
import Moment from 'moment';
import { isSieOrNo } from "helper/project-mode";

interface InCompletedMaintenanceProps {}

const InCompletedMaintenance: FC<InCompletedMaintenanceProps> = (props) => {
  const { project } = useAuth();
  const [getReports, statReports, dataReports] = Hooks.WaitingFormReport();

  useLayoutEffect(() => {
    getReports(project.id);
  }, []);

  return (
    <Main headerProps={{ title: `Form Beklenen Periyodik ${isSieOrNo("Kontroller", "Muayeneler")}` }}>
      {statReports !== "fulfilled" || !dataReports ? (
        <Center h="300px">
          <Spinner />
        </Center>
      ) : (
        <Card>
          <DataTable
            columns={[
              { name: "Barkod", selector: "barcode" },
              { name: "Ekipman Adı", selector: "name", width: "150px" },
              { name: "Bakım Tipi", selector: "maintenanceTypeName", width: "150px" },
              { name: "Periyot", selector: "maintenancePeriodName", width: "150px" },
              { name: "Başlangıç Tarihi", cell: (row) => <Text>{Moment(row.startDate).format("DD.MM.YYYY")}</Text>, width: "150px" },
              { name: "Planli Bakım Tarihi", cell: (row) => <Text>{Moment(row.endDate).format("DD.MM.YYYY")}</Text>, width: "150px" },
              { name: "Tesis", selector: "campusName", width: "150px"  },
              { name: "Bina", selector: "buildName", width: "150px"  },
              { name: "Kat", selector: "floorName", width: "150px"  },
              { name: "Oda", selector: "roomName", width: "150px"  },
              { name: "Grup", selector: "groupName", width: "150px"  },
              { name: "Marka", selector: "brandName", width: "150px"  },
              { name: "Model", selector: "modelName", width: "150px"  },
              { name: "Kapasite Tipi", selector: "inventoryCapacityName", width: "150px"  },
              { name: "Adet", selector: "unit", width: "50px"  },
              { name: "Üretim Yılı", selector: "productionYear", width: "150px"  },
            ]}
            data={dataReports.data.data}
          />
        </Card>
      )}
    </Main>
  );
};

InCompletedMaintenance.defaultProps = {};

export default InCompletedMaintenance;
