import React, { FC } from "react";
import { Box, Text, SimpleGrid, Button, Center } from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

interface ReportsPageProps {}

const ReportsPage: FC<ReportsPageProps> = (props) => {
  return (
    <Main headerProps={{ title: "Talep Raporları" }}>
      <Card>
        <SimpleGrid columns={[1, 2]} gap="5">
          <Link to="/report/completed-demand">
            <Center
              bgColor="gray.50"
              h="250px"
              rounded="md"
              _hover={{ bgColor: "blue.50" }}
            >
              <Box textAlign="center">
                <Text>Tamamlanan Talep İşlemleri</Text>
              </Box>
            </Center>
          </Link>
          <Link to="/report/incompleted-demand">
            <Center
              bgColor="gray.50"
              h="250px"
              rounded="md"
              _hover={{ bgColor: "blue.50" }}
            >
              <Box textAlign="center">
                <Text>Doküman Gerekli Talep İşlemleri</Text>
              </Box>
            </Center>
          </Link>
        </SimpleGrid>
      </Card>
    </Main>
  );
};

ReportsPage.defaultProps = {};

export default ReportsPage;
