import {
  Avatar,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { FC, useMemo } from "react";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { useHistory } from "react-router-dom";
enum SearchDataType {
  Inventory = 1,
  Document = 2,
  Demand = 3,
  Control = 4,
  Counter = 5,
}

type ResultProps = {
  title: string;
  itemType: SearchDataType;
  id: number;
};

const Result: FC<ResultProps> = (props) => {
  const history = useHistory();
  const typeName = useMemo(() => {
    switch (props.itemType) {
      case SearchDataType.Inventory:
        return "Ekipman";
      case SearchDataType.Demand:
        return "Talep";
      case SearchDataType.Counter:
        return "Sayaç";
      case SearchDataType.Document:
        return "Belge Takip";
      case SearchDataType.Control:
        return "Denetim";
      default:
        return "";
    }
  }, []);

  const actions = useMemo(() => {
    switch (props.itemType) {
      case SearchDataType.Inventory:
        return [
          <MenuItem
            onClick={() =>
              history.push("/maintenance-list/" + props.id, {
                inventory: { name: props.title },
              })
            }
          >
            Bakımlar
          </MenuItem>,
          <MenuItem
            onClick={() =>
              history.push("/periodic-controls/" + props.id, {
                inventory: { name: props.title },
              })
            }
          >
            Periyodik Kontroller
          </MenuItem>,
        ];
      case SearchDataType.Demand:
        return null;
      case SearchDataType.Counter:
        return [
          <MenuItem
            onClick={() =>
              history.push("/counter/" + props.id + "/maintenance")
            }
          >
            Kontroller
          </MenuItem>,
        ];
      case SearchDataType.Document:
        return [
          <MenuItem
            onClick={() => history.push("/document/" + props.id + "/control")}
          >
            Kontroller
          </MenuItem>,
        ];
      case SearchDataType.Control:
        return null;
      default:
        return "";
    }
  }, []);

  return (
    <Flex
      borderBottomWidth="1px"
      py="2"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex alignItems="center">
        <Tooltip label={typeName}>
          <Avatar name={typeName} size="sm" />
        </Tooltip>
        <Text ml="2">{props.title}</Text>
      </Flex>
      <Flex>
        <Menu placement="bottom-end">
          <MenuButton
            size="sm"
            as={IconButton}
            icon={<IoEllipsisHorizontal />}
          />
          <MenuList>{actions}</MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default Result;
