import {
  Button,
  Center,
  Flex,
  HStack,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import DTable from "components/atoms/DataTable";
import Drawer, { DrawerProps } from "components/atoms/Drawer";
import Modal, { ModalProps } from "components/atoms/Modal";
import InventoryFilter from "components/organisms/InventoryFilter";
import Main from "components/templates/Main";
import { useAuth } from "hooks/redux-hooks";
import { Inventory } from "models";
import moment from "moment";
import Moment from "moment";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useHistory } from "react-router";
import { Hooks } from "services";
import { getApiUrl } from "services/urls";
import FileUploaderForm, {
  FileUploadType,
} from "./components/FileUploaderForm";

function InventoryPage() {
  const { project } = useAuth();
  const history = useHistory();
  const [selectedInventory, setSelectedInventory] = useState<Inventory>();
  const drawerRef = useRef<DrawerProps>(null);
  const [lightBoxImage, setLightBoxImage] = useState("");
  const toast = useToast();
  const filterModal = useRef<ModalProps>(null);
  const filterDisc = useDisclosure();
  const [filterData, setFilterData] = useState<any>(null);
  const [status, setStatus] = useState<any[]>([1, 2, 3, 4, 5]);

  const [getMC, statMC, dataMC] = Hooks.NonMaintenanceContract();
  const [getRA, statRA, dataRA] = Hooks.NonRiskAnalysisInventory();
  const [getUG, statUG, dataUG] = Hooks.NonUserGuide();
  const [getUI, statUI, dataUI] = Hooks.NonUserInstructions();
  const [getER, statER, dataER] = Hooks.ExpireRiskAnalysisInventory();

  const [getIntentoryList, statusList, inventoryData] = Hooks.InventoryList();

  const [_filterData, _setFilterData] = useState<any>({
    mc: null,
    ra: null,
    ug: null,
    ui: null,
    er: null,
  });

  function getList() {
    getMC(project.id);
    getRA(project.id);
    getUG(project.id);
    getUI(project.id);
    getER(project.id);
    getIntentoryList(project.id);
  }

  useLayoutEffect(() => {
    getList();
  }, []);

  const apiUrl = getApiUrl();

  function _onFilterData(row: Inventory) {
    function _condition(a: any, b: any) {
      return !a || a === b;
    }

    const _data = [
      _condition(filterData?.campus?.value, row.campusID),
      _condition(filterData?.build?.value, row.buildID),
      _condition(filterData?.floor?.value, row.floorID),
      _condition(filterData?.room?.value, row.roomID),
      _condition(filterData?.userType?.value, row.userTypeID),
      _condition(filterData?.group?.value, row.groupID),
      _condition(filterData?.brand?.value, row.brandID),
      _condition(filterData?.model?.value, row.modelID),
    ];

    return !_data.includes(false);
  }

  useEffect(() => {
    if (!filterDisc.isOpen) {
      setFilterData({});
    }
  }, [filterDisc.isOpen]);

  let data: any[] = [];

  if (
    !!dataMC?.data.data &&
    !!dataRA?.data.data &&
    !!dataUG?.data.data &&
    !!dataUI?.data.data &&
    !!dataER?.data.data
  ) {
    data = [
      ...(status.includes(1) ? dataMC?.data.data : []),
      ...(status.includes(2) ? dataRA?.data.data : []),
      ...(status.includes(3) ? dataUG?.data.data : []),
      ...(status.includes(4) ? dataUI?.data.data : []),
      ...(status.includes(5) ? dataER?.data.data : []),
    ];
    let ids: any[] = [];
    data = data.filter((item) => {
      if (ids.includes(item.id)) {
        return false;
      } else {
        ids.push(item.id);
        return true;
      }
    });
  }

  return (
    <Main headerProps={{ title: "Eksik Ekipman Dosyaları" }}>
      {filterDisc.isOpen && (
        <InventoryFilter
          onFilter={(data: any) => {
            setFilterData(data);
          }}
        />
      )}

      <Card mb="3">
        <Flex justifyContent="flex-end">
          <Button
            colorScheme="orange"
            size="sm"
            onClick={filterModal.current?.open}
          >
            Filtre
          </Button>
        </Flex>
      </Card>

      {data ? (
        <Card>
          <DTable
            columns={[
              {
                name: "ID",
                selector: "id",
                sortable: true,
              },
              {
                name: "Barkod",
                selector: "barcode",
                sortable: true,
              },
              {
                name: "Tesis",
                selector: "campusName",
                sortable: true,
              },
              {
                name: "Ekipman Adı",
                selector: "name",
                sortable: true,
              },
              {
                name: "Grup",
                selector: "groupName",
                sortable: true,
              },
              {
                name: "Marka",
                selector: "brandName",
                sortable: true,
              },
              {
                name: "Model",
                selector: "modelName",
                sortable: true,
              },
              {
                name: "Dosyaları Düzenle",
                cell: (row: any) => {
                  return (
                    <Button
                      colorScheme="blue"
                      size="sm"
                      onClick={() => {
                        setSelectedInventory(row);
                        drawerRef.current?.open();
                      }}
                    >
                      Dosyalar
                    </Button>
                  );
                },
              },
            ]}
            data={
              inventoryData?.data
                ? inventoryData?.data.data.filter((item: any) => {
                    console.log({ item });
                    const filterArr = [];
                    console.log({ _filterData });
                    if (_filterData.ug != null) {
                      if (_filterData.ug) {
                        if (!item.userGudiePath?.includes("Content")) {
                          filterArr.push(false);
                        }
                      } else {
                        if (!!item.userGuidePath?.includes("Content")) {
                          filterArr.push(false);
                        }
                      }
                    }

                    if (_filterData.ui != null) {
                      if (_filterData.ui) {
                        if (!item.userInstructions?.includes("Content")) {
                          filterArr.push(false);
                        }
                      } else {
                        if (!!item.userInstructions?.includes("Content")) {
                          filterArr.push(false);
                        }
                      }
                    }
                    if (_filterData.ra != null) {
                      if (_filterData.ra) {
                        if (!item.riskAnalysisPath?.includes("Content")) {
                          filterArr.push(false);
                        }
                      } else {
                        if (item.riskAnalysisPath?.includes("Content")) {
                          filterArr.push(false);
                        }
                      }
                    }

                    if (_filterData.er !== null) {
                      let isExpired = true;

                      if (item.riskAnalysisExpriDate !== null) {
                        if (
                          moment().diff(
                            moment(item.riskAnalysisExpriDate),
                            "seconds"
                          ) < 0
                        ) {
                          isExpired = false;
                        }
                      }

                      if (!_filterData.er) {
                        if (!isExpired) {
                          filterArr.push(false);
                        }
                      } else {
                        if (isExpired) {
                          filterArr.push(false);
                        }
                      }
                    }

                    if (_filterData.mc != null) {
                      if (_filterData.mc) {
                        if (!item.maintenanceContract?.includes("Content")) {
                          filterArr.push(false);
                        }
                      } else {
                        if (!!item.maintenanceContract?.includes("Content")) {
                          filterArr.push(false);
                        }
                      }
                    }

                    return !filterArr.includes(false);
                  })
                : []
            }
          />
        </Card>
      ) : (
        <Card>
          <Center>
            <Spinner />
          </Center>
        </Card>
      )}

      <Modal title="Filtre" ref={filterModal} noForm>
        <Flex
          justifyContent="space-between"
          py="3"
          borderBottom="1px"
          borderColor="gray.300"
          alignItems="center"
        >
          <Text>Kullanım Klavuzu</Text>
          <Flex>
            <Button
              colorScheme="teal"
              size="sm"
              borderWidth="1px"
              borderRadius="none"
              borderColor="teal.500"
              variant={_filterData.ug ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, ug: true }));
              }}
            >
              Var
            </Button>
            <Button
              colorScheme="red"
              size="sm"
              borderRadius="none"
              borderWidth="1px"
              borderColor="red.500"
              variant={_filterData.ug == false ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, ug: false }));
              }}
            >
              Yok
            </Button>
            <Button
              colorScheme="orange"
              size="sm"
              borderRadius="none"
              borderWidth="1px"
              borderColor="orange.500"
              variant={_filterData.ug == null ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, ug: null }));
              }}
            >
              Filtre Yok
            </Button>
          </Flex>
        </Flex>
        <Flex
          justifyContent="space-between"
          py="3"
          borderBottom="1px"
          borderColor="gray.300"
          alignItems="center"
        >
          <Text>Kullanım Talimatları</Text>
          <Flex>
            <Button
              colorScheme="teal"
              size="sm"
              borderWidth="1px"
              borderRadius="none"
              borderColor="teal.500"
              variant={_filterData.ui ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, ui: true }));
              }}
            >
              Var
            </Button>
            <Button
              colorScheme="red"
              size="sm"
              borderRadius="none"
              borderWidth="1px"
              borderColor="red.500"
              variant={_filterData.ui == false ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, ui: false }));
              }}
            >
              Yok
            </Button>
            <Button
              colorScheme="orange"
              size="sm"
              borderRadius="none"
              borderWidth="1px"
              borderColor="orange.500"
              variant={_filterData.ui == null ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, ui: null }));
              }}
            >
              Filtre Yok
            </Button>
          </Flex>
        </Flex>
        <Flex
          justifyContent="space-between"
          py="3"
          borderBottom="1px"
          borderColor="gray.300"
          alignItems="center"
        >
          <Text>ISG Risk Analizi</Text>
          <Flex>
            <Button
              colorScheme="teal"
              size="sm"
              borderWidth="1px"
              borderRadius="none"
              borderColor="teal.500"
              variant={_filterData.ra ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, ra: true }));
              }}
            >
              Var
            </Button>
            <Button
              colorScheme="red"
              size="sm"
              borderRadius="none"
              borderWidth="1px"
              borderColor="red.500"
              variant={_filterData.ra == false ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, ra: false }));
              }}
            >
              Yok
            </Button>
            <Button
              colorScheme="orange"
              size="sm"
              borderRadius="none"
              borderWidth="1px"
              borderColor="orange.500"
              variant={_filterData.ra == null ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, ra: null }));
              }}
            >
              Filtre Yok
            </Button>
          </Flex>
        </Flex>
        <Flex
          justifyContent="space-between"
          py="3"
          borderBottom="1px"
          borderColor="gray.300"
          alignItems="center"
        >
          <Text>Süresi Dolmuş ISG Risk Analizi</Text>
          <Flex>
            <Button
              colorScheme="teal"
              size="sm"
              borderWidth="1px"
              borderRadius="none"
              borderColor="teal.500"
              variant={_filterData.er ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, er: true }));
              }}
            >
              Var
            </Button>
            <Button
              colorScheme="red"
              size="sm"
              borderRadius="none"
              borderWidth="1px"
              borderColor="red.500"
              variant={_filterData.er == false ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, er: false }));
              }}
            >
              Yok
            </Button>
            <Button
              colorScheme="orange"
              size="sm"
              borderRadius="none"
              borderWidth="1px"
              borderColor="orange.500"
              variant={_filterData.er == null ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, er: null }));
              }}
            >
              Filtre Yok
            </Button>
          </Flex>
        </Flex>
        <Flex
          justifyContent="space-between"
          py="3"
          borderBottom="1px"
          borderColor="gray.300"
          alignItems="center"
        >
          <Text>Bakım Sözleşmesi</Text>
          <Flex>
            <Button
              colorScheme="teal"
              size="sm"
              borderWidth="1px"
              borderRadius="none"
              borderColor="teal.500"
              variant={_filterData.mc ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, mc: true }));
              }}
            >
              Var
            </Button>
            <Button
              colorScheme="red"
              size="sm"
              borderRadius="none"
              borderWidth="1px"
              borderColor="red.500"
              variant={_filterData.mc == false ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, mc: false }));
              }}
            >
              Yok
            </Button>
            <Button
              colorScheme="orange"
              size="sm"
              borderRadius="none"
              borderWidth="1px"
              borderColor="orange.500"
              variant={_filterData.mc == null ? "solid" : "outline"}
              onClick={() => {
                _setFilterData((state: any) => ({ ...state, mc: null }));
              }}
            >
              Filtre Yok
            </Button>
          </Flex>
        </Flex>

        <HStack justifyContent="flex-end" pt="10" pb="2">
          <Button onClick={() => filterModal.current?.close()}>Vazgeç</Button>
          <Button
            colorScheme="blue"
            onClick={() => filterModal.current?.close()}
          >
            Uygula
          </Button>
        </HStack>
      </Modal>

      <Drawer
        onConfirmText="Tamam"
        onConfirm={() => {
          drawerRef.current?.close();
          getList();
        }}
        ref={drawerRef}
        onClose={() => {
          getList();
        }}
        title={`${selectedInventory?.name} Dosyaları`}
      >
        <FileUploaderForm
          uploadType={FileUploadType.UserGuidePath}
          inventoryId={selectedInventory?.id as unknown as number}
          defaultValue={selectedInventory?.userGudiePath as unknown as string}
          title={"Kullanım Klavuzu"}
        />
        <FileUploaderForm
          uploadType={FileUploadType.UserInstructions}
          inventoryId={selectedInventory?.id as unknown as number}
          defaultValue={
            selectedInventory?.userInstructions as unknown as string
          }
          title={"Kullanım Talimatı"}
        />
        <FileUploaderForm
          uploadType={FileUploadType.RiskAnalysisPath}
          inventoryId={selectedInventory?.id as unknown as number}
          defaultValue={
            selectedInventory?.riskAnalysisPath as unknown as string
          }
          title={"ISG Risk Analizi"}
          date={Moment(selectedInventory?.riskAnalysisExpriDate).format(
            "YYYY-MM-DD"
          )}
        />
        <FileUploaderForm
          uploadType={FileUploadType.MaintenanceContract}
          inventoryId={selectedInventory?.id as unknown as number}
          defaultValue={
            selectedInventory?.maintenanceContract as unknown as string
          }
          title={"Bakım Sözleşmesi"}
        />
      </Drawer>
      {!!lightBoxImage && (
        <Lightbox
          mainSrc={lightBoxImage}
          onCloseRequest={() => setLightBoxImage("")}
        />
      )}
    </Main>
  );
}

export default InventoryPage;
