import React, { FC, useLayoutEffect } from "react";
import { Box, Center, Text, Spinner, Button, Flex } from "@chakra-ui/react";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import DataTable from "components/atoms/DataTable";
import { TimelineLegal } from "models";
import { TimelineStatus } from "components/organisms/TimelineLegal";
import Moment from "moment";
import { FaFile } from "react-icons/fa";

interface CompletedLegalProps {}

const CompletedLegal: FC<CompletedLegalProps> = (props) => {
  const { project } = useAuth();
  const [getData, statusData, dataData] = Hooks.DocumentReports();
  const [getTime, statTime, dataTime] = Hooks.TimelineDocument()


  useLayoutEffect(() => {
    getData(project.id);
    getTime(project.id);
  }, []);

  return (
    <Main headerProps={{ title: "Form Beklenen Dokümanlar" }}>
      <Card>
        {statusData !== "fulfilled" || !dataData ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <DataTable
            data={dataData.data.data.filter((item: any) => {
              let _data = dataTime?.data.data.find((_item: any) => item.documentMaintenanceID === _item.documentMaintenanceID)
              if(_data) {
               
                return [TimelineStatus.needDocument].includes(_data.status);
              }
              return false
            })}
            columns={[
              {
                name: "Kullanıcı Tipi",
                selector: "userTypeName",
                width: "200px",
              },
              { name: "Doküman Adı", selector: "documentName", width: "200px" },
              {
                name: "Dokümanlar Ve Yüklenme Tarihleri",
                width: "200px",
                cell: (row: any) => {
                  return (
                    <Flex pr="10">
                      {row.documents
                        .filter(
                          (document: any) => document.type === "formPhoto"
                        )
                        .map((document: any, index: number) => (
                          <Button
                            colorScheme="teal"
                            size="xs"
                            py="3"
                            mr="1"
                            onClick={() => {
                              window.open(
                                "https://siemensapi.tesisyonetim.pro" +
                                  document.path,
                                "_blank"
                              );
                            }}
                          >
                            {Moment(document.completedDate).format(
                              "DD.MM.YYYY HH:mm"
                            )}
                          </Button>
                        ))}
                    </Flex>
                  );
                },
              },
              {
                name: "Fotoğraf / Belge Ve Tarihleri",
                width: "500px",
                cell: (row: any) => {
                  return (
                    <Flex pr="10">
                      {row.documents
                        .filter(
                          (document: any) => document.type === "inventoryPhoto"
                        )
                        .map((document: any, index: number) => (
                          <Button
                            colorScheme="teal"
                            size="xs"
                            py="3"
                            mr="1"
                            onClick={() => {
                              window.open(
                                "https://siemensapi.tesisyonetim.pro" +
                                  document.path,
                                "_blank"
                              );
                            }}
                          >
                            {Moment(document.completedDate).format(
                              "DD.MM.YYYY HH:mm"
                            )}
                          </Button>
                        ))}
                    </Flex>
                  );
                },
              },
              {
                name: "Periyot",
                selector: "documentMaintenancePeriodName",
                width: "150px",
              },
              {
                name: "Başlangıç  Tarihi",
                cell: (row: any) => Moment(row.startDate).format("DD.MM.YYYY"),
                width: "200px",
              },
              {
                name: "Planlı Doküman Yükleme Tarihi",
                cell: (row: any) => Moment(row.endDate).format("DD.MM.YYYY"),
                width: "200px",
              },
              {
                name: "Son Doküman Yüklenen Tarih",
                cell: (row: any) =>
                  Moment(row.completedDate).format("DD.MM.YYYY"),
                width: "200px",
              },
              {
                name: "Notlar",
                cell: (row: any) =>
                  row.secondDate
                    ? Moment(row.secondDate).format("DD.MM.YYYY")
                    : "Mevcut Değil",
                width: "200px",
              },
              {
                name: "Soru Yanıtı",
                selector: "questionAnswer",
                width: "200px",
              },
              {
                name: "İkinci Doküman Yüklenme Notu",
                selector: "updateExplain",
                width: "200px",
              },
              { name: "Tesis", selector: "campusName", width: "200px" },
              { name: "Bina", selector: "buildName", width: "200px" },
              { name: "Kat", selector: "floorName", width: "200px" },
              { name: "Oda", selector: "roomName", width: "200px" },
              { name: "Grup", selector: "groupName", width: "200px" },
              { name: "Marka", selector: "brandName", width: "200px" },
              { name: "Model", selector: "modelName", width: "200px" },
              {
                name: "Kapasite Birimi",
                selector: "documentCapacityName",
                width: "200px",
              },
              {
                name: "Kapasite Değeri",
                selector: "documentUnit",
                width: "200px",
              },
              {
                name: "Üretim Yılı",
                selector: "documentProductionYear",
                width: "200px",
              },
              {
                name: "İlk Dokümanı Yükleyen Kullanıcı",
                selector: "firstControlUserName",
                width: "200px",
              },
              {
                name: "İkinci Dokümanı Yükleyen Kullanıcı",
                selector: "secondControlUserName",
                width: "200px",
              },
            ]}
          />
        )}
      </Card>
    </Main>
  );
};

CompletedLegal.defaultProps = {};

export default CompletedLegal;
