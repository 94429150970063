import React, { FC } from "react";
import Main from "components/templates/Main";
import NoteList from "features/daily-note/daily-note-list/NoteList";

type AllNotesProps = {};

const AllNotes: FC<AllNotesProps> = (props) => {
  return (
    <Main headerProps={{ title: "Tüm Notlar" }}>
      <NoteList />
    </Main>
  );
};

export default AllNotes;
