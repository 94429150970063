import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import DataTable from "components/atoms/DataTable";
import Moment from "moment";
import Badge from "components/atoms/Badge";
import Confirm from "components/atoms/Confirm";
import { IoOptions } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import InventoryFilter from "components/organisms/InventoryFilter";
import { UserRoles } from "models/user";

interface DocumentPageProps {}

const DocumentPage: FC<DocumentPageProps> = (props) => {
  const [getList, statList, dataList] = Hooks.DocumentList();
  const [update, statUpdate, dataUpdate] = Hooks.UpdateDocument();

  const { project, role } = useAuth();
  const history = useHistory();
  const filterDisc = useDisclosure();
  const [filterData, setFilterData] = useState<any>(null);

  useLayoutEffect(() => {
    getList(project.id);
  }, []);

  function _onFilterData(row: any) {
    function _condition(a: any, b: any) {
      return !a || a === b;
    }

    const _data = [
      _condition(filterData?.campus?.value, row.campusID),
      _condition(filterData?.build?.value, row.buildID),
      _condition(filterData?.floor?.value, row.floorID),
      _condition(filterData?.room?.value, row.roomID),
      _condition(filterData?.userType?.value, row.userTypeID),
      _condition(filterData?.group?.value, row.groupID),
      _condition(filterData?.brand?.value, row.brandID),
      _condition(filterData?.model?.value, row.modelID),
    ];

    return !_data.includes(false);
  }

  useEffect(() => {
    if (!filterDisc.isOpen) {
      setFilterData({});
    }
  }, [filterDisc.isOpen]);

  return (
    <Main headerProps={{ title: "Belge Takip Listesi" }}>
      <Card p="3" mb="3">
        <Flex justifyContent="flex-end">
          <Button
            size="sm"
            colorScheme="orange"
            mr="3"
            onClick={() => filterDisc.onToggle()}
          >
            {filterDisc.isOpen ? "Filtre Kapat" : "Filtre"}
          </Button>
          {role !== UserRoles.basic && role !== UserRoles.view && (
            <Button
              colorScheme="blue"
              size="sm"
              onClick={() => {
                history.push("/document-create");
              }}
            >
              Yeni Ekle
            </Button>
          )}
        </Flex>
      </Card>
      {statList !== "fulfilled" && (
        <Center mt="5">
          <Spinner size="lg" />
        </Center>
      )}

      {filterDisc.isOpen && (
        <InventoryFilter
          onFilter={(data: any) => {
            setFilterData(data);
          }}
        />
      )}

      {!!filterData && Object.values(filterData).length > 0 && (
        <Flex flexWrap="wrap" mb="3">
          {Object.values(filterData).map((data: any) =>
            !!data?.label ? (
              <Center p="2" px="4" bgColor="white" mr="3">
                <Text fontSize="xs" textAlign="center">
                  {data?.label}
                </Text>
              </Center>
            ) : null
          )}
        </Flex>
      )}

      {statList === "fulfilled" && dataList?.data.data && (
        <Card mt="3">
          <DataTable
            data={dataList?.data.data
              .filter(_onFilterData)
              .map((item: any) => ({
                ...item,
                highlight: item.hasMaintenance,
              }))}
            columns={[
              { name: "#ID", selector: "id" },
              { name: "Belge Adı", width: "150px", selector: "documentName" },
              { name: "Grup", width: "150px", selector: "groupName" },
              {
                name: "Kullanıcı Tipi",
                width: "150px",
                selector: "userTypeName",
              },
              {
                name: "Atanan Kullanıcı",
                width: "150px",
                selector: "assignUserName",
              },
              { name: "Kampüs", width: "150px", selector: "campusName" },
              { name: "Bina", width: "150px", selector: "buildName" },
              { name: "Kat", width: "150px", selector: "floorName" },
              { name: "Oda", width: "150px", selector: "roomName" },
              { name: "Marka", width: "150px", selector: "brandName" },
              { name: "Model", width: "150px", selector: "modelName" },
              {
                name: "Oluşturulma Tarihi",
                width: "150px",
                cell: (row: any) => Moment(row.createDate).format("DD.MM.YYYY"),
              },
              { name: "Açıklama", width: "150px", selector: "explain" },
              {
                name: "Oluşturan Kullanıcı",
                cell: (row: any) => <span>{row.createUserName}</span>,
                width: "200px",
                selector: "createUserName",
                columnType: "string",
              },
              {
                name: "Durum",
                width: "150px",
                cell: (row: any) => <Badge isActive={row.isActive} />,
                selector: "isActive",
                columnType: "isActiveBoolean",
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any) => (
                  <Box>
                    <Confirm
                      onConfirm={async () => {
                        await update({ ...row, isActive: !row.isActive });
                        getList(project.id);
                      }}
                      text="Bu kayıt pasif yapılacak. İşleme devam edilsin mi?"
                    >
                      {({ onOpen }) => (
                        <IconButton
                          size="sm"
                          onClick={onOpen}
                          aria-label="active"
                          icon={<IoOptions />}
                        />
                      )}
                    </Confirm>
                  </Box>
                ),
              },
              {
                name: "Düzenle",
                cell: (row: any) => (
                  <Box>
                    <Button
                      colorScheme="purple"
                      size="sm"
                      onClick={() => {
                       
                        history.push("/document-edit", { document: row });
                      }}
                    >
                      Düzenle
                    </Button>
                  </Box>
                ),
              },
              {
                name: "Kontrol Listesi",
                width: "150px",
                cell: (row: any) => (
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => history.push(`/document/${row.id}/control`)}
                  >
                    Kontrol Listesi
                  </Button>
                ),
              },
              {
                name: "Kontrol Ekle",
                width: "150px",
                cell: (row: any) => (
                  <Button
                    size="sm"
                    colorScheme="teal"
                    disabled={
                      role === UserRoles.view || role === UserRoles.basic
                    }
                    onClick={() =>
                      history.push(`/document/${row.id}/control-create`)
                    }
                  >
                    Kontrol Ekle
                  </Button>
                ),
              },
            ]}
          />
        </Card>
      )}
    </Main>
  );
};

DocumentPage.defaultProps = {};

export default DocumentPage;
