import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Center, Flex, Spinner, Text, useDisclosure, useToast
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import DTable from "components/atoms/DataTable";
import { DrawerProps } from "components/atoms/Drawer";
import InventoryFilter from "components/organisms/InventoryFilter";
import Main from "components/templates/Main";
import { useAuth } from "hooks/redux-hooks";
import { flatMap } from "lodash";
import { Inventory } from "models";
import Moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-lightbox/style.css";
import { useHistory } from "react-router";
import { Hooks } from "services";


function InventoryPage() {
  const { project, role } = useAuth();
  const history = useHistory();
  const [selectedInventory, setSelectedInventory] = useState<Inventory>();
  const drawerRef = useRef<DrawerProps>(null);
  const [lightBoxImage, setLightBoxImage] = useState("");
  const toast = useToast();
  const filterDisc = useDisclosure();
  const [filterData, setFilterData] = useState<any>(null);

  const [getList, statusList, data] = Hooks.AllTask();

  useLayoutEffect(() => {
    getList(project.id);
  }, []);

  useEffect(() => {
    if (!filterDisc.isOpen) {
      setFilterData({});
    }
  }, [filterDisc.isOpen]);

  return (
    <Main headerProps={{ title: "Görev Listesi" }}>
      {filterDisc.isOpen && (
        <InventoryFilter
          onFilter={(data: any) => {
            setFilterData(data);
          }}
        />
      )}

      {!!filterData && Object.values(filterData).length > 0 && (
        <Flex flexWrap="wrap" mb="3">
          {Object.values(filterData).map((data: any) =>
            !!data?.label ? (
              <Center p="2" px="4" bgColor="white" mr="3">
                <Text fontSize="xs" textAlign="center">
                  {data?.label}
                </Text>
              </Center>
            ) : null
          )}
        </Flex>
      )}

      {!!data?.data.data ? (
        <Card>
          <DTable
            columns={[
              {
                name: "ID",
                selector: "id",
                sortable: true,
                width: "100px",
              },
              {
                name: "Denetim Adı",
                selector: "controlName",
                sortable: true,
                width: "100px",
              },
              {
                name: "Denetim Açıklaması",
                selector: "controlDesc",
                sortable: true,
                width: "100px",
              },
              {
                name: "Periyot",
                selector: "controlPeriodName",
                sortable: true,
                width: "100px",
              },
              {
                name: "Grup",
                selector: "controlQuestionGroupName",
                sortable: true,
                width: "100px",
              },
              {
                name: "Açıklama",
                selector: "explain",
                sortable: true,
                width: "100px",
              },
              {
                name: "Bitiş Tarihi",
                cell: (row) => Moment(row.startDate).format("DD.MM.YYYY"),
                sortable: true,
                width: "100px",
              },
            ]}
            data={flatMap(
              (data?.data.data.map((item: any) => [
                ...item?.controlTaskList.map((_task: any) => ({..._task, controlName: item.controlName, controlDesc: item.controlDescription})),
              ]) as Array<any>) || []
            )}
          />
        </Card>
      ) : (
        <Card>
          <Center>
            <Spinner />
          </Center>
        </Card>
      )}
    </Main>
  );
}

export default InventoryPage;
