import { Center, Spinner } from "@chakra-ui/react";
import Main from "components/templates/Main";
import InventoryEditForm from "features/inventory/forms/inventory-edit-form/InventoryEditForm";
import { FC, useLayoutEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { mainPaths } from "router/path";
import { Hooks } from "services";

interface InventoryEditPageProps {}

const InventoryEditPage: FC<
  InventoryEditPageProps & RouteComponentProps<{ id: string }>
> = (props) => {
  const history = useHistory();
  const [getInventoryDetail, status, data] = Hooks.InventoryDetail();
  useLayoutEffect(() => {
    getInventoryDetail(props.match.params.id);
  }, []);

  return (
    <Main headerProps={{ title: "Ekipman Düzenle" }}>
      {status !== "fulfilled" && (
        <Center>
          <Spinner />
        </Center>
      )}
      {status === "fulfilled" && (
        <InventoryEditForm
          onUpdate={() => {
            history.push(mainPaths.Inventory);
          }}
          location={{
            state: {
              data: data?.data?.data,
            },
          }}
        />
      )}
    </Main>
  );
};

InventoryEditPage.defaultProps = {};

export default InventoryEditPage;
