import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Center,
  Spinner,
  FormControl,
  FormLabel,
  Input,
  IconButton,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { UserRoles } from "models/user";
import DataTable from "components/atoms/DataTable";
import Moment from "moment";
import Badge from "components/atoms/Badge";
import { useHistory } from "react-router-dom";
import ModalAtom, { ModalProps } from "components/atoms/Modal";
import { useToast } from "hooks/toast";
import Confirm from "components/atoms/Confirm";
import { IoOptions } from "react-icons/io5";

interface QuestionFormListProps {}

const QuestionFormList: FC<QuestionFormListProps> = (props) => {
  const { role, project } = useAuth();
  const modalRef = useRef<ModalProps>(null);
  const editRef = useRef<ModalProps>(null);

  const [getQF, statQF, dataQF] = Hooks.QuestionForms();
  const [getQG, statQG, dataQG] = Hooks.AddQuestionGroup();
  const [updateQF, statUQF, dataUQF] = Hooks.UpdateQuestionForm();
  const history = useHistory();
  const toast = useToast();
  const [formName, setFormName] = useState<string>("");
  const [selectedGroup, setSelectedGroup] = useState<any>(null);

  useEffect(() => {
    if (statQG === "fulfilled" && dataQG?.data.success) {
      toast({ title: "Soru Grubu Başarıyla Eklendi" });
      modalRef.current?.close();
      setFormName("");
      getQF(project.id);
    }
  }, [statQG]);

  useEffect(() => {
    if (statUQF === "fulfilled" && dataUQF?.data.success) {
      toast({ title: "Soru Grubu Başarıyla Düzenlendi" });
      editRef.current?.close();
      setFormName("");
      getQF(project.id);
    }
  }, [statUQF]);

  useLayoutEffect(() => {
    getQF(project.id);
  }, []);

  useEffect(() => {
    if (statUQF === "fulfilled") {
      getQF(project.id);
    }
  }, [statUQF]);

  return (
    <Main headerProps={{ title: "Kontrol Soru Grupları" }}>
      <Card py="2">
        <Flex justifyContent="flex-end">
          {role !== UserRoles.basic && (
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => modalRef.current?.open()}
            >
              Yeni Oluştur
            </Button>
          )}
        </Flex>
      </Card>

      <Card mt="3">
        {statQF !== "fulfilled" && (
          <Center minH="300px">
            <Spinner />
          </Center>
        )}
        {statQF === "fulfilled" && !!dataQF?.data.data && (
          <DataTable
            columns={[
              { name: "#ID", selector: "id" },
              { name: "Grup Adı", selector: "name" },
              {
                name: "Oluşturulma Tarihi",
                cell: (row: any) => Moment(row.createDate).format("DD.MM.YYYY"),
                selector: "createDate",
                columnType: "date",
              },
              {
                name: "Durum",
                cell: (row: any) => <Badge isActive={row.isActive} />,
                selector: "isActive",
                columnType: "isActiveBoolean",
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any) => (
                  <Center w="90%">
                    <Confirm
                      onConfirm={() => {
                        updateQF(row.id, row.name, !row.isActive);
                      }}
                      text={`Soru Grubu ${
                        !row.isActive ? "Aktif" : "Pasif"
                      } yapılsın mı?`}
                    >
                      {({ onOpen }) => (
                        <IconButton
                          size="sm"
                          onClick={onOpen}
                          aria-label="activepassive"
                          icon={<IoOptions />}
                        />
                      )}
                    </Confirm>
                  </Center>
                ),
                width: "100px",
              },
              {
                name: "Düzenle",
                cell: (row: any) => (
                  <Box>
                    <Button
                      onClick={() => {
                        setSelectedGroup(row);
                        setFormName(row.name);
                        editRef.current?.open();
                      }}
                    >
                      Düzenle
                    </Button>
                  </Box>
                ),
              },

              {
                name: "Sorular",
                cell: (row: any) => (
                  <Button
                    size="sm"
                    colorScheme="teal"
                    onClick={() => {
                      history.push("/control-questions", { group: row });
                    }}
                  >
                    Sorular
                  </Button>
                ),
              },
            ]}
            data={dataQF.data.data}
          />
        )}
      </Card>
      <ModalAtom
        ref={modalRef}
        title="Soru Grubu Oluştur"
        onConfirm={() => {
          getQG({
            projectID: project.id,
            name: formName,
          });
        }}
        onClose={() => {
          setFormName("");
        }}
        onConfirmText="Oluştur"
      >
        <FormControl>
          <FormLabel>
            Soru Grup Adı{" "}
            <Text color="red.500" d="inline">
              *
            </Text>
          </FormLabel>
          <Input
            type="text"
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
          />
        </FormControl>
      </ModalAtom>
      <ModalAtom
        ref={editRef}
        title="Soru Grubu Düzenle"
        onConfirm={() => {
          updateQF(selectedGroup.id, formName, selectedGroup.isActive);
        }}
        onClose={() => {
          setFormName("");
        }}
        onConfirmText="Düzenle"
      >
        <FormControl>
          <FormLabel>
            Soru Grup Adı{" "}
            <Text color="red.500" d="inline">
              *
            </Text>
          </FormLabel>
          <Input
            type="text"
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
          />
        </FormControl>
      </ModalAtom>
    </Main>
  );
};

QuestionFormList.defaultProps = {};

export default QuestionFormList;
