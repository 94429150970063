import React, { FC, useLayoutEffect, useRef, useState } from "react";
import { Box, Center, Text, Spinner, Button, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import Main from "components/templates/Main";
import { Card, ModalProps } from "components/atoms";
import DataTable from "components/atoms/DataTable";
import { TimelineLegal } from "models";
import { TimelineStatus } from "components/organisms/TimelineLegal";
import Moment from "moment";
import RSelect from "react-select";
import ModalAtom from "components/atoms/Modal";
import { isSieOrNo } from "helper/project-mode";

interface CompletedLegalProps {}

const CompletedLegal: FC<CompletedLegalProps> = (props) => {
  const { project } = useAuth();
  const [getData, statusData, dataData] = Hooks.TimelineLegal();
  const [getPeriods, statPeriods, dataPeriods] = Hooks.ListPeriod();
  const [getUT, statUT, dataUT] = Hooks.UserTypeList();
  const [filterData, setFilterData] = useState<any[]>([]);
  const filterModalRef = useRef<ModalProps>(null);

  useLayoutEffect(() => {
    getData(project.id);
    getPeriods(project.id);
    getUT();
  }, []);

  function onFilter(item: any) {
    const filters: boolean[] = [];
    filterData.forEach((_filter) => {
      filters.push(item[_filter.name] === _filter.value);
    });

    return !filters.includes(false);
  }

  return (
    <Main headerProps={{ title: `Form Beklenen Periyodik ${isSieOrNo("Kontroller", "Muayeneler")}` }}>
      <Card>
        {statusData !== "fulfilled" || !dataData ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <DataTable
            onClickFilter={() => {
              filterModalRef.current?.open();
            }}
            data={dataData.data.data.filter((item: TimelineLegal) =>
              [TimelineStatus.needDocument].includes(item.status)
            ).filter(onFilter)}
            columns={[
              {
                name: "Kullanıcı Tipi",
                selector: "userTypeName",
                width: "200px",
              },
              { name: "Barkod", selector: "barcode" },
              { name: "Durum", selector: "statusDesc", width: "360px" },
              { name: "Ekipman Adı", selector: "name", width: "200px" },
              {
                name: `Periyodik ${isSieOrNo("Kontrol", "Muayene")} Formları Ve Yüklenme Tarihleri`,
                width: "400px",
                cell: (row: any) => {
                  return (
                    <Flex pr="3">
                      {row.documents.filter((item: any) => item.type === "formPhoto").map((_document: any, index: number) => (
                        <Button
                          colorScheme="teal"
                          size="xs"
                          py="2"
                          mr="1"
                          onClick={() => {
                            window.open(_document.path, "_blank");
                          }}
                        >
                          {Moment(_document.createDate).format(
                            "DD.MM.YYYY HH:mm"
                          )}
                        </Button>
                      ))}
                    </Flex>
                  );
                },
              },
              {
                name: "Fotoğraf / Belge Ve Yüklenme Tarihleri",
                width: "500px",
                cell: (row: any) => {
                  return (
                    <Flex pr="3">
                      {row.documents.filter((item: any) => item.type !== "formPhoto").map((_document: any, index: number) => (
                        <Button
                          colorScheme="teal"
                          size="xs"
                          py="2"
                          mr="1"
                          onClick={() => {
                            window.open(_document.path, "_blank");
                          }}
                        >
                          {Moment(_document.createDate).format(
                            "DD.MM.YYYY HH:mm"
                          )}
                        </Button>
                      ))}
                    </Flex>
                  );
                },
              },
              {
                name: "Başlangıç Tarihi",
                cell: (row: TimelineLegal) =>
                  Moment(row.startDate).format("DD.MM.YYYY"),
                width: "200px",
              },
              {
                name: `Planlı Periyodik ${isSieOrNo("Kontrol", "Muayene")} Tarihi`,
                cell: (row: TimelineLegal) =>
                  Moment(row.endDate).format("DD.MM.YYYY"),
                width: "200px",
              },
              {
                name: "Son Form Yüklenme Tarihi",
                width: "200px",
                cell: (row: TimelineLegal) =>
                  row.documents &&
                  Array.isArray(row.documents) &&
                  row.documents.length > 0
                    ? Moment(row.documents[0].createDate).format(
                        "DD.MM.YYYY HH:mm"
                      )
                    : null,
              },
              {
                name: "Gerçekleştiren Firma",
                selector: "maintenanceFirm",
                width: "200px",
              },
              {
                name: "İkinci Form Yüklenmesi Gereken Tarih",
                cell: (row: TimelineLegal) =>
                  Moment(row.secondDate).format("DD.MM.YYYY"),
                width: "200px",
              },
              { name: "Notlar", selector: "explain", width: "200px" },
              { name: "Soru Yanıtı", cell: (row: any) => row.answer ? "Uygun" : "Uygun Değil" , width: "200px" },
              { name: "Tesis", selector: "campusName", width: "200px" },
              { name: "Bina", selector: "buildName", width: "200px" },
              { name: "Kat", selector: "floorName", width: "200px" },
              { name: "Oda", selector: "roomName", width: "200px" },
              { name: "Grup", selector: "groupName", width: "200px" },
              { name: "Marka", selector: "brandName", width: "200px" },
              { name: "Model", selector: "modelName", width: "200px" },
              {
                name: "Kapasite",
                selector: "inventoryCapacityName",
                width: "200px",
              },
              { name: "Kapasite Değeri", selector: "unit", width: "200px" },
              {
                name: "Üretim Yılı",
                selector: "productionYear",
                width: "200px",
              },
              {
                name: "İlk Formu Yükleyen Kullanıcı",
                selector: "completedPersonelName",
                width: "200px",
              },
            ]}
          />
        )}
      </Card>
      <ModalAtom ref={filterModalRef} title="Filtre" noForm>
        <FormControl mt="5" mb="20">
          <FormLabel>Kullanıcı Tipi</FormLabel>
          <RSelect
            isClearable
            placeholder="Seçim Yap"
            onChange={(value) => {
              const keyName = "userTypeId";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                  },
                ]);
              }
            }}
            options={dataUT?.data.data.map((period: any) => ({
              value: period.id,
              label: period.name,
            }))}
          />
        </FormControl>
      </ModalAtom>
    </Main>
  );
};

CompletedLegal.defaultProps = {};

export default CompletedLegal;
