import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import React, { memo } from "react";
import RSelect from "react-select";

const SpecialSelect = memo((props: any) => {
  return (
    <FormControl isInvalid={props.isInvalid}>
      <FormLabel>
        {props.label}{" "}
        {props.isRequired && (
          <Text d="inline" color="red.500">
            *
          </Text>
        )}
      </FormLabel>
      <RSelect
        isClearable
        isSearchable
        value={
          !!props?.value
            ? {
                label: props.data?.find(
                  (item: any) => item.id === parseInt(props.value)
                )?.name,
                value: props.data?.find(
                  (item: any) => item.id === parseInt(props.value)
                )?.id,
              }
            : null
        }
        onChange={(value) => {
          props.onChange(value);
        }}
        isLoading={props.status === "pending"}
        placeholder="Seç"
        isDisabled={!props.data}
        options={props.data?.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))}
      />
      {props.isInvalid && (
        <FormErrorMessage>Bu alan boş bırakılamaz!</FormErrorMessage>
      )}
    </FormControl>
  );
});

export default SpecialSelect;
