import React, { FC } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  UseFormStateReturn,
} from "react-hook-form";

type ConditialInputControllerProps = {
  conditian: boolean;
  name: string;
  control: any;
  children: (params: any) => React.ReactElement;
};

const ConditialInputController: FC<ConditialInputControllerProps> = (props) => {
  if (!props.conditian) return null;
  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={{required: true}}
      render={props.children}
    />
  );
};

export default ConditialInputController;
