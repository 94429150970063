import React, { FC, Fragment, useLayoutEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  SimpleGrid,
  Divider,
  Center,
} from "@chakra-ui/react";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { Card } from "components/atoms";
import PerfectScrollbar from "components/atoms/PerfectScrollbar";
import Moment from "moment";
import { isSieOrNo } from "helper/project-mode";

interface MaintenanceNotesProps {}

enum NotesStatus {
  maintenance = 1,
  legal = 2,
  document = 4,
  control =5
}

const MaintenanceNotes: FC<MaintenanceNotesProps> = (props) => {
  const { project } = useAuth();
  const [getNotes, statNotes, dataNotes] = Hooks.GetMaintenanceNotes();
  const [getTL, statTL, dataTL] = Hooks.TimelineLegal();
  const [getDT, statDT, dataDT] = Hooks.DemandTimeline();
  const [getD, statD, dataD] = Hooks.TimelineDocument();
  const [statu, setStatu] = useState<NotesStatus>(NotesStatus.maintenance);

  useLayoutEffect(() => {
    getNotes(project.id);
    getTL(project.id);
    getD(project.id);
    getDT(project.id);
  }, []);

  return (
    <Card w="100%" border="1px" borderColor="gray.200" minH="580px">
      <SimpleGrid columns={[1, 2]} spacing={2} mb="3">
        <Button
          size="sm"
          w="100%"
          colorScheme="blue"
          variant={statu ===NotesStatus.maintenance ? "solid" : "outline"}
          onClick={() => {
            setStatu(NotesStatus.maintenance);
          }}
        >
          Bakım Notları
        </Button>
        <Button
          size="sm"
          w="100%"
          colorScheme="teal"
          variant={statu === NotesStatus.legal ? "solid" : "outline"}
          onClick={() => {
            setStatu(NotesStatus.legal);
          }}
        >
          Periyodik {isSieOrNo("Kontrol", "Muayene")} Notları
        </Button>
        <Button
          size="sm"
          w="100%"
          colorScheme="teal"
          variant={statu === NotesStatus.document ? "solid" : "outline"}
          onClick={() => {
            setStatu(NotesStatus.document);
          }}
        >
          Belge Takip Notları
        </Button>
        <Button
          size="sm"
          w="100%"
          colorScheme="blue"
          variant={statu ===NotesStatus.control ? "solid" : "outline"}
          onClick={() => {
            setStatu(NotesStatus.control);
          }}
        >
          Denetim Notları
        </Button>
      </SimpleGrid>
      {statu === NotesStatus.maintenance && (
        <Fragment>
          {dataNotes?.data.data.length === 0 ? (
            <Center h="200px">
              <Text>Bakım Notu Bulunamadı!</Text>
            </Center>
          ) : (
            <PerfectScrollbar maxHeight={500}>
              {dataNotes?.data.data.map((item: any, index: number) => (
                <Flex
                  borderWidth="1px"
                  mb="2"
                  justifyContent="space-between"
                  key={index}
                  alignItems="stretch"
                >
                  <Box p="3" flex="1">
                    <Text fontSize="xs">Ekipman Adı: {item.name}</Text>
                    <Text fontSize="xs">Barkod: {item.barcode}</Text>
                    <Text fontSize="xs">
                      Bakım Adı: {item.maintenanceTypeName}
                    </Text>
                    <Text fontSize="xs">
                      Periyot: {item.maintenancePeriodName}
                    </Text>
                  </Box>
                  <Divider orientation="vertical" h="50%" />
                  <Box p="3" flex="1" borderLeftWidth="1px">
                    <Text fontSize="xs">Kullanıcı: {item.personelName}</Text>
                    <Text fontSize="xs">
                      Tarih:{" "}
                      {Moment(item.maintenanceDate).format("DD.MM.YYYY HH:mm")}
                    </Text>
                    <Text fontWeight="bold" fontSize="xs">
                      Not: {item.maintenanceExplain}
                    </Text>
                  </Box>
                  {/* <Button size="sm" colorScheme="blue" fontSize="xs" m="1" h="100%">Sorun<br/>Giderildi</Button> */}
                </Flex>
              ))}
            </PerfectScrollbar>
          )}
        </Fragment>
      )}
      {statu === NotesStatus.legal && (
        <Fragment>
          {dataTL?.data.data.filter((item: any) => (item.explain || item.updateExplain) && [2, 6].includes(item.status)).length === 0 ? (
            <Center h="200px">
              <Text>Periyodik {isSieOrNo("Kontrol", "Muayene")} Notu Bulunamadı!</Text>
            </Center>
          ) : (
            <PerfectScrollbar maxHeight={500}>
              {dataTL?.data.data.filter((item: any) => (item.explain || item.updateExplain) && [2, 6].includes(item.status)).map((item: any, index: number) => (
                <Flex
                  borderWidth="1px"
                  mb="2"
                  justifyContent="space-between"
                  key={index}
                >
                  <Box p="3" flex="1">
                    <Text fontSize="xs">Ekipman Adı: {item.inventoryName}</Text>
                    <Text fontSize="xs">Barkod: {item.barcode}</Text>
                    <Text fontSize="xs">Kullanıcı Tipi: {item.userTypeName}</Text>
                  </Box>
                  <Divider orientation="vertical" h="50%" />
                  <Box p="3" flex="1" borderLeftWidth="1px">
                    <Text fontSize="xs">
                      Tarih:{" "}
                      {Moment(item.completedDate).format("DD.MM.YYYY HH:mm")}
                    </Text>
                    <Text fontWeight="bold" fontSize="xs">
                      Not: {item.explain}
                    </Text>
                    <Text fontWeight="bold" fontSize="xs">
                      İkinci Not: {item.updateExplain}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </PerfectScrollbar>
          )}
        </Fragment>
      )}
      {statu === NotesStatus.control && (
        <Fragment>
          {dataDT?.data.data.filter((item: any) => (item.explain || item.updateExplain) && [2, 6].includes(item.status)).length === 0 ? (
            <Center h="200px">
              <Text>Denetim Notu Bulunamadı!</Text>
            </Center>
          ) : (
            <PerfectScrollbar maxHeight={500}>
              {dataDT?.data.data.filter((item: any) => (item.explain || item.updateExplain) && [2, 6].includes(item.status)).map((item: any, index: number) => (
                <Flex
                  borderWidth="1px"
                  mb="2"
                  justifyContent="space-between"
                  key={index}
                >
                  <Box p="3" flex="1">
                    <Text fontSize="xs">Ekipman Adı: {item.inventoryName}</Text>
                    <Text fontSize="xs">Barkod: {item.barcode}</Text>
                    <Text fontSize="xs">Kullanıcı Tipi: {item.userTypeName}</Text>
                  </Box>
                  <Divider orientation="vertical" h="50%" />
                  <Box p="3" flex="1" borderLeftWidth="1px">
                    <Text fontSize="xs">
                      Tarih:{" "}
                      {Moment(item.completedDate).format("DD.MM.YYYY HH:mm")}
                    </Text>
                    <Text fontWeight="bold" fontSize="xs">
                      Not: {item.explain}
                    </Text>
                    <Text fontWeight="bold" fontSize="xs">
                      İkinci Not: {item.updateExplain}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </PerfectScrollbar>
          )}
        </Fragment>
      )}
      {statu === NotesStatus.document && (
        <Fragment>
          {dataD?.data.data.filter((item: any) => (item.explain || item.updateExplain) && [2, 6].includes(item.status)).length === 0 ? (
            <Center h="200px">
              <Text>Doküman Notu Bulunamadı!</Text>
            </Center>
          ) : (
            <PerfectScrollbar maxHeight={500}>
              {dataD?.data.data.filter((item: any) => (item.explain || item.updateExplain) && [2, 6].includes(item.status)).map((item: any, index: number) => (
                <Flex
                  borderWidth="1px"
                  mb="2"
                  justifyContent="space-between"
                  key={index}
                >
                  <Box p="3" flex="1">
                    <Text fontSize="xs">Doküman Adı: {item.documentName}</Text>
                    <Text fontSize="xs">Periyot: {item.documentPeriodName}</Text>
                    <Text fontSize="xs">Kullanıcı Tipi: {item.userTypeName}</Text>
                  </Box>
                  <Divider orientation="vertical" h="50%" />
                  <Box p="3" flex="1" borderLeftWidth="1px">
                    <Text fontSize="xs">
                      Tarih:{" "}
                      {Moment(item.completedDate).format("DD.MM.YYYY HH:mm")}
                    </Text>
                    <Text fontWeight="bold" fontSize="xs">
                      Not: {item.explain}
                    </Text>
                    <Text fontWeight="bold" fontSize="xs">
                      İkinci Not: {item.updateExplain}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </PerfectScrollbar>
          )}
        </Fragment>
      )}
    </Card>
  );
};

MaintenanceNotes.defaultProps = {};

export default MaintenanceNotes;
