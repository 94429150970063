import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Button, Center, Heading, Select } from "@chakra-ui/react";
import { Card } from "components/atoms";
import { useAuth } from "hooks/redux-hooks";
import { Hooks } from "services";
import Freeze from "./Freeze";
import { Project } from "services/swagger";
import { Authenticator } from "helper/authenticator";

function ProjectSelect(): ReactElement {
  const [project, setProject] = useState<Project | null>(null);
  const { user, token } = useAuth();
  const [get, status, data] = Hooks.ProjectList();

  useLayoutEffect(() => {
    get(user.id);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (data) {
      if (data.data.data.length === 1) {
        Authenticator.SetProject(token, user, data.data.data[0]);
      }
    }
  }, [data]);

  async function handleClick() {
    if (!!project) {
      Authenticator.SetProject(token, user, project);
    }
  }

  if (status !== "fulfilled" || !data?.data?.success) return <Freeze />;

  return (
    <Center minH="100vh">
      <Card minW="250px">
        <Heading mb="2" fontSize="md">
          Proje Seçiniz
        </Heading>
        <Select
          borderRadius="sm"
          mb="3"
          onChange={(e) => {
            const _project = data?.data.data.find(
              (item) => item.id?.toString() === e.currentTarget.value
            );
            if (_project) {
              setProject(_project);
            }
          }}
        >
          <option value="-1">Project Seçiniz</option>
          {data?.data!.data.map((project) => (
            <option value={project.id}>{project.name}</option>
          ))}
        </Select>
        <Button w="100%" colorScheme="teal" onClick={handleClick}>
          Seç
        </Button>
      </Card>
    </Center>
  );
}

export default ProjectSelect;
