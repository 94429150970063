import React, { useEffect, useLayoutEffect, useRef, FC } from "react";
import {
  Flex,
  Box,
  Text,
  SimpleGrid,
  FormLabel,
  Input,
  FormControl,
  Select,
  Divider,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  FormHelperText,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { useForm, Controller } from "react-hook-form";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import {
  FileElement,
  InputElement,
  SelectElement,
} from "components/atoms/FormElements";
import ReactSelect from "react-select";
import { useHistory } from "react-router-dom";
import { IoAdd } from "react-icons/io5";
import Modal, { ModalProps } from "components/atoms/Modal";
import { Form, InputElement as HookInput } from "components/atoms/HookForm";
import { UserRoles } from "models/user";

interface DemandCreatePageProps {}

const DemandCreatePage: FC<DemandCreatePageProps> = (props) => {
  const { project, user, role } = useAuth();
  const { control, handleSubmit, watch, setValue } = useForm();
  const [addInventory, statusInventory, dataInventory] = Hooks.AddDemand();
  const [getTypes, , userTypes] = Hooks.UserTypeList();
  const toast = useToast();
  const history = useHistory();

  const [getCampuses, statCampuses, dataCampuses] = Hooks.CampusList();
  const [getBuilds, statBuilds, dataBuilds] = Hooks.BuildList();
  const [getFloors, statFloors, dataFloors] = Hooks.FloorList();
  const [getRooms, statRooms, dataRooms] = Hooks.RoomList();
  const [getGroups, statGroups, dataGroups] = Hooks.GroupList();
  const [getBrands, statBrands, dataBrands] = Hooks.BrandList();
  const [getModels, statModels, dataModels] = Hooks.ModelList();

  const [getDemandGroup, statDemandGroup, dataDemandGroup] = Hooks.DemandGroupList();
  const [addDemandGroup, statAddDemandGroup, dataAddDemandGroup] = Hooks.AddDemandGroup();

  const [onAddGroup, statusAddGroup] = Hooks.AddGroup();
  const [onAddBrand, statusAddBrand] = Hooks.AddBrand();
  const [onAddModel, statusAddModel] = Hooks.AddModel();

  const groupModal = useRef<ModalProps>(null);
  const brandModal = useRef<ModalProps>(null);
  const modelModal = useRef<ModalProps>(null);

  useLayoutEffect(() => {
    if (!!!dataCampuses) {
      getCampuses(project.id);
    }
    getGroups(project.id);
    getDemandGroup(project.id);
    getTypes();
  }, []);

  useEffect(() => {
    if (!isNaN(parseInt(watch("CampusID")))) {
      getBuilds(parseInt(watch("CampusID")));
      setValue("BuildID", undefined);
    }
  }, [watch("CampusID")]);

  useEffect(() => {
    if (!isNaN(parseInt(watch("BuildID")))) {
      getFloors(parseInt(watch("BuildID")));
      setValue("FloorID", undefined);
    }
  }, [watch("BuildID")]);

  useEffect(() => {
    if (!isNaN(parseInt(watch("FloorID")))) {
      getRooms(parseInt(watch("FloorID")));
      setValue("RoomID", undefined);
    }
  }, [watch("FloorID")]);

  useEffect(() => {
    if (!isNaN(parseInt(watch("GroupID")))) {
      getBrands(parseInt(watch("GroupID")));
      setValue("ModelID", undefined);
    }
  }, [watch("GroupID")]);

  useEffect(() => {
    if (!isNaN(parseInt(watch("BrandID")))) {
      getModels(parseInt(watch("BrandID")));
      setValue("ModelID", undefined);
    }
  }, [watch("BrandID")]);

  function onValid(values: any) {
   
    addInventory({
      projectID: project.id,
      userTypeID: parseInt(values.UserTypeID),
      campusID: parseInt(values.CampusID),
      buildID: parseInt(values.BuildID),
      floorID: parseInt(values.FloorID),
      roomID: parseInt(values.RoomID),
      explain: values.Explain,
      name: values.Name,
      demandGroupID: parseInt(values.DemandGroupId),
      createUserId: user.id
    });
  }

  useEffect(() => {
    if (!!dataInventory) {
      if (dataInventory.data.success) {
        toast({ status: "success", title: "Talep başarıyla eklendi!" });
        history.push(`/demand`);
      } else {
        toast({
          status: "error",
          title: "Talep Eklenemedi",
          description: dataInventory.data.message,
        });
      }
    }
  }, [dataInventory]);
  return (
    <Main headerProps={{ title: "Talep Oluştur" }}>
      <Card>
        <form onSubmit={handleSubmit(onValid)}>
          <Tabs isFitted>
            <TabList>
              <Tab>Lokasyon Bilgileri</Tab>
              <Tab>Kullanıcı Tipi</Tab>
              <Tab>Genel Bilgiler</Tab>
            </TabList>
            <TabPanels>
              <TabPanel pt="10">
                <SimpleGrid columns={[1, 2]} gap="5">
                  <SelectElement
                    name="CampusID"
                    label="Tesis"
                    control={control}
                    isRequired
                    data={dataCampuses?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                  <SelectElement
                    name="BuildID"
                    label="Bina"
                    control={control}
                    isRequired
                    data={dataBuilds?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                  <SelectElement
                    name="FloorID"
                    label="Kat"
                    isRequired
                    control={control}
                    data={dataFloors?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                  <SelectElement
                    name="RoomID"
                    label="Oda"
                    isRequired
                    control={control}
                    data={dataRooms?.data.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                </SimpleGrid>
              </TabPanel>
              <TabPanel pt="10">
                <SimpleGrid columns={2} gap="5">
                  <Flex alignItems="flex-end">
                    <SelectElement
                      name="DemandGroupId"
                      label="Grup"
                      isRequired
                      control={control}
                      data={dataDemandGroup?.data.data.map((item: any) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                    <IconButton
                      icon={<IoAdd />}
                      ml="2"
                      variant="outline"
                      aria-label="add"
                      onClick={() => {
                        groupModal.current?.open();
                      }}
                    />
                  </Flex>
                  <SelectElement
                    name="UserTypeID"
                    label="Kullanıcı Tipi"
                    isRequired
                    control={control}
                    data={userTypes?.data.data
                      .filter((item) =>
                        role === UserRoles.admin
                          ? true
                          : user.userTypes
                              .map((_type: any) => _type.id)
                              .includes(item.id)
                      )
                      .map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                  />
                </SimpleGrid>
              </TabPanel>
              <TabPanel pt="10">
                <SimpleGrid columns={[1, 2]} gap="5">
                  <InputElement
                    name="Name"
                    label="Talep Adı"
                    isRequired
                    control={control}
                  />
                  <InputElement
                    name="Explain"
                    label="Açıklama"
                    control={control}
                  />
                </SimpleGrid>
                <Flex justifyContent="flex-end" mt="5">
                  <Button colorScheme="teal" type="submit">
                    Oluştur
                  </Button>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </form>
      </Card>
      <Modal title="Grup Ekle" ref={groupModal}>
        <Form
          onValid={async ({ name }: any) => {
            await addDemandGroup({ name: name, projectID: project.id });
            toast({ status: "success", title: "Grup başarıyla eklendi!" });
            getDemandGroup(project.id);
            groupModal.current?.close();
          }}
        >
          <HookInput name="name" label="İsim" />
          <Button mt="2" type="submit">
            Grup Ekle
          </Button>
        </Form>
      </Modal>
      <Modal title="Marka Ekle" ref={brandModal}>
        <Form
          onValid={async ({ name }: any) => {
            await onAddBrand({
              name: name,
              groupID: parseInt(watch("GroupID")),
            });
            toast({ status: "success", title: "Marka başarıyla eklendi!" });
            getBrands(parseInt(watch("GroupID")));
            brandModal.current?.close();
          }}
        >
          <HookInput name="name" label="İsim" />
          <Button mt="2" type="submit">
            Marka Ekle
          </Button>
        </Form>
      </Modal>
      <Modal title="Model Ekle" ref={modelModal}>
        <Form
          onValid={async ({ name }: any) => {
            await onAddModel({
              name: name,
              brandID: parseInt(watch("BrandID")),
            });
            toast({ status: "success", title: "Model başarıyla eklendi!" });
            getModels(parseInt(watch("BrandID")));
            modelModal.current?.close();
          }}
        >
          <HookInput name="name" label="İsim" />
          <Button mt="2" type="submit">
            Model Ekle
          </Button>
        </Form>
      </Modal>
    </Main>
  );
};

DemandCreatePage.defaultProps = {};

export default DemandCreatePage;
