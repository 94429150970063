import React, {
  FC,
  Fragment,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { RouteComponentProps } from "react-router-dom";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { Hooks } from "services";
import { TaskMain, User } from "models";
import { FaCheck } from "react-icons/fa";
import Dropzone from "components/atoms/Dropzone";
import { useAuth } from "hooks/redux-hooks";
import { useToast } from "hooks/toast";

export enum ControlAnswer {
  cantAgree = "Kabul Edilemez",
  canDevelop = "Geliştirilebilir",
  good = "İyi",
  notInterest = "İlgili değil",
}

interface PageState {
  data: TaskMain;
}

interface DoControlProps {}

const DoControl: FC<DoControlProps & RouteComponentProps<{}, {}, PageState>> =
  ({
    history,
    location: {
      state: { data },
    },
  }) => {
    const { user, project } = useAuth();
    const [getQL, statQL, dataQL] = Hooks.QuestionList();
    const [doMaintenance, statDoMain, dataDoMain] = Hooks.DoControlTask();
    const [questions, setQuestions] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const toast = useToast();

    const [datas, setDatas] = useState({
      isg: false,
      contract: false,
      instruction: false,
      guide: false,
      photo: [] as { values: File[]; questionId: any }[],
      request: null as File[] | null,
      firm: "",
      user: "",
      explain: "",
    });

    useLayoutEffect(() => {
      getQL(data.controlQuestionGroupID);
    }, []);

    useEffect(() => {
      if (dataQL) {
        let data: any[] = [];
        dataQL.data.data.forEach((quest: any, index: number) => {
          data.push({
            Id: quest.id,
            Question: quest.question,
            SelectedItem: null,
            isDone: true,
            QuestionType: quest.controlQuestionTypeID,
            InitialValue: quest.initialValue,
            EndValue: quest.endValue,
            Note: "",
            trueFalseAnswer: quest.trueFalseAnswer,
          });
        });
        setQuestions(data);
      }
    }, [dataQL]);

    useEffect(() => {
      if (statDoMain === "fulfilled") {
        if (dataDoMain?.data.success) {
          toast({ title: "İşlemi başarıyla gerçekleşti!" });
          history.push("/");
        } else if (dataDoMain?.data.success === false) {
          toast({ title: dataDoMain?.data.message, status: "error" });
        }
      } else if (statDoMain === "rejected") {
        toast({ title: "Hata Oluştu!", status: "error" });
      }
      setLoading(false);
    }, [statDoMain]);

    const toBase64 = (file: File) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    function getBase64Photos(arr: any[]) {
      return new Promise(async (resolve, reject) => {
        let arrData: any[] = [];
        for (var index = 0; index < arr.length; index++) {
          let base64str = await toBase64(arr[index].values);
          let ext = arr[index].values.name.split(".").pop();
          arrData.push({
            base64: base64str,
            extension: ext,
            questionId: arr[index].questionId,
          });
        }

        return resolve(arrData);
      });
    }

    async function handleClickComplete() {
      setLoading(true);
      let base64List = await getBase64Photos(datas.photo || []);
      await doMaintenance({
        answer: JSON.stringify(
          questions.map((question) => ({
            question: question.Question,
            answer: question.SelectedItem,
            note: question.Note,
            id: question.Id,
          }))
        ),
        controlID: data.controlId,
        controlTaskMaintenanceID: data.controlTaskMaintenanceID,
        completedUserID: user.id,
        completedUserName: `${(user as User).name} ${(user as User).surname}`,
        state: true,
        explain: datas.explain,
        projectID: project.id,
        documents: [
          ...(base64List as any[]).map((base64Str) => ({
            path: base64Str.base64,
            extension: base64Str.extension,
            type: `${base64Str.questionId}`,
          })),
        ],
      });
      setLoading(false);
    }

    return (
      <Main headerProps={{ title: "Denetim Görevi Gerçekleştirme" }}>
        <Card mt="3">
          {!!dataQL?.data.data &&
          Array.isArray(dataQL.data.data) &&
          dataQL.data.data!.length > 0 ? (
            <Fragment>
              <Fragment>
                <Heading fontSize="large" mt="8">
                  Açıklama
                </Heading>
                <Text color="gray.400" fontSize="xs">
                  Opsiyonel
                </Text>
                <Box>
                  <Textarea
                    mt="1"
                    placeholder="Açıklama Alanı"
                    value={datas.explain}
                    onChange={(e) =>
                      setDatas((state) => ({
                        ...state,
                        explain: e.target.value,
                      }))
                    }
                  ></Textarea>
                </Box>
              </Fragment>
              <Heading fontSize="large" mt="8">
                Sorular{" "}
                <Text d="inline" color="red.500">
                  *
                </Text>
              </Heading>
              <Divider my="2" />
              <Box bgColor="gray.50" p="5">
                {questions.map((question, index) => (
                  <Box mt="2">
                    <Text>{question.Question}</Text>
                    {(question.QuestionType === 1 ||
                      question.QuestionType === 3) && (
                      <Flex mt="1" flexDir={["column", "column", "row"]}>
                        <Button
                          mr="1"
                          mb="1"
                          minW="150px"
                          rightIcon={
                            question.SelectedItem ===
                            ControlAnswer.cantAgree ? (
                              <FaCheck />
                            ) : undefined
                          }
                          variant={
                            question.SelectedItem === ControlAnswer.cantAgree
                              ? "solid"
                              : "outline"
                          }
                          colorScheme="red"
                          onClick={() => {
                            const tmpQuestion = [...questions];
                            questions[index].SelectedItem =
                              ControlAnswer.cantAgree;
                            setQuestions(tmpQuestion);
                          }}
                        >
                          {ControlAnswer.cantAgree}
                        </Button>
                        <Button
                          mr="1"
                          mb="1"
                          minW="150px"
                          rightIcon={
                            question.SelectedItem ===
                            ControlAnswer.canDevelop ? (
                              <FaCheck />
                            ) : undefined
                          }
                          variant={
                            question.SelectedItem === ControlAnswer.canDevelop
                              ? "solid"
                              : "outline"
                          }
                          colorScheme="orange"
                          onClick={() => {
                            const tmpQuestion = [...questions];
                            questions[index].SelectedItem =
                              ControlAnswer.canDevelop;
                            setQuestions(tmpQuestion);
                          }}
                        >
                          {ControlAnswer.canDevelop}
                        </Button>
                        <Button
                          mr="1"
                          mb="1"
                          minW="150px"
                          rightIcon={
                            question.SelectedItem === ControlAnswer.good ? (
                              <FaCheck />
                            ) : undefined
                          }
                          variant={
                            question.SelectedItem === ControlAnswer.good
                              ? "solid"
                              : "outline"
                          }
                          colorScheme="green"
                          onClick={() => {
                            const tmpQuestion = [...questions];
                            questions[index].SelectedItem = ControlAnswer.good;
                            setQuestions(tmpQuestion);
                          }}
                        >
                          {ControlAnswer.good}
                        </Button>
                        <Button
                          minW="150px"
                          mr="1"
                          rightIcon={
                            question.SelectedItem ===
                            ControlAnswer.notInterest ? (
                              <FaCheck />
                            ) : undefined
                          }
                          variant={
                            question.SelectedItem === ControlAnswer.notInterest
                              ? "solid"
                              : "outline"
                          }
                          colorScheme="gray"
                          onClick={() => {
                            const tmpQuestion = [...questions];
                            questions[index].SelectedItem =
                              ControlAnswer.notInterest;
                            setQuestions(tmpQuestion);
                          }}
                        >
                          {ControlAnswer.notInterest}
                        </Button>
                      </Flex>
                    )}
                    {(question.QuestionType === 2 ||
                      question.QuestionType === 4) && (
                      <Flex mt="1" flexDir="column">
                        <Input
                          placeholder="Yanıt giriniz..."
                          type="number"
                          onChange={(e) => {
                            const tmpQuestion = [...questions];
                            questions[index].SelectedItem =
                              e.currentTarget.value;
                            setQuestions(tmpQuestion);
                          }}
                        />
                      </Flex>
                    )}
                    {question.QuestionType === 5 && (
                      <Flex mt="1" flexDir="column">
                        <Input
                          placeholder="Yanıt giriniz..."
                          type="number"
                          onChange={(e) => {
                            const tmpQuestion = [...questions];
                            questions[index].SelectedItem =
                              e.currentTarget.value;
                            setQuestions(tmpQuestion);
                          }}
                        />
                      </Flex>
                    )}
                    <Flex>
                      <Textarea
                        mt="1"
                        placeholder="Soru Notları"
                        value={question.Note}
                        onChange={(e) => {
                          const tmpQuestion = [...questions];
                          questions[index].Note = e.target.value;
                          setQuestions(tmpQuestion);
                        }}
                      ></Textarea>
                    </Flex>
                    {[
                      ControlAnswer.cantAgree,
                      ControlAnswer.notInterest,
                    ].includes(question.SelectedItem) && (
                      <Dropzone
                        onDrop={(files) => {
                          setDatas((_data) => ({
                            ..._data,
                            photo: [
                              ..._data.photo.filter(
                                (_file) => _file.questionId !== question.Id
                              ),
                              ...(files.map((_file) => ({
                                values: _file,
                                questionId: question.Id,
                              })) as any[]),
                            ],
                          }));
                        }}
                      />
                    )}
                    <Divider my="2" />
                  </Box>
                ))}
              </Box>
            </Fragment>
          ) : (
            <Center h="300px" flexDir="column">
              <Spinner />
              <Text>Sorular Yükleniyor</Text>
            </Center>
          )}
          <Flex justifyContent="flex-end" mt="10">
            <Button
              colorScheme="teal"
              onClick={handleClickComplete}
              isLoading={loading || statDoMain === "pending"}
            >
              Tamamla
            </Button>
          </Flex>
        </Card>
      </Main>
    );
  };

DoControl.defaultProps = {};

export default DoControl;
