import React, { FC, useEffect } from "react";
import { Button, Flex, SimpleGrid } from "@chakra-ui/react";
import { Card } from "components/atoms";
import { CheckBoxElement, Form, InputElement } from "components/atoms/HookForm";
import Main from "components/templates/Main";
import { useToast } from "hooks/toast";
import { Inventory, IPeriodicControl } from "models";
import Moment from "moment";
import { RouteComponentProps } from "react-router-dom";
import { Hooks } from "services";
import { isSieOrNo } from "helper/project-mode";

interface PageParams {
  id: string;
}

interface PageStates {
  inventory: Inventory;
  control: IPeriodicControl;
}

interface PeriodControlsProps {}

type PageProps = RouteComponentProps<PageParams, {}, PageStates> &
  PeriodControlsProps;

const EditPeriodControl: FC<PageProps> = (props) => {
  const [update, stat, data] = Hooks.UpdateLegal();
  const [updateDate, statDate] = Hooks.UpdateLegalDate();
  const toast = useToast();
  const { control } = props.location.state;

  async function handleValid(values: any) {
    updateDate({
      legalInspectionID: control.id,
      endDate: values.startDate,
    });
    update({
      id: control.id,
      beforeDay: control.beforeDay,
      controlFormPhotoIdRequired:
        `${values.controlFormPhotoIdRequired}` == "true" ? true : false,
      inventoryPhotoIdRequired:
        `${values.inventoryPhotoIdRequired}` == "true" ? true : false,
      maintenanceContractIsRequired:
        `${values.maintenanceContractIsRequired}` == "true" ? true : false,
      riskAnalysisIsRequired:
        `${values.riskAnalysisIsRequired}` == "true" ? true : false,
      userGudiePathIsRequired:
        `${values.userGudiePathIsRequired}` == "true" ? true : false,
      userInstructionsIsRequired:
        `${values.userInstructionsIsRequired}` == "true" ? true : false,
      startDate: control.startDate,
      projectID: control.projectID,
      isActive: control.isActive,
      inventoryID: control.inventoryID,
      question: values.question,
    });
  }

  useEffect(() => {
    if (stat === "fulfilled" && statDate === "fulfilled") {
      if (data?.data.success) {
        toast({
          title: `Periyodik ${isSieOrNo("Kontrol", "Muayene")} Başarıyla Düzenlendi!`,
          status: "success",
        });
        props.history.push(`/periodic-controls/${control.inventoryID}`, {
          inventory: props.location.state.inventory,
        } as any);
      }
    }
  }, [stat, statDate]);

  return (
    <Main headerProps={{ title: `Periyodik ${isSieOrNo("Kontrol", "Muayene")} Düzenle` }}>
      <Card mt="3">
        <Form onValid={handleValid}>
          <SimpleGrid columns={[1, 2]} spacing="5" mt="3">
            <InputElement
              label={`Periyodik ${isSieOrNo("Kontrol", "Muayene")} Tarihi`}
              type="date"
              name="startDate"
              defaultValue={Moment(control.startDate).format("YYYY-MM-DD")}
              isRequired
            />
            <CheckBoxElement
              label="Kullanma talimatı zorunlu mu?"
              name="userInstructionsIsRequired"
              defaultValue={control.userInstructionsIsRequired}
            />
            <CheckBoxElement
              label="Kullanım klavuzu zorunlu mu?"
              name="userGudiePathIsRequired"
              defaultValue={control.userGudiePathIsRequired}
            />
            <CheckBoxElement
              label="ISG risk analizi zorunlu mu?"
              name="riskAnalysisIsRequired"
              defaultValue={control.riskAnalysisIsRequired}
            />
            <CheckBoxElement
              label="Ekipman fotoğrafı zorunlu mu?"
              name="inventoryPhotoIdRequired"
              defaultValue={control.inventoryPhotoIdRequired}
            />
            <CheckBoxElement
              label="Bakım sözleşmesi zorunlu mu?"
              name="maintenanceContractIsRequired"
              defaultValue={control.maintenanceContractIsRequired}
            />
            <InputElement
              label="Soru"
              defaultValue={control.question}
              name="question"
              isRequired
            />
          </SimpleGrid>
          <Flex mt="5" justifyContent="flex-end">
            <Button
              colorScheme="teal"
              type="submit"
              isLoading={stat === "pending"}
            >
              Düzenle
            </Button>
          </Flex>
        </Form>
      </Card>
    </Main>
  );
};

EditPeriodControl.defaultProps = {};

export default EditPeriodControl;
