import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Box,
  Center,
  Text,
  Spinner,
  Button,
  IconButton,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Hooks } from "services";
import { RouteComponentProps } from "react-router-dom";
import { Demand } from "services/swagger";
import { Card } from "components/atoms";
import Confirm from "components/atoms/Confirm";
import { IoOptions } from "react-icons/io5";
import DTable from "components/atoms/DataTable";
import ModalAtom, { ModalProps } from "components/atoms/Modal";
import { useToast } from "hooks/toast";
import Moment from "moment";
import Badge from "components/atoms/Badge";
import { UserRoles } from "models/user";
import { useAuth } from "hooks/redux-hooks";
import RSelect from "react-select";

interface DemandDocumentPageProps {}

const DemandDocumentPage: FC<
  DemandDocumentPageProps & RouteComponentProps<{}, {}, { data: Demand }>
> = (props) => {
  const toast = useToast();
  const { role, project } = useAuth();
  const [getDocs, statDocs, dataDocs] = Hooks.DemandDocumentList();
  const [addDoc, statDoc, dataDoc] = Hooks.AddDemandDocument();
  const [updateDoc, statUpdate, dataUpdate] = Hooks.UpdateDemandDocument();
  const [getUsers, statUsers, dataUsers] = Hooks.UserList();
  const modalRef = useRef<ModalProps>(null);
  const editModalRef = useRef<ModalProps>(null);
  const [newName, setNewName] = useState("");
  const [newDate, setNewDate] = useState("");
  const [newUser, setNewUser] = useState<number | undefined>();
  const [editData, setEditData] = useState<IDemandDocument | null>(null);
  const [editName, setEditName] = useState("");
  const [editDate, setEditDate] = useState("");
  const [editUser, setEditUser] = useState<number | undefined>();

  useLayoutEffect(() => {
    getDocs(props.location.state.data.id);
    getUsers(project.id);
  }, []);

  function onAdd() {
    addDoc({
      demandID: props.location.state.data.id,
      documentName: newName,
      lastDate: newDate,
      assignUserID: newUser,
    });
  }

  function clearAndCloseEdit() {
    setEditData(null);
    setEditDate("");
    setEditName("");
    setEditUser(undefined);
  }

  function onEdit() {
    updateDoc({
      ...editData,
      assignUserID: editUser,
      demandID: editData?.demandID,
      documentName: editName,
      lastDate: editDate,
      isActive: editData?.isActive,
      id: editData?.id,
    });
  }

  function handleClickEdit(row: IDemandDocument) {
    setEditData(row);
    setEditName(row.documentName);
    setEditUser(row.assignUserID);
    setEditDate(Moment(row.lastDate).format("YYYY-MM-DD"));
    editModalRef.current?.open();
  }

  useEffect(() => {
    if (statDoc === "fulfilled") {
      if (dataDoc?.data.success === true) {
        setNewName("");
        setNewDate("");
        toast({ status: "success", title: "Doküman Başarıyla Oluşturuldu!" });
        getDocs(props.location.state.data.id);
        modalRef.current?.close();
      } else if (dataDoc?.data.success === false) {
        toast({ status: "error", title: "Doküman Oluşturulamadı!" });
      }
    } else if (statDoc === "rejected") {
      toast({ status: "error", title: "Doküman Oluşturulamadı!" });
    }

    if (statUpdate === "fulfilled") {
      toast({ status: "success", title: "Doküman Başarıyla Düzenlendi!" });
      editModalRef.current?.close();
      getDocs(props.location.state.data.id);
    }
  }, [statDoc, statUpdate]);

  return (
    <Main headerProps={{ title: "Talep Doküman Listesi" }}>
      <Card mb="3">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex>
            <Text fontWeight="bold">{props.location.state.data.name}</Text>
            <Text ml="1">Doküman Listesi</Text>
          </Flex>
          {role !== UserRoles.view && role !== UserRoles.basic && (
            <Button
              colorScheme="blue"
              onClick={() => {
                modalRef.current?.open();
              }}
            >
              Yeni Ekle
            </Button>
          )}
        </Flex>
      </Card>
      {!!dataDocs?.data.data ? (
        <Card>
          <DTable
            columns={[
              {
                name: "ID",
                selector: "id",
                sortable: true,
                width: "100px",
              },
              {
                name: "Ad",
                selector: "documentName",
                sortable: true,
                width: "180px",
              },
              {
                name: "Atanan Kullanıcı",
                selector: "assignUserName",
                sortable: true,
                width: "180px",
              },
              {
                name: "Son Tarih",
                cell: (row: any) => (
                  <span>{Moment(row.lastDate).format("DD.MM.YYYY")}</span>
                ),
                width: "200px",
                selector: "lastDate",
                columnType: "date",
              },
              {
                name: "Oluşturulma Tarihi",
                selector: "createDate",
                columnType: "date",
                cell: (row: any) => (
                  <span>{Moment(row.createDate).format("DD.MM.YYYY")}</span>
                ),
                width: "200px",
              },
              {
                name: "Oluşturan Kullanıcı",
                cell: (row: any) => <span>{row.purchaseName}</span>,
                width: "200px",
                selector: "purchaseName",
              },
              {
                name: "Durum",
                cell: (row: any) => <Badge isActive={!!row.isActive} />,
                width: "100px",
                selector: "isActive",
                columnType: "isActiveBoolean",
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any) => (
                  <Confirm
                    onConfirm={async () => {
                      await updateDoc({ ...row, isActive: !row.isActive });
                      getDocs(props.location.state.data.id);
                    }}
                    text={`Doküman ${
                      !row.isActive ? "Aktif" : "Pasif"
                    } yapılsın mı?`}
                  >
                    {({ onOpen }) => (
                      <IconButton
                        size="sm"
                        onClick={onOpen}
                        aria-label="activepassive"
                        icon={<IoOptions />}
                      />
                    )}
                  </Confirm>
                ),

                width: "100px",
              },
              {
                name: "Düzenle",
                cell: (row: any) => (
                  <Box>
                    <Button
                      colorScheme="blue"
                      onClick={() => {
                        handleClickEdit(row);
                      }}
                    >
                      Düzenle
                    </Button>
                  </Box>
                ),
              },
            ]}
            data={dataDocs?.data.data}
          />
        </Card>
      ) : (
        <Card>
          <Center>
            <Spinner />
          </Center>
        </Card>
      )}
      <ModalAtom
        ref={modalRef}
        title="Yeni Talep Dokümanı Oluştur"
        onConfirmText="Oluştur"
        onConfirm={() => {
          onAdd();
        }}
      >
        <FormControl>
          <FormLabel>Doküman Adı</FormLabel>
          <Input
            value={newName}
            onChange={(e) => setNewName(e.currentTarget.value)}
          />
        </FormControl>
        <FormControl mt="3">
          <FormLabel>Son Tarih</FormLabel>
          <Input
            type="date"
            value={newDate}
            onChange={(e) => setNewDate(e.currentTarget.value)}
          />
        </FormControl>
        <FormControl mt="3">
          <FormLabel>Kullanıcı</FormLabel>
          <RSelect
            placeholder="Kullanıcı Seç"
            isLoading={!dataUsers?.data.data}
            onChange={(data) => setNewUser(data!.value)}
            options={dataUsers?.data.data.map((user) => ({
              value: user.id,
              label: (user as any).username,
            }))}
          />
        </FormControl>
      </ModalAtom>
      <ModalAtom
        ref={editModalRef}
        title="Talep Dokümanı Düzenle"
        onConfirmText="Düzenle"
        onClose={() => {
          clearAndCloseEdit();
        }}
        onConfirm={() => {
          onEdit();
        }}
      >
        <FormControl>
          <FormLabel>Doküman Adı</FormLabel>
          <Input
            value={editName}
            onChange={(e) => setEditName(e.currentTarget.value)}
          />
        </FormControl>
        <FormControl mt="3">
          <FormLabel>Son Tarih</FormLabel>
          <Input
            type="date"
            value={editDate}
            onChange={(e) => setEditDate(e.currentTarget.value)}
          />
        </FormControl>
        <FormControl mt="3">
          <FormLabel>Kullanıcı</FormLabel>
          <RSelect
            placeholder="Kullanıcı Seç"
            defaultValue={
              {
                label: editData?.assignUserName,
                value: editData?.assignUserID,
              } as any
            }
            isLoading={!dataUsers?.data.data}
            onChange={(data) => setEditUser(data!.value)}
            options={dataUsers?.data.data.map((user) => ({
              value: user.id,
              label: (user as any).username,
            }))}
          />
        </FormControl>
      </ModalAtom>
    </Main>
  );
};

DemandDocumentPage.defaultProps = {};

export default DemandDocumentPage;
