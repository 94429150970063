import React, { FC, Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Text,
  Textarea,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Page } from "models/page";
import { TimelineCounter } from "components/organisms/TimelineCounter/Item";
import { Card } from "components/atoms";
import { Controller, useForm } from "react-hook-form";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import { ConditialInputController } from "components/molecules";
import Dropzone from "components/atoms/Dropzone";
import { useToast } from "hooks/toast";
import { useHistory } from "react-router-dom";

type Params = {};

type States = {
  item: TimelineCounter;
};

const CounterDoMaintenance: Page<Params, States> = (props) => {
  const { item } = props.location.state;
  const { user } = useAuth();
  const [photos, setPhotos] = useState<File[]>([]);
  const [postDM, statusDM, dataDM] = Hooks.CounterDoMaintenance();
  const toast = useToast();
  const history = useHistory();

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function getBase64Photos(arr: any[]) {
    return new Promise(async (resolve, reject) => {
      let arrData: any[] = [];
      for (var index = 0; index < arr.length; index++) {
        let base64str = await toBase64(arr[index]);
        let ext = arr[index].name.split(".").pop();
        arrData.push({ base64: base64str, extension: ext });
      }

      return resolve(arrData);
    });
  }

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      id: item.counterID,
      controlledUserID: item.controlledUserID,
      t: "",
      t1: "",
      t2: "",
      t3: "",
      kapasitif: "",
      reaktif: "",
      demant: "",
      value: "",
      description: "",
      photo: "",
      photoExtension: "",
      multiplierValue: item.multiplierValue,
      consumptionDifferencePercentage: item.consumptionDifferencePercentage,
    },
  });

  useEffect(() => {
    if (statusDM === "fulfilled") {
      toast({
        status: "success",
        title: "Başarılı!",
        description: "Sayaç kontrolü başarıyla gerçekleşti!",
      });
      history.push(`/?initial=counter`);
    } else if (statusDM === "rejected") {
      toast({
        status: "error",
        title: "Hata Oluştu!",
        description: "Sayaç kontrolü gerçekleşirken hata oluştu!",
      });
    }
  }, [statusDM]);

  return (
    <Main headerProps={{ title: "Sayaç Bakımı Yap" }}>
      <Container pt="3">
        <Box>
          <Heading size="md">
            {props.location.state.item.location} -{" "}
            {props.location.state.item.counterTypeName} Sayaç Kontrolü 
          </Heading>
        </Box>
        <Box mt="5">
          <Card>
            <form
              onSubmit={handleSubmit(async (values) => {
                let base64List = await getBase64Photos(photos || []);
                postDM({
                  id: item.counterTaskID,
                  consumptionDifferencePercentage:
                    item.consumptionDifferencePercentage,
                  controlledUserID: item.controlledUserID ?? user.id,
                  demant: values.demant,
                  description: values.description,
                  kapasitif: values.kapasitif,
                  multiplierValue: item.multiplierValue,
                  photo:
                    photos.length > 0 && !!base64List
                      ? JSON.stringify(base64List)
                      : "",
                  photoExtension: "",
                  reaktif: values.reaktif,
                  t1: values.t1,
                  t2: values.t2,
                  t3: values.t3,
                  t: values.t,
                  value: values.value || "0",
                });
              })}
            >
              <SimpleGrid columns={item.counterTypeId !== 1 ? 2 : 1} spacing="5">
                {item.counterTypeId !==
                  1 && (
                    <Controller
                      control={control}
                      name="value"
                      rules={{ required: true }}
                      render={({ field, fieldState }) => (
                        <FormControl id="value">
                          <FormLabel>Değer</FormLabel>
                          <Input {...field} isInvalid={fieldState.invalid} />
                        </FormControl>
                      )}
                    />
                  )}
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <FormControl id="description">
                      <FormLabel>Açıklama</FormLabel>
                      <Textarea {...field} />
                    </FormControl>
                  )}
                />
              </SimpleGrid>
              <SimpleGrid columns={2} spacing="5">
                {item.isTRequired && (
                  <Controller
                    control={control}
                    name="t"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <FormControl id="t">
                        <FormLabel>T</FormLabel>
                        <Input {...field} isInvalid={fieldState.invalid} />
                      </FormControl>
                    )}
                  />
                )}
                <ConditialInputController
                  conditian={item.isT1Required}
                  name="t1"
                  control={control}
                >
                  {({ field, fieldState }) => (
                    <FormControl id="t1">
                      <FormLabel>T1</FormLabel>
                      <Input {...field} isInvalid={fieldState.invalid} />
                    </FormControl>
                  )}
                </ConditialInputController>
                <ConditialInputController
                  conditian={item.isT2Required}
                  name="t2"
                  control={control}
                >
                  {({ field, fieldState }) => (
                    <FormControl id="t2">
                      <FormLabel>T2</FormLabel>
                      <Input {...field} isInvalid={fieldState.invalid} />
                    </FormControl>
                  )}
                </ConditialInputController>
                <ConditialInputController
                  conditian={item.isT3Required}
                  name="t3"
                  control={control}
                >
                  {({ field, fieldState }) => (
                    <FormControl id="t3">
                      <FormLabel>T3</FormLabel>
                      <Input {...field} isInvalid={fieldState.invalid} />
                    </FormControl>
                  )}
                </ConditialInputController>
              </SimpleGrid>
              <SimpleGrid columns={2} spacing="5" mt="4">
                <ConditialInputController
                  conditian={item.isKapasitifRequired}
                  name="kapasitif"
                  control={control}
                >
                  {({ field, fieldState }) => (
                    <FormControl id="kapasitif">
                      <FormLabel>Kapasitif Değer</FormLabel>
                      <Input {...field} isInvalid={fieldState.invalid} />
                    </FormControl>
                  )}
                </ConditialInputController>
                <ConditialInputController
                  conditian={item.isReaktifRequired}
                  name="reaktif"
                  control={control}
                >
                  {({ field, fieldState }) => (
                    <FormControl id="reaktif">
                      <FormLabel>Reaktif Değer</FormLabel>
                      <Input {...field} isInvalid={fieldState.invalid} />
                    </FormControl>
                  )}
                </ConditialInputController>
                <ConditialInputController
                  conditian={item.isDemantRequired}
                  name="demant"
                  control={control}
                >
                  {({ field, fieldState }) => (
                    <FormControl id="demant">
                      <FormLabel>Demant Değer</FormLabel>
                      <Input {...field} isInvalid={fieldState.invalid} />
                    </FormControl>
                  )}
                </ConditialInputController>
              </SimpleGrid>
              <SimpleGrid columns={2} spacing="5" my="4">
                {/* <Controller
                  control={control}
                  name="multiplierValue"
                  render={({ field }) => (
                    <FormControl id="multiplierValue">
                      <FormLabel>Çarpan Değeri</FormLabel>
                      <Input {...field} />
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="consumptionDifferencePercentage"
                  render={({ field }) => (
                    <FormControl id="consumptionDifferencePercentage">
                      <FormLabel>Ortalama Tüketim Farkı Yüzdesi</FormLabel>
                      <Input {...field} />
                    </FormControl>
                  )}
                /> */}
              </SimpleGrid>
              <Fragment>
                <Heading fontSize="large" mt="8">
                  Fotoğraf
                </Heading>
                <Divider my="2" />
                <Box>
                  <Dropzone
                    multiple
                    onDrop={(files) => {
                      setPhotos(files);
                    }}
                  />
                </Box>
              </Fragment>
              <Flex mt="5" justifyContent="flex-end">
                <Button type="submit" colorScheme="blue">
                  Bakım Tamamla
                </Button>
              </Flex>
            </form>
          </Card>
        </Box>
      </Container>
    </Main>
  );
};

export default CounterDoMaintenance;
