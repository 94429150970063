import React, { PropsWithChildren } from "react";
import {
  Flex,
  Box,
  Container,
  Link,
  Icon,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { Sidebar, Header } from "components/organisms";
import { HeaderProps } from "components/organisms/Header";
import { useResponsive } from "hooks/responsive";
import GlobalSearch from "components/molecules/GlobalSearch";

interface MainProps {
  headerProps: HeaderProps;
}

function Main({ children, headerProps }: PropsWithChildren<MainProps>) {
  const searchDisc = useDisclosure();
  const drawerDisc = useDisclosure();
  const { isSm, isBase } = useResponsive();

  return (
    <Flex w="100vw" minH="100vh" overflow="hidden">
      <Sidebar drawerDisc={drawerDisc} />
      <Box
        bgColor={useColorModeValue("gray.100", "gray.800")}
        w="100%"
        maxH="100vh"
        overflowY="scroll"
      >
        <Header drawerDisc={drawerDisc} {...headerProps} searchDisc={searchDisc} />
        <Container mt="5" maxW="100%">
          {children}
        </Container>
        {(isBase || isSm) && <Box h="100px"></Box>}
      </Box>
      <GlobalSearch searchDisc={searchDisc} />
    </Flex>
  );
}

export default Main;
