import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  Spinner,
  Center,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Collapse,
  Fade,
  useDisclosure,
} from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Card } from "components/atoms";
import { useAuth } from "hooks/redux-hooks";
import { Hooks } from "services";
import DTable from "components/atoms/DataTable";
import { Campus as ICampus } from "services/swagger";
import Badge from "components/atoms/Badge";
import Moment from "moment";
import { useHistory } from "react-router";
import Drawer, { DrawerProps } from "components/atoms/Drawer";
import { Inventory } from "models";
import {
  IoCamera,
  IoOptions,
  IoRefresh,
  IoRefreshCircle,
} from "react-icons/io5";
import ReactDatePicker from "react-datepicker";
import Lightbox from "react-image-lightbox";
import Dropzone from "components/atoms/Dropzone";
import FileUploaderForm, {
  FileUploadType,
} from "./components/FileUploaderForm";
import Confirm from "components/atoms/Confirm";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-lightbox/style.css";
import InventoryFilter from "components/organisms/InventoryFilter";
import { mainPaths } from "router/path";
import { UserRoles } from "models/user";
import { isSieOrNo } from "helper/project-mode";

function InventoryPage() {
  const { project, role } = useAuth();
  const history = useHistory();
  const [selectedInventory, setSelectedInventory] = useState<Inventory>();
  const drawerRef = useRef<DrawerProps>(null);
  const [lightBoxImage, setLightBoxImage] = useState("");
  const toast = useToast();
  const filterDisc = useDisclosure();
  const [filterData, setFilterData] = useState<any>(null);

  const [getList, statusList, data] = Hooks.InventoryList();

  useLayoutEffect(() => {
    getList(project.id);
  }, []);

  function _onFilterData(row: Inventory) {
    function _condition(a: any, b: any) {
      return !a || a === b;
    }

    const _data = [
      _condition(filterData?.campus?.value, row.campusID),
      _condition(filterData?.build?.value, row.buildID),
      _condition(filterData?.floor?.value, row.floorID),
      _condition(filterData?.room?.value, row.roomID),
      _condition(filterData?.userType?.value, row.userTypeID),
      _condition(filterData?.group?.value, row.groupID),
      _condition(filterData?.brand?.value, row.brandID),
      _condition(filterData?.model?.value, row.modelID),
    ];

    return !_data.includes(false);
  }

  useEffect(() => {
    if (!filterDisc.isOpen) {
      setFilterData({});
    }
  }, [filterDisc.isOpen]);

  return (
    <Main headerProps={{ title: "Eksik Ekipman" }}>
      <Card p="3" mb="3">
        <Flex justifyContent="flex-end">
          <Button
            size="sm"
            colorScheme="orange"
            mr="3"
            onClick={() => filterDisc.onToggle()}
          >
            {filterDisc.isOpen ? "Filtre Kapat" : "Filtre"}
          </Button>
          {role !== UserRoles.basic && role !== UserRoles.view && (
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => history.push("/inventory/create")}
            >
              Yeni Ekipman Ekle
            </Button>
          )}
        </Flex>
      </Card>

      {filterDisc.isOpen && (
        <InventoryFilter
          onFilter={(data: any) => {
            setFilterData(data);
          }}
        />
      )}

      {!!filterData && Object.values(filterData).length > 0 && (
        <Flex flexWrap="wrap" mb="3">
          {Object.values(filterData).map((data: any) =>
            !!data?.label ? (
              <Center p="2" px="4" bgColor="white" mr="3">
                <Text fontSize="xs" textAlign="center">
                  {data?.label}
                </Text>
              </Center>
            ) : null
          )}
        </Flex>
      )}

      {!!data?.data.data ? (
        <Card>
          <DTable
            columns={[
              {
                name: "ID",
                selector: "id",
                sortable: true,
                width: "100px",
              },
              {
                name: "Barkod",
                selector: "barcode",
                sortable: true,
                width: "150px",
              },
              {
                name: "Ekipman Adı",
                selector: "name",
                sortable: true,
                width: "150px",
              },
              {
                name: "Kullanıcı Tipi",
                selector: "userTypeName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Açıklama",
                selector: "explain",
                sortable: true,
                width: "200px",
              },
              {
                name: "Adet",
                selector: "count",
                sortable: true,
                width: "100px",
              },
              {
                name: "Tesis",
                selector: "campusName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Bina",
                selector: "buildName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Kat",
                selector: "floorName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Oda",
                selector: "roomName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Grup",
                selector: "groupName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Marka",
                selector: "brandName",
                sortable: true,
                width: "150px",
              },
              {
                name: "Model",
                selector: "modelName",
                sortable: true,
              },
              {
                name: "Seri No",
                selector: "serialNumber",
                sortable: true,
              },
              {
                name: "Asset No",
                selector: "assetNo",
                sortable: true,
              },
              {
                name: "Kapasite Tipi",
                selector: "inventoryaCapacityName",
                sortable: true,
              },
              {
                name: "Kapasite Değeri",
                selector: "unit",
                sortable: true,
              },
              {
                name: "Oluşturulma Tarihi",
                cell: (row: ICampus) => (
                  <span>{Moment(row.createDate).format("DD.MM.YYYY")}</span>
                ),
                width: "200px",
              },
              {
                name: "Oluşturan Kullanıcı",
                cell: (row: any) => <span>{row.purchaseName}</span>,
                width: "200px",
              },
              {
                name: "Durum",
                cell: (row: ICampus) => <Badge isActive={!!row.isActive} />,
                width: "100px",
              },
              {
                name: "Aktif / Pasif",
                cell: (row: ICampus) => (
                  <Center w="90%">
                    <Confirm onConfirm={() => {}} text="Aaa">
                      {({ onOpen }) => (
                        <IconButton
                          size="sm"
                          onClick={onOpen}
                          aria-label="activepassive"
                          icon={<IoOptions />}
                        />
                      )}
                    </Confirm>
                  </Center>
                ),
                width: "100px",
              },
              {
                name: "Fotoğraf",
                cell: (row: any) => (
                  <Center w="100%">
                    {!!!row.inventoryPhoto ? (
                      "Fotoğraf Yok"
                    ) : (
                      <IconButton
                        size="sm"
                        aria-label="activepassive"
                        onClick={() => {
                          setLightBoxImage(row.inventoryPhoto);
                        }}
                        icon={<IoCamera />}
                      />
                    )}
                  </Center>
                ),
                width: "100px",
              },
              {
                name: "Dosyaları Düzenle",
                cell: (row: any) => {
                  return (
                    <Button
                      colorScheme="blue"
                      size="sm"
                      onClick={() => {
                        setSelectedInventory(row);
                        drawerRef.current?.open();
                      }}
                    >
                      Dosyalar
                    </Button>
                  );
                },
                width: "120px",
              },
              {
                name: "Düzenle",
                cell: (row: any) => (
                  <Button
                    colorScheme="teal"
                    disabled={role === UserRoles.basic}
                    size="sm"
                    onClick={() => {
                      history.push(mainPaths.InventoryEdit, { data: row });
                    }}
                  >
                    Düzenle
                  </Button>
                ),
                width: "120px",
              },
              {
                name: "Bakım Listesi",
                cell: (row: any) => (
                  <Button
                    colorScheme="purple"
                    size="sm"
                    onClick={() => {
                      history.push(`/maintenance-list/${row.id}`, {
                        inventory: row,
                      });
                    }}
                  >
                    Bakım Listesi
                  </Button>
                ),
                width: "140px",
              },
              {
                name: `Periyodik ${isSieOrNo("Kontroller", "Muayeneler")}`,
                cell: (row: any) => (
                  <Button
                    colorScheme="orange"
                    size="sm"
                    onClick={() => {
                      history.push(`/periodic-controls/${row.id}`, {
                        inventory: row,
                      });
                    }}
                  >
                    Periyodik {isSieOrNo("Kontroller", "Muayeneler")}
                  </Button>
                ),
                width: "180px",
              },
            ]}
            data={data?.data.data.filter(_onFilterData)}
          />
        </Card>
      ) : (
        <Card>
          <Center>
            <Spinner />
          </Center>
        </Card>
      )}

      <Drawer
        onConfirmText="Tamam"
        onConfirm={() => {
          drawerRef.current?.close();
          getList(project.id);
        }}
        ref={drawerRef}
        onClose={() => {
          getList(project.id);
        }}
        title={`${selectedInventory?.name} Dosyaları`}
      >
        <FileUploaderForm
          uploadType={FileUploadType.UserGuidePath}
          inventoryId={selectedInventory?.id as unknown as number}
          defaultValue={selectedInventory?.userGudiePath as unknown as string}
          title={"Kullanım Klavuzu"}
        />
        <FileUploaderForm
          uploadType={FileUploadType.UserInstructions}
          inventoryId={selectedInventory?.id as unknown as number}
          defaultValue={
            selectedInventory?.userInstructions as unknown as string
          }
          title={"Kullanım Talimatı"}
        />
        <FileUploaderForm
          uploadType={FileUploadType.RiskAnalysisPath}
          inventoryId={selectedInventory?.id as unknown as number}
          defaultValue={
            selectedInventory?.riskAnalysisPath as unknown as string
          }
          title={"ISG Risk Analizi"}
          date={Moment(selectedInventory?.riskAnalysisExpriDate).format(
            "YYYY-MM-DD"
          )}
        />
        <FileUploaderForm
          uploadType={FileUploadType.MaintenanceContract}
          inventoryId={selectedInventory?.id as unknown as number}
          defaultValue={""}
          title={"Bakım Sözleşmesi"}
        />
      </Drawer>
      {!!lightBoxImage && (
        <Lightbox
          mainSrc={lightBoxImage}
          onCloseRequest={() => setLightBoxImage("")}
        />
      )}
    </Main>
  );
}

export default InventoryPage;
