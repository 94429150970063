import React, { FC, useLayoutEffect, useRef, useState } from "react";
import {
  Box,
  Center,
  Text,
  Spinner,
  Button,
  Flex,
  FormControl,
  FormLabel,
  useDisclosure,
} from "@chakra-ui/react";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import Main from "components/templates/Main";
import { Card, ModalProps } from "components/atoms";
import DataTable from "components/atoms/DataTable";
import { Inventory, TimelineLegal } from "models";
import { TimelineStatus } from "components/organisms/TimelineLegal";
import Moment from "moment";
import { FaFile } from "react-icons/fa";
import ModalAtom from "components/atoms/Modal";
import RSelect from "react-select";
import { IoFolder } from "react-icons/io5";
import InventoryFilter from "components/organisms/InventoryFilter";
import { isSieOrNo } from "helper/project-mode";

interface CompletedLegalProps {}

const CompletedLegal: FC<CompletedLegalProps> = (props) => {
  const { project } = useAuth();
  const [getData, statusData, dataData] = Hooks.TimelineLegal();
  const [getPeriods, statPeriods, dataPeriods] = Hooks.ListPeriod();
  const [getUT, statUT, dataUT] = Hooks.UserTypeList();
  const [filterData, setFilterData] = useState<any[]>([]);
  const [filterData2, setFilterData2] = useState<any>();
  const filterModalRef = useRef<ModalProps>(null);
  const filterDisc = useDisclosure();
  const [statusFilter, setStatusFilter] = useState([1, 2, 3]);

  useLayoutEffect(() => {
    getData(project.id);
    getPeriods(project.id);
    getUT();
  }, []);

  function onFilter(item: any) {
    const filters: boolean[] = [];
    filterData.forEach((_filter) => {
      filters.push(item[_filter.name] === _filter.value);
    });

    return !filters.includes(false);
  }

  function _onFilterData(row: Inventory) {
    function _condition(a: any, b: any) {
      return !a || a === b;
    }

   

    const _data = [
      _condition(filterData2?.campus?.label, row.campusName),
      _condition(filterData2?.build?.label, row.buildName),
      _condition(filterData2?.floor?.label, row.floorName),
      _condition(filterData2?.room?.label, row.roomName),
      _condition(filterData2?.userType?.value, row.userTypeID),
      _condition(filterData2?.group?.label, row.groupName),
      _condition(filterData2?.brand?.label, row.brandName),
      _condition(filterData2?.model?.label, row.modelName),
    ];

    return !_data.includes(false);
  }

  return (
    <Main headerProps={{ title: `Periyodik ${isSieOrNo("Kontroller", "Muayeneler")}` }}>
      <Card mb="3">
        <Flex justifyContent="space-between">
          <Flex>
            <Button
              size="sm"
              mr="1"
              onClick={() =>
                setStatusFilter((prev) =>
                  prev.includes(1) ? prev.filter((i) => i !== 1) : [...prev, 1]
                )
              }
              variant={statusFilter.includes(1) ? "solid" : "outline"}
              colorScheme="purple"
            >
              Tamamlanan
            </Button>
            <Button
              size="sm"
              mr="1"
              onClick={() =>
                setStatusFilter((prev) =>
                  prev.includes(2) ? prev.filter((i) => i !== 2) : [...prev, 2]
                )
              }
              variant={statusFilter.includes(2) ? "solid" : "outline"}
              colorScheme="purple"
            >
              Planlı Zamanda Gerçekleşmeyen
            </Button>
            <Button
              size="sm"
              mr="1"
              onClick={() =>
                setStatusFilter((prev) =>
                  prev.includes(3) ? prev.filter((i) => i !== 3) : [...prev, 3]
                )
              }
              variant={statusFilter.includes(3) ? "solid" : "outline"}
              colorScheme="purple"
            >
              Form Beklenen
            </Button>
          </Flex>
          <Button
            size="sm"
            colorScheme="orange"
            onClick={() => filterDisc.onToggle()}
          >
            {filterDisc.isOpen ? "Filtre Kapat" : "Detaylı Filtre"}
          </Button>
        </Flex>
      </Card>
      {filterDisc.isOpen && (
        <InventoryFilter
          onFilter={(data: any) => {
            setFilterData2(data);
          }}
        />
      )}
      <Card>
        {statusData !== "fulfilled" || !dataData ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <DataTable
            onClickFilter={() => {
              filterModalRef.current?.open();
            }}
            data={dataData.data.data
              .filter((item: TimelineLegal) => {
                let _statuses: any[] = [];
                if (statusFilter.includes(1)) {
                  _statuses = [
                    ..._statuses,
                    ...[TimelineStatus.completed, TimelineStatus.lateDone],
                  ];
                }
                if (statusFilter.includes(2)) {
                  _statuses = [..._statuses, TimelineStatus.late];
                }
                if (statusFilter.includes(3)) {
                  _statuses = [..._statuses, TimelineStatus.needDocument];
                }
                return _statuses.includes(item.status);
              })
              .filter(_onFilterData)
              .filter(onFilter).map((row: TimelineLegal) => ({
                ...row,
                endDateStr:  Moment(row.endDate).format("DD.MM.YYYY")
              }))}
            columns={[
              {
                name: `Periyodik ${isSieOrNo("Kontrol", "Muayene")} Formları Ve Yüklenme Tarihleri`,
                width: "400px",
                cell: (row: any) => {
                  return (
                    <Flex pr="3">
                      {row.documents
                        .filter((item: any) => item.type === "formPhoto")
                        .map((_document: any, index: number) => (
                          <Button
                            colorScheme="teal"
                            size="xs"
                            py="5"
                            mr="1"
                            leftIcon={<IoFolder />}
                            onClick={() => {
                              window.open(_document.path, "_blank");
                            }}
                          >
                            {Moment(_document.createDate).format(
                              "DD.MM.YYYY HH:mm"
                            )}
                            <br />
                            (Dokümanı Gör)
                          </Button>
                        ))}
                    </Flex>
                  );
                },
              },
              {
                name: "Fotoğraf / Belge Ve Yüklenme Tarihleri",
                width: "700px",
                cell: (row: any) => {
                  return (
                    <Flex pr="3">
                      {row.documents
                        .filter((item: any) => item.type !== "formPhoto")
                        .map((_document: any, index: number) => (
                          <Button
                            colorScheme="teal"
                            size="xs"
                            py="5"
                            leftIcon={<IoFolder />}
                            mr="1"
                            onClick={() => {
                              window.open(_document.path, "_blank");
                            }}
                          >
                            {Moment(_document.createDate).format(
                              "DD.MM.YYYY HH:mm"
                            )}
                            <br />
                            (Dokümanı Gör)
                          </Button>
                        ))}
                    </Flex>
                  );
                },
              },
              {
                name: `Periyodik ${isSieOrNo("Kontrol", "Muayene")} Raporu`,
                cell: (row: any) => (
                  <Button
                    size="xs"
                    colorScheme="blue"
                    onClick={() => {
                      localStorage.removeItem("doc-report");
                      localStorage.setItem("doc-report", JSON.stringify(row));
                      window.open("/doc-report-legal", "_blank");
                    }}
                    leftIcon={<FaFile />}
                  >
                    Raporu Görüntüle
                  </Button>
                ),
                width: "200px",
              },
              {
                name: "Kullanıcı Tipi",
                selector: "userTypeName",
                width: "240px",
              },
              { name: "Barkod", selector: "barcode" },
              { name: "Ekipman Adı", selector: "name", width: "200px" },

              {
                name: "Başlangıç Tarihi",
                cell: (row: TimelineLegal) =>
                  Moment(row.startDate).format("DD.MM.YYYY"),
                width: "200px",
              },
              {
                name: `Planlı Periyodik ${isSieOrNo("Kontrol", "Muayene")} Tarihi`,
                cell: (row: TimelineLegal) =>
                  Moment(row.endDate).format("DD.MM.YYYY"),
                width: "200px",
              },
              {
                name: "Son Formun Yüklendiği Tarih",
                cell: (row: TimelineLegal) =>
                  Moment(
                    (row as unknown as any).updateDate ||
                      (row as unknown as any).completedDate
                  ).format("DD.MM.YYYY HH:mm"),
                width: "200px",
              },
              {
                name: "Gerçekleştiren Firma",
                selector: "maintenanceFirm",
                width: "200px",
              },
              { name: "Notlar", selector: "explain", width: "200px" },
              {
                name: "Soru Yanıtı",
                cell: (row: any) => (row.answer ? "Uygun" : "Uygun Değil"),
                width: "200px",
              },
              {
                name: "İkinci Form Yükleme Notu",
                selector: "updateExplaint",
                width: "200px",
              },
              { name: "Tesis", selector: "campusName", width: "200px" },
              { name: "Bina", selector: "buildName", width: "200px" },
              { name: "Kat", selector: "floorName", width: "200px" },
              { name: "Oda", selector: "roomName", width: "200px" },
              { name: "Grup", selector: "groupName", width: "200px" },
              { name: "Marka", selector: "brandName", width: "200px" },
              { name: "Model", selector: "modelName", width: "200px" },
              {
                name: "Kapasite Birim",
                selector: "inventoryCapacityName",
                width: "200px",
              },
              { name: "Kapasite Değeri", selector: "unit", width: "200px" },
              {
                name: "Üretim Yılı",
                selector: "productionYear",
                width: "200px",
              },
              {
                name: "İlk Formu Yükleyen Kullanıcı",
                selector: "completedPersonelName",
                width: "200px",
              },
              {
                name: "İkinci Formu Yükleyen Kullanıcı",
                selector: "updatePersonelName",
                width: "200px",
              },
            ]}
          />
        )}
      </Card>
      <ModalAtom ref={filterModalRef} title="Filtre" noForm>
        <FormControl mt="5" mb="20">
          <FormLabel>Kullanıcı Tipi</FormLabel>
          <RSelect
            isClearable
            placeholder="Seçim Yap"
            onChange={(value) => {
              const keyName = "userTypeId";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                  },
                ]);
              }
            }}
            options={dataUT?.data.data.map((period: any) => ({
              value: period.id,
              label: period.name,
            }))}
          />
        </FormControl>
      </ModalAtom>
    </Main>
  );
};

CompletedLegal.defaultProps = {};

export default CompletedLegal;
