import React, { FC, Fragment, useEffect, useState } from "react";
import {
  Box,
  Text,
  Spinner,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Tr,
  Divider,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import "react-image-lightbox/style.css";
import "./master.css";
import { useAuth, useRedux } from "hooks/redux-hooks";
import { Redirect, RouteComponentProps } from "react-router-dom";
import Moment from "moment";
import { Card } from "components/atoms";
import Lightbox from "react-image-lightbox";
import { FaCheck } from "react-icons/fa";
import { Question } from "models";
import { isSieOrNo } from "helper/project-mode";

interface DocReportProps {}

const configs = {
  dateString: "YYYY-MM-DD[T]HH:mm:ss",
};

const DocReport: FC<DocReportProps & RouteComponentProps> = (props) => {
  const { user, project } = useAuth();
  const [maintenance, setMaintenance] = useState<any>(null);
  const [lightImage, setLightImage] = useState<string | null>(null);

  useEffect(() => {
    const data = localStorage.getItem("doc-report");
    if (!data) {
      props.history.push("/");
    }
   
    setMaintenance(JSON.parse(data!));
  }, []);

  if (!maintenance) return <Spinner />;

  return (
    <Fragment>
      <div className="book">
        <div className="page">
          <Header />
          <DataTable
            title="Ekipman Bilgileri"
            data={[
              { title: "Tesis", value: maintenance.campusName },
              { title: "Bina", value: maintenance.buildName },
              { title: "Kat", value: maintenance.floorName },
              { title: "Oda", value: maintenance.roomName },
              { title: "Kod", value: maintenance.barcode },
              { title: "Envanter No", value: maintenance.inventoryNo },
              { title: "Ekipman Adı", value: maintenance.name },
              { title: "Grup", value: maintenance.groupName },
              { title: "Marka", value: maintenance.brandName },
              { title: "Model", value: maintenance.modelName },
              { title: "Seri No", value: maintenance.serialNumber },
              { title: "Üretim Yılı", value: maintenance.productionYear },
              {
                title: "Kapasite Birimi",
                value: maintenance.inventoryCapacityName,
              },
              { title: "Kapasite Değeri", value: maintenance.unit },
              { title: "Adet", value: maintenance.count },
            ]}
          />
          {!!maintenance.inventoryPhoto && (
            <Flex justifyContent="center">
              <img
                alt="Ekipman"
                style={{
                  cursor: "pointer",
                }}
                src={maintenance.inventoryPhoto}
                onClick={(e) => setLightImage(maintenance.inventoryPhoto)}
                width="auto"
                height="150px"
              />
            </Flex>
          )}
          <div className="page-number">1</div>
        </div>
        <div className="page">
          <Header />
          <DataTable
            title={`Periyodik ${isSieOrNo("Kontrol", "Muayene")} Bilgileri`}
            data={[
              {
                title: "Başlangıç Tarihi",
                value: Moment(maintenance.startDate, configs.dateString).format(
                  "DD/MM/YYYY"
                ),
              },
              {
                title: `Planlı Periyodik ${isSieOrNo("Kontrol", "Muayene")} Tarihi`,
                value: Moment(maintenance.endDate, configs.dateString).format(
                  "DD/MM/YYYY"
                ),
              },
              {
                title: "Son Formun Yüklendiği Tarih",
                value: Moment(maintenance.completedDate).format(
                  "DD/MM/YYYY HH:mm"
                ),
              },
              {
                title: "Gerçekleştiren Fimra",
                value: maintenance.maintenanceFirm,
              },
              {
                title: "Notlar",
                value: maintenance.explain,
              },
              {
                title: `Periyodik ${isSieOrNo("Kontrol", "Muayene")} Sorusu`,
                value: maintenance.question,
              },
              {
                title: "Soru Yanıtı",
                value: maintenance.answer ? "Uygun" : "Uygun Değil",
              },
              {
                title: "İkinci Form Yükleme Notu",
                value: maintenance.updateExplaint,
              },
              {
                title: `İlk Periyodik ${isSieOrNo("Kontrol", "Muayene")} Formunu Yükleyen Kullanıcı`,
                value: maintenance.completedPersonelName,
              },
              {
                title: `İkinci Periyodik ${isSieOrNo("Kontrol", "Muayene")} Formunu Yükleyen Kullanıcı`,
                value: maintenance.updatePersonelName,
              },
             
            ]}
          />

          <DataTable
            title="Form Olayları"
            data={[
              {
                title: "ISG Risk Analizi",
                value: "Okudum, Anladım",
                stat: maintenance.riskAnalysisIsRequired,
              },
              {
                title: "Kullanma Klavuzu",
                value: "Okudum, Anladım",
                stat: maintenance.userGudiePathIsRequired,
              },
              {
                title: "Kullanıcı Talimatları",
                value: "Okudum, Anladım",
                stat: maintenance.userInstructionsIsRequired,
              },
              {
                title: "Bakım Sözleşmesi",
                value: "Okudum, Anladım",
                stat: maintenance.maintenanceContractIsRequired,
              },
            ].filter((item) => item.stat)}
          />
          <div className="page-number">2</div>
        </div>
        <div className="page">
          <Header />
          <DataCard title="Ekipman Fotoğrafı">
            <SimpleGrid columns={1}>
              {maintenance &&
                maintenance.documents
                  .filter((photo: any) => photo.type === "inventoryPhoto")
                  .map((photo: any, index: number) => (
                    <Flex flexDir="column" flex="1" mb="2" justifyContent="center" key={index}>
                      <img
                        alt={"Form" + index}
                        style={{
                          cursor: "pointer",
                          marginRight: 3,
                        }}
                        src={photo.path}
                        onClick={(e) => setLightImage(photo.path)}
                        width="auto"
                        height="auto"
                      />
                      <Text fontSize="xs">
                        {Moment(photo.createDate).format("DD.MM.YYYY")}
                      </Text>
                    </Flex>
                  ))}
            </SimpleGrid>
          </DataCard>
          <div className="page-number">4</div>
        </div>
      </div>
      {!!lightImage && (
        <Lightbox
          mainSrc={lightImage!}
          onCloseRequest={() => setLightImage(null)}
        />
      )}
    </Fragment>
  );
};

function Header() {
  const { user, project } = useAuth();
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      borderBottom="1px"
      borderColor="gray.300"
      pb="1"
    >
      <Flex flex="1">
        <Image src={project.companyLogo} width="150px" />
      </Flex>
      <Flex flex="3" justifyContent="center">
        <Text textAlign="center" fontSize="lg">
          Periyodik {isSieOrNo("Kontrol", "Muayene")} Raporu
        </Text>
      </Flex>
      <Flex flex="1" justifyContent="flex-end">
        <Text textAlign="center" fontSize="lg">
          {Moment().format("DD.MM.YYYY")}
        </Text>
      </Flex>
    </Flex>
  );
}

function DataTable({ data, title }: any) {
  return (
    <Box py="4">
      <Heading fontSize="md">{title}</Heading>
      <Divider my="2" />
      {data && (
        <Table variant="striped" size="sm">
          <Tbody>
            {data.map((item: any, index: number) => (
              <Tr key={index}>
                <Td>
                  <span>{item.title}: </span>
                </Td>
                <Td style={{ minWidth: 130 }}>
                  <strong>{item.value}</strong>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}

function DataCard({ title, children }: any) {
  return (
    <Box py="4">
      <Heading fontSize="md" mb="5">
        {title}
      </Heading>
      <Flex w="100%" flexWrap="wrap" wrap="wrap">
        {children}
      </Flex>
    </Box>
  );
}

DocReport.defaultProps = {};

export default DocReport;
