import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Button,
  Center,
  Divider,
  Flex,
  IconButton,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Card } from "components/atoms";
import { InputElement, SelectElement } from "components/atoms/FormElements";
import { Form, InputElement as HookInput } from "components/atoms/HookForm";
import Modal, { ModalProps } from "components/atoms/Modal";
import Main from "components/templates/Main";
import { useAuth } from "hooks/redux-hooks";
import { useToast } from "hooks/toast";
import { IFollowDocument } from "models";
import { UserRoles } from "models/user";
import { useForm } from "react-hook-form";
import { IoAdd } from "react-icons/io5";
import { RouteComponentProps } from "react-router-dom";
import { Hooks } from "services";

type PageStates = {
  document: IFollowDocument;
};

function DocumentCreate({
  history,
  location: {
    state: { document },
  },
}: RouteComponentProps<{}, {}, PageStates>) {
  const { project, user, role } = useAuth();
  const { control, handleSubmit, watch, setValue } = useForm();
  const [addInventory, statusInventory, dataInventory] = Hooks.AddInventory();
  const [addDocument, statDoc, dataDoc] = Hooks.EditDocument();
  const [getTypes, , userTypes] = Hooks.UserTypeList();
  const toast = useToast();
  const [isMounted, setMounted] = useState<boolean>(false);

  const [getCampuses, statCampuses, dataCampuses] = Hooks.CampusList();
  const [getBuilds, statBuilds, dataBuilds] = Hooks.BuildList();
  const [getFloors, statFloors, dataFloors] = Hooks.FloorList();
  const [getRooms, statRooms, dataRooms] = Hooks.RoomList();
  const [getGroups, statGroups, dataGroups] = Hooks.GroupList();
  const [getBrands, statBrands, dataBrands] = Hooks.BrandList();
  const [getModels, statModels, dataModels] = Hooks.ModelList();
  const [getCapacities, statCapacities, dataCapacities] = Hooks.CapacityList();

  const [onAddGroup, statusAddGroup] = Hooks.AddGroup();
  const [onAddBrand, statusAddBrand] = Hooks.AddBrand();
  const [onAddModel, statusAddModel] = Hooks.AddModel();

  const [getUsers, statUsers, dataUsers] = Hooks.UserList();

  const groupModal = useRef<ModalProps>(null);
  const brandModal = useRef<ModalProps>(null);
  const modelModal = useRef<ModalProps>(null);

  useLayoutEffect(() => {
    if (!!!dataCampuses) {
      getCampuses(project.id);
    }
    getGroups(project.id);
    getUsers(project.id);
    getTypes();
    getCapacities();
  }, []);

  useEffect(() => {
    if (isMounted && !isNaN(parseInt(watch("campusID")))) {
      getBuilds(parseInt(watch("campusID")));
      setValue("buildID", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("campusID")]);

  useEffect(() => {
    if (isMounted && !isNaN(parseInt(watch("buildID")))) {
      getFloors(parseInt(watch("buildID")));
      setValue("floorID", undefined);
    }
  }, [watch("buildID")]);

  useEffect(() => {
    if (isMounted && !isNaN(parseInt(watch("floorID")))) {
      getRooms(parseInt(watch("floorID")));
      setValue("roomID", undefined);
    }
  }, [watch("floorID")]);

  useEffect(() => {
    if (isMounted && !isNaN(parseInt(watch("groupID")))) {
      getBrands(parseInt(watch("groupID")));
      setValue("modelID", undefined);
    }
  }, [watch("groupID")]);

  useEffect(() => {
    if (isMounted && !isNaN(parseInt(watch("brandID")))) {
      getModels(parseInt(watch("brandID")));
      setValue("modelID", undefined);
    }
  }, [watch("brandID")]);

  async function getFounds() {
    if (document.campusID) {
      await getBuilds(document.campusID);
    }
    if (document.buildID) {
      await getFloors(document.buildID);
    }
    if (document.floorID) {
      await getRooms(document.floorID);
    }
    if (document.groupID) {
      await getBrands(document.groupID);
    }
    if (document.brandID) {
      await getModels(document.brandID);
    }
    setMounted(true);
  }

  useEffect(() => {
    getFounds();
  }, []);

  function onValid(values: any) {
   
    addDocument({
      id: document.id,
      isActive: document.isActive,
      projectID: project.id,
      userTypeID: parseInt(values.userTypeID),
      campusID: parseInt(values.campusID),
      buildID: parseInt(values.buildID),
      floorID: parseInt(values.floorID),
      roomID: parseInt(values.roomID),
      groupID: parseInt(values.groupID),
      brandID: parseInt(values.brandID),
      modelID: parseInt(values.modelID),
      inventoryCapacityID: parseInt(values.inventoryCapacityID),
      documentName: values.documentName,
      explain: values.explain,
      productionYear: values.productionYear,
      unit: values.unit,
      assignUserID: parseInt(values.assignUserID)
    });
  }

  useEffect(() => {
    if (!!dataDoc) {
      if (dataDoc?.data.success) {
        toast({ status: "success", title: "Belge başarıyla eklendi" });
        history.push("/document");
      } else {
        toast({
          status: "error",
          title: "Belge eklenirken hata!",
          description: dataDoc?.data.message,
        });
      }
    }
  }, [dataDoc]);

  return (
    <Main headerProps={{ title: "Belge Düzenle" }}>
      <>
        {isMounted ? (
          <>
            <Card>
              <form onSubmit={handleSubmit(onValid)}>
                <Tabs isFitted>
                  <TabList>
                    <Tab>Lokasyon Bilgileri</Tab>
                    <Tab>Teknik Bilgiler Ve Kullanıcı Tipi</Tab>
                    <Tab>Genel Bilgiler</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel pt="10">
                      <SimpleGrid columns={[1, 2]} gap="5">
                        <SelectElement
                          name="campusID"
                          label="Tesis"
                          defaultValue={document.campusID}
                          control={control}
                          isRequired
                          data={dataCampuses?.data.data.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                        />
                        <SelectElement
                          name="buildID"
                          label="Bina"
                          defaultValue={document.buildID}
                          control={control}
                          isRequired
                          data={dataBuilds?.data.data.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                        />
                        <SelectElement
                          name="floorID"
                          label="Kat"
                          isRequired
                          defaultValue={document.floorID}
                          control={control}
                          data={dataFloors?.data.data.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                        />
                        <SelectElement
                          name="roomID"
                          label="Oda"
                          defaultValue={document.roomID}
                          isRequired
                          control={control}
                          data={dataRooms?.data.data.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                        />
                      </SimpleGrid>
                    </TabPanel>
                    <TabPanel pt="10">
                      <SimpleGrid columns={[1, 2]} gap="5">
                        <Flex alignItems="flex-end">
                          <SelectElement
                            name="groupID"
                            label="Grup"
                            isRequired
                            defaultValue={document.groupID}
                            control={control}
                            data={dataGroups?.data.data.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                          />
                          <IconButton
                            icon={<IoAdd />}
                            ml="2"
                            variant="outline"
                            aria-label="add"
                            onClick={() => {
                              groupModal.current?.open();
                            }}
                          />
                        </Flex>
                        <Flex alignItems="flex-end">
                          <SelectElement
                            name="brandID"
                            label="Marka"
                            defaultValue={document.brandID}
                            control={control}
                            data={dataBrands?.data.data.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                          />
                          <IconButton
                            icon={<IoAdd />}
                            ml="2"
                            variant="outline"
                            aria-label="add"
                            onClick={() => {
                              if (watch("groupID") != 0) {
                                brandModal.current?.open();
                              } else {
                                toast({
                                  status: "error",
                                  title: "Grup seçiniz!",
                                });
                              }
                            }}
                          />
                        </Flex>
                        <Flex alignItems="flex-end">
                          <SelectElement
                            name="modelID"
                            label="Model"
                            defaultValue={document.modelID}
                            control={control}
                            data={dataModels?.data.data.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                          />
                          <IconButton
                            icon={<IoAdd />}
                            ml="2"
                            variant="outline"
                            aria-label="add"
                            onClick={() => {
                              if (watch("brandID") != 0) {
                                modelModal.current?.open();
                              } else {
                                toast({
                                  status: "error",
                                  title: "Marka seçiniz!",
                                });
                              }
                            }}
                          />
                        </Flex>
                      </SimpleGrid>
                      <Divider my="5" />
                      <SimpleGrid columns={2} gap="5">
                        <SelectElement
                          name="userTypeID"
                          label="Kullanıcı Tipi"
                          isRequired
                          defaultValue={document.userTypeID}
                          control={control}
                          data={userTypes?.data.data
                            .filter((item) =>
                              role === UserRoles.admin
                                ? true
                                : user.userTypes
                                    .map((_type: any) => _type.id)
                                    .includes(item.id)
                            )
                            .map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                        />
                        <SelectElement
                          name="assignUserID"
                          label="Atanan Kullanıcı"
                          isRequired
                          defaultValue={document.assignUserID}
                          control={control}
                          data={dataUsers?.data.data.map((item) => ({
                            value: item.id,
                            label: (item as any).username,
                          }))}
                        />
                      </SimpleGrid>
                    </TabPanel>
                    <TabPanel pt="10">
                      <SimpleGrid columns={[1, 2]} gap="5">
                        <InputElement
                          name="documentName"
                          label="Belge Adı"
                          isRequired
                          control={control}
                          defaultValue={document.documentName}
                        />
                        <InputElement
                          name="explain"
                          label="Açıklama"
                          defaultValue={document.explain}
                          control={control}
                        />
                        {/* <SelectElement
                          name="inventoryCapacityID"
                          defaultValue={document.inventoryCapacityID}
                          label="Kapasite "
                          control={control}
                          data={(dataCapacities?.data.data as any[])?.map(
                            (capacity) => ({
                              label: capacity.name,
                              value: capacity.id,
                            })
                          )}
                        />
                        <InputElement
                          name="Unit"
                          label="Kapasite Değeri"
                          defaultValue={document.unit}
                          control={control}
                        />

                        <InputElement
                          name="productionYear"
                          defaultValue={document.productionYear}
                          label="Üretim Yılı"
                          control={control}
                        /> */}
                      </SimpleGrid>
                      <Flex justifyContent="flex-end" mt="5">
                        <Button colorScheme="teal" type="submit">
                          Düzenle
                        </Button>
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </form>
            </Card>
            <Modal title="Grup Ekle" ref={groupModal}>
              <Form
                onValid={async ({ name }: any) => {
                  await onAddGroup({ name: name, projectID: project.id });
                  toast({
                    status: "success",
                    title: "Grup başarıyla eklendi!",
                  });
                  getGroups(project.id);
                  groupModal.current?.close();
                }}
              >
                <HookInput name="name" label="İsim" />
                <Button mt="2" type="submit">
                  Grup Ekle
                </Button>
              </Form>
            </Modal>
            <Modal title="Marka Ekle" ref={brandModal}>
              <Form
                onValid={async ({ name }: any) => {
                  await onAddBrand({
                    name: name,
                    groupID: parseInt(watch("groupID")),
                  });
                  toast({
                    status: "success",
                    title: "Marka başarıyla eklendi!",
                  });
                  getBrands(parseInt(watch("groupID")));
                  brandModal.current?.close();
                }}
              >
                <HookInput name="name" label="İsim" />
                <Button mt="2" type="submit">
                  Marka Ekle
                </Button>
              </Form>
            </Modal>
            <Modal title="Model Ekle" ref={modelModal}>
              <Form
                onValid={async ({ name }: any) => {
                  await onAddModel({
                    name: name,
                    brandID: parseInt(watch("brandID")),
                  });
                  toast({
                    status: "success",
                    title: "Model başarıyla eklendi!",
                  });
                  getModels(parseInt(watch("brandID")));
                  modelModal.current?.close();
                }}
              >
                <HookInput name="name" label="İsim" />
                <Button mt="2" type="submit">
                  Model Ekle
                </Button>
              </Form>
            </Modal>
          </>
        ) : (
          <Center>
            <Spinner />
          </Center>
        )}
      </>
    </Main>
  );
}

export default DocumentCreate;
