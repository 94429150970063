import React, { FC, useLayoutEffect } from "react";
import { Box, Button, Center, Flex, IconButton, Spinner, Text } from "@chakra-ui/react";
import Main from "components/templates/Main";
import { Hooks } from "services";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Card } from "components/atoms";
import DataTable from "components/atoms/DataTable";
import Moment from "moment";
import Badge from "components/atoms/Badge";
import { UserRoles } from "models/user";
import { useAuth } from "hooks/redux-hooks";
import Confirm from "components/atoms/Confirm";
import { IoOptions } from "react-icons/io5";

interface ConrolListProps {}

const ConrolList: FC<ConrolListProps & RouteComponentProps<{ id: string }>> = (
  props
) => {
  const { role } = useAuth();
  const [getList, statList, dataList] = Hooks.DocumentMaintenanceList();
  const [update, stat] = Hooks.UpdateDocumentMaintenance();
  const history = useHistory();

  useLayoutEffect(() => {
    getList(parseInt(props.match.params.id));
  }, []);

  return (
    <Main headerProps={{ title: "Belge Takip Kontrolleri" }}>
      <Card p="3">
        <Flex justifyContent="flex-end">
          {role !== UserRoles.view && role !== UserRoles.basic && (
            <Button
              colorScheme="blue"
              onClick={() =>
                history.push(
                  `/document/${props.match.params.id}/control-create`
                )
              }
            >
              Yeni Ekle
            </Button>
          )}
        </Flex>
      </Card>
      {statList !== "fulfilled" && (
        <Center mt="3">
          <Spinner size="lg" />
        </Center>
      )}
      {statList === "fulfilled" && dataList?.data.data && (
        <Card mt="3">
          <DataTable
            data={dataList?.data.data} 
            columns={[
              { name: "#ID", selector: "id" },
              { name: "Kontrol Periyotu", selector: "documentPeriodName" },
              { name: "Kontrol Başlangıç Günü", selector: "beforeDay" },
              {
                name: "Kontrol Başlangıç Tarihi",
                width: "150px",
                cell: (row: any) => Moment(row.startDate).format("DD.MM.YYYY"),
                selector: "startDate",
                columnType: "date",
              },
              {
                name: "Kontrol Tarihi",
                width: "150px",
                cell: (row: any) => Moment(row.endDate).format("DD.MM.YYYY"),
                selector: "endDate",
                columnType: "date",
              },
              { name: "Kontrol Sorusu", selector: "question", width: "200px" },
              {
                name: "Oluşturulma Tarihi",
                width: "150px",
                cell: (row: any) => Moment(row.createDate).format("DD.MM.YYYY"),
                selector: "createDate",
                columnType: "date",
              },
              {
                name: "Durum",
                width: "100px",
                cell: (row: any) => <Badge isActive={row.isActive} />,
                selector: "isActive",
                columnType: "isActiveBoolean",
              },
              {
                name: "Aktif / Pasif",
                cell: (row: any) => (
                  <Box>
                    <Confirm
                      onConfirm={async () => {
                        await update({ ...row, isActive: !row.isActive });
                        getList(parseInt(props.match.params.id));
                      }}
                      text={`Bu kayıt ${
                        row.isActive ? "Pasif" : "Aktif"
                      } yapılacak. İşleme devam edilsin mi?`}
                    >
                      {({ onOpen }) => (
                        <IconButton
                          size="sm"
                          onClick={onOpen}
                          aria-label="active"
                          icon={<IoOptions />}
                        />
                      )}
                    </Confirm>
                  </Box>
                ),
              },
              {
                name: "Düzenle",
                cell: (row: any) => (
                  <Box>
                    <Button
                      colorScheme="purple"
                      size="sm"
                      onClick={() => {
                       
                        history.push(`/document/control-edit`, {
                          control: row,
                        });
                      }}
                    >
                      Düzenle
                    </Button>
                  </Box>
                ),
              },
            ]}
          />
        </Card>
      )}
    </Main>
  );
};

ConrolList.defaultProps = {};

export default ConrolList;
