import React, { FC, useLayoutEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  Center,
  Spinner,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import RSelect from "react-select";
import Main from "components/templates/Main";
import { Hooks } from "services";
import { useAuth } from "hooks/redux-hooks";
import DataTable from "components/atoms/DataTable";
import { Card } from "components/atoms";
import Moment from "moment";
import ModalAtom, { ModalProps } from "components/atoms/Modal";

interface InCompletedMaintenanceProps {}

const InCompletedMaintenance: FC<InCompletedMaintenanceProps> = (props) => {
  const { project } = useAuth();
  const [getReports, statReports, dataReports] = Hooks.InCompletedReport();
  const [getPeriods, statPeriods, dataPeriods] = Hooks.ListPeriod();
  const [getUT, statUT, dataUT] = Hooks.UserTypeList();
  const [filterData, setFilterData] = useState<any[]>([]);
  const filterModalRef = useRef<ModalProps>(null);

  useLayoutEffect(() => {
    getReports(project.id);
    getPeriods(project.id);
    getUT();
  }, []);

  function onFilter(item: any) {
    const filters: boolean[] = [];
    filterData.forEach((_filter) => {
      filters.push(item[_filter.name] === _filter.value);
    });

    return !filters.includes(false);
  }

  return (
    <Main headerProps={{ title: "Zamanında Gerçekleşmeyen Bakımlar" }}>
      {statReports !== "fulfilled" || !dataReports ? (
        <Center h="300px">
          <Spinner />
        </Center>
      ) : (
        <Card>
          <DataTable
            onClickFilter={() => {
              filterModalRef.current?.open();
            }}
            columns={[
              { name: "Barkod", selector: "barcode" },
              { name: "Ekipman Adı", selector: "name", width: "150px" },
              {
                name: "Bakım Tipi",
                selector: "maintenanceTypeName",
                width: "150px",
              },
              {
                name: "Periyot",
                selector: "maintenancePeriodName",
                width: "150px",
              },
              {
                name: "Başlangıç Tarihi",
                cell: (row) => (
                  <Text>{Moment(row.startDate).format("DD.MM.YYYY")}</Text>
                ),
                width: "150px",
              },
              {
                name: "Planli Bakım Tarihi",
                cell: (row) => (
                  <Text>{Moment(row.endDate).format("DD.MM.YYYY")}</Text>
                ),
                width: "150px",
              },
              { name: "Tesis", selector: "campusName", width: "150px" },
              { name: "Bina", selector: "buildName", width: "150px" },
              { name: "Kat", selector: "floorName", width: "150px" },
              { name: "Oda", selector: "roomName", width: "150px" },
              { name: "Grup", selector: "groupName", width: "150px" },
              { name: "Marka", selector: "brandName", width: "150px" },
              { name: "Model", selector: "modelName", width: "150px" },
              {
                name: "Kapasite Birimi",
                selector: "inventoryCapacityName",
                width: "150px",
              },
              { name: "Kapasite Değeri", selector: "unit", width: "100px" },
              { name: "Adet", selector: "count", width: "100px" },
              {
                name: "Üretim Yılı",
                selector: "productionYear",
                width: "150px",
              },
            ]}
            data={dataReports.data.data.filter(onFilter)}
          />
        </Card>
      )}
      <ModalAtom ref={filterModalRef} title="Filtre" noForm>
        <FormControl>
          <FormLabel>Periyot</FormLabel>
          <RSelect
            placeholder="Seçim Yap"
            isClearable
            onChange={(value) => {
              const keyName = "maintenancePeriodID";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                  },
                ]);
              }
            }}
            options={dataPeriods?.data.data.map((period: any) => ({
              value: period.id,
              label: period.name,
            }))}
          />
        </FormControl>
        <FormControl mt="5">
          <FormLabel>Bakım Tipi</FormLabel>
          <RSelect
            placeholder="Seçim Yap"
            isClearable
            onChange={(value) => {
              const keyName = "maintenanceTypeID";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                  },
                ]);
              }
            }}
            options={[
              {
                label: "İç Bakım / Konrol",
                value: 1,
              },
              {
                label: "Dış Bakım",
                value: 2,
              },
            ]}
          />
        </FormControl>
        <FormControl mt="5" mb="20">
          <FormLabel>Kullanıcı Tipi</FormLabel>
          <RSelect
            isClearable
            placeholder="Seçim Yap"
            onChange={(value) => {
              const keyName = "userTypeId";
              if (!value) {
                setFilterData((_state) =>
                  _state.filter((_filter) => _filter.name !== keyName)
                );
              } else {
                setFilterData((_state) => [
                  ..._state,
                  {
                    name: keyName,
                    value: value?.value,
                  },
                ]);
              }
            }}
            options={dataUT?.data.data.map((period: any) => ({
              value: period.id,
              label: period.name,
            }))}
          />
        </FormControl>
      </ModalAtom>
    </Main>
  );
};

InCompletedMaintenance.defaultProps = {};

export default InCompletedMaintenance;
