import { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "store";
import { Project } from "services/swagger";
import { UserRoles } from "models/user";
import { Authenticator } from "helper/authenticator";
import { Hooks } from "services";
import { useEffect } from "react";

export function useRedux(): [Store, Dispatch<any>] {
  const reduxStore = useSelector<Store, Store>((store) => store);
  const dispatch = useDispatch();
  return [reduxStore, dispatch];
}

export function useAuth() {
  const [state, dispatch] = useRedux();
  const [getUser, statUser, dataUser, reset] = Hooks.GetUser();

  function refreshUser() {
    getUser(state.auth.user.id);
  }

  useEffect(() => {
    if (statUser === "fulfilled") {
      Authenticator.LoginWithProject(
        state.auth.jwt,
        { ...state.auth.user, ...dataUser?.data.data },
        state.auth.project,
        state.auth.decoded,
      );
      reset();
    }
  }, [statUser]);

  return {
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user as any,
    project: state.auth.project as Project & { companyLogo: string },
    token: state.auth.jwt,
    role: state?.auth?.user?.userRoleID || (0 as UserRoles),
    refreshUser,
  };
}
